import React from 'react';
import { t } from 'helpers/i18n';
import { commonConstants } from 'constants/index';

const { DEFAULT_SUPPORT_EMAIL } = commonConstants;

const ContactAdminMessage: React.FC<{
  email?: string;
}> = ({ email = DEFAULT_SUPPORT_EMAIL }) => {
  return (
    <>
      {t('PleaseContactAdmin')}
      <span className="mx-half text-medium">
        <a href={`mailto:${email}`}>{email}</a>
      </span>
      {t('ForAssistance')}.
    </>
  );
};

export default ContactAdminMessage;
