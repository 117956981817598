import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { IRoute } from 'interfaces';
import { commonHelpers } from 'helpers';
import ModalComponent from 'components/ModalComponent';

const { getExternalPath } = commonHelpers;

const ModalItem: React.FC<{ item: IRoute }> = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button type="link" onClick={() => setShowModal(true)}>
        {item.icon && <item.icon className="app-icon" />}
        <span>{item.name}</span>
      </Button>
      <ModalComponent
        component={item.component}
        iFrameSrc={item.iFrameSrc}
        showModal={showModal}
        setShowModal={setShowModal}
        isSuspense
      />
    </>
  );
};

const MenuItemContent: React.FC<{ item: IRoute }> = ({ item }) => {
  const currentLocation = window?.location?.pathname;
  return item.redirectOutside ? (
    <a
      href={getExternalPath(item)}
      target="_blank"
      rel="noopener noreferrer"
      title={item.name}
    >
      {item.icon && <item.icon className="app-icon" />}
      <span>{item.name}</span>
    </a>
  ) : item.isModalItem ? (
    <ModalItem item={item} />
  ) : (
    <Link
      to={item.path}
      title={item.name}
      onClick={e => {
        if (currentLocation === item.path) e.preventDefault();
      }}
    >
      {item.icon && <item.icon className="app-icon" />}
      <span>{item.name}</span>
    </Link>
  );
};

export default MenuItemContent;
