import React, { useState, useMemo } from 'react';
import { Modal, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { sellerHelpers } from 'helpers';
import EmptyBlock from 'components/shared/EmptyBlock';
import { LogoutButton } from 'components/shared/Button';
import PlatformItem from 'components/Platform/PlatformItem';
import './PlatformPopup.scss';
import { IPlatform } from 'interfaces';

const { getSearchPlatforms } = sellerHelpers;

interface PlatformPopupProps {
  isFetching: boolean;
  authorizedPlatforms: IPlatform[];
}

const PlatformPopup: React.FC<PlatformPopupProps> = ({
  isFetching,
  authorizedPlatforms,
}) => {
  const [keyword, setKeyword] = useState('');

  const results = useMemo(() => {
    return getSearchPlatforms(authorizedPlatforms, keyword);
  }, [keyword, authorizedPlatforms]);

  return (
    <Modal
      wrapClassName="platform-popup"
      title={t('PlatformInformation')}
      bodyStyle={{ padding: '8px', maxHeight: 380, overflowY: 'auto' }}
      visible
      closable={false}
      footer={<LogoutButton />}
    >
      <div className="px-base py-half">
        <div className="platform-popup-search">
          {t('PleaseChoosePlatformToContinue')}
          <Input
            className="my-half"
            autoFocus
            allowClear
            placeholder={t('SearchPlatformPlaceholder')}
            prefix={<SearchOutlined />}
            onChange={e => setKeyword(e.target.value.trim())}
            disabled={isFetching}
          />
        </div>
        <div className="platform-popup-list">
          <Spin spinning={isFetching}>
            {!!results.length ? (
              results.map(platform => (
                <PlatformItem key={platform.id} platform={platform} />
              ))
            ) : (
              <EmptyBlock className="m-0 pt-base" />
            )}
          </Spin>
        </div>
      </div>
    </Modal>
  );
};

export default PlatformPopup;
