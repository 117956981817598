import { notification } from 'antd';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { commonConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import { AxiosRequestCustomConfig } from 'interfaces';
import { get } from 'lodash';
import { userServices } from 'services';

const { URL_SEARCH_PARAMS_REGEX } = commonConstants;

const handleResponseError = (
  error: AxiosError & {
    config: AxiosRequestConfig & AxiosRequestCustomConfig;
  }
) => {
  // Track error
  trackError(error);

  const status = error && error.response && error.response.status;
  switch (status) {
    case 401:
      userServices.logout();
      break;
    case 403:
      userServices.denyAccess();
      break;
    default:
      if (
        error.config?.isBypassNormalErrorRequests &&
        !isNotFoundErrorFromIAMService(error)
      ) {
        break;
      }
      let message = null;
      // Handle error message from API response
      if (error.response && error.response.data) {
        const { data } = error.response;
        message = data.message;
      }
      notification.error({
        message: t('Error'),
        description: message || t('SomethingWentWrong'),
      });
      break;
  }
};

const isNotFoundErrorFromIAMService = (error: AxiosError) => {
  return /^404[0-9]{2}$/.test(error.response?.data?.error?.code?.toString());
};

const trackError = (error: AxiosError) => {
  const status = get(error, ['response', 'status'], '.');
  // Track error
  const errResponse = get(error, ['response']);
  if (errResponse) {
    // Remove search params from apiCall
    const apiCall = get(errResponse, ['request', 'responseURL'], '.').replace(
      URL_SEARCH_PARAMS_REGEX,
      ''
    );

    const getErrCodeAndMessage = () => {
      const isBFFErr = !!get(errResponse, ['data', 'code']);
      if (isBFFErr)
        return {
          errorCode: get(errResponse, ['data', 'code'], '.').toString(),
          errorMessage: get(errResponse, ['data', 'message'], '.'),
        };
      else {
        return {
          errorCode: get(
            errResponse,
            ['data', 'error', 'code'],
            '.'
          ).toString(),
          errorMessage: get(errResponse, ['data', 'error', 'message'], '.'),
        };
      }
    };

    setTimeout(() => {
      track('error', {
        errorSource: 'staff-web-app',
        apiCall,
        apiPayload: get(
          errResponse,
          ['config', 'data'],
          JSON.stringify(get(errResponse, ['config', 'params'], {}))
        ),
        httpResponseCode: status?.toString(),
        responseJson: JSON.stringify(get(errResponse, 'data', {})),
        ...getErrCodeAndMessage(),
      });
    });
  }
};

export const getResult = (response: AxiosResponse) => response.data.result;
export default {
  handleResponseError,
};
