const CUSTOM_EVENT = 'customEvent';

const ACTIONS = {
  MESSAGE_FROM_CHILD_APP: 'messageFromChildApp',
};

const TRACKING_CONTENT_REGION_NAME = {
  SiteGlobalSwitch: 'SiteGlobalSwitch',
};

const TRACKING_CONTENT_NAME = {
  OpenSiteSwitchDropdown: 'OpenSiteSwitchDropdown',
  SelectSite: 'SelectSite_',
};

export default {
  CUSTOM_EVENT,
  ACTIONS,
  TRACKING_CONTENT_REGION_NAME,
  TRACKING_CONTENT_NAME,
};
