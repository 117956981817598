import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Result } from 'antd';
import lodashGet from 'lodash/get';

import { StoreContext } from 'contexts';
import { useCurrentRoute } from 'hooks/common';

export const withSiteIdPermission = <P extends {}>(
  Component: React.FC<P>,
  targetTitle: string
): React.FC<P> => {
  return (props: P) => {
    const { currentUser } = useContext(StoreContext);
    const { currentRoute } = useCurrentRoute();

    const userIdentity =
      currentUser.email || currentUser.phone_number || currentUser.name;
    const hasSiteId = Boolean(lodashGet(currentUser, ['meta_data', 'siteId']));
    const hasSiteIds = Boolean(
      lodashGet(currentUser, ['meta_data', 'siteIds', 'length'])
    );
    const isValidUser = currentRoute?.showSiteSwitch ? hasSiteIds : hasSiteId;

    if (!isValidUser) {
      return (
        <Result
          status="403"
          data-testid="InvalidUserSiteIdMsg"
          subTitle={
            <Trans i18nKey="InvalidUserSiteIdMsg">
              Tài khoản
              <span className="text-primary text-medium">
                {{ userIdentity }}
              </span>
              chưa được gán SiteID hợp lệ để xem
              <span className="text-medium">
                {{ targetTitle: targetTitle }}
              </span>
              .
              <br /> Vui lòng liên hệ Admin
              <a href="mailto:support@teko.vn">support@teko.vn</a>
              để được hỗ trợ.
            </Trans>
          }
        />
      );
    }

    return <Component {...props} />;
  };
};
