import React, { useContext } from 'react';
import { commonConstants } from 'constants/index';
import { NetworkContext } from 'contexts/network';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;

const AppRibbon = () => {
  const { isOnlineMode } = useContext(NetworkContext);
  if (!isOnlineMode) {
    return <div className="app-ribbon offline">Offline Mode</div>;
  }

  const config = commonConstants.RIBBON_CONFIGS[getCommonConfig().env];

  if (!config) return null;

  return <div className={`app-ribbon ${config.className}`}>{config.name}</div>;
};

export default AppRibbon;
