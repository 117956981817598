import { Spin } from 'antd';
import { StoreContext } from 'contexts';
import { commonHelpers } from 'helpers';
import { userHooks } from 'hooks';
import { IRoute } from 'interfaces';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const { getBrandConfig } = commonHelpers;

const OfflineRoute = ({ component: Component, ...rest }: IRoute) => {
  const { appName } = getBrandConfig();
  useEffect(() => {
    // Set document title and meta
    document.title = appName;
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', appName);
  }, [appName]);

  useEffect(() => {
    // Set favicons
    const { favicon } = getBrandConfig();
    if (favicon) {
      document.querySelector('link[rel="icon"]')?.setAttribute('href', favicon);
    }
  }, []);

  const { currentUser } = userHooks.useUserInfo();
  // Show spin when fetching required global data
  if (!currentUser) {
    return <Spin className="app-spin" />;
  }

  if (!Component) return null;

  return (
    <StoreContext.Provider
      value={{
        currentUser,
        appName,
        authorizedPlatforms: [],
        platformInfo: undefined,
        authorizedSellers: [],
        sellerInfo: {} as any,
        featureFlagsData: {},
        websites: [],
        userSites: [],
        fetchingContent: false,
      }}
    >
      <Route {...rest} render={routeProps => <Component {...routeProps} />} />
    </StoreContext.Provider>
  );
};

export default OfflineRoute;
