import { IRoute } from 'interfaces';
import { lazy } from 'react';
import { LineChartOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
  roleConstants,
  commonConstants,
  flagConstants,
  guideConstants,
} from 'constants/index';
import {
  REPORT_PATHS,
  PURCHASING_PATHS,
  FINANCE_WEB_APP_PATHS,
} from 'constants/paths';
import { withMasanOwner, withSellerPermission } from 'hocs';
import biReportRoutes from './bi-report';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  baseWmsUrl,
  apiLogisticsUrl,
  baseSellerCenterV2Url,
  baseFmsUrl,
  baseAsUrl,
  baseErpUrl,
  baseLogisticsUrl,
  baseNewWmsUrl,
  baseSupplyChainUrl,
  baseFinanceUrl,
} = getCommonConfig();

const { AS, FMS, STAFF_BFF, STAFF_FE, SELLER_GATEWAY, WMS, LO } = appConstants;
const {
  ACCOUNTANT,
  BRANCH_ACCOUNTANT,
  PHONGVU,
  POSTMART_MANAGER,
  POSTMART_SALESMAN,
  TAKASHIMAYA,
  SOIBIEN,
  SOIBIEN_STORE_STAFF,
  SOIBIEN_WAREHOUSE_STAFF,
  LO_SUPER_ADMIN,
  LO_ADMIN,
  LO_MOD,
} = roleConstants;
const {
  REPORTS,
  PERIOD_REPORT,
  BANKING,
  STOCK_TRANSFER_OUT_REPORT,
  SELLER_SALE_REPORT,
  SALE_REPORT,
  MASAN_DEBT_REPORT,
  MASAN_SELLOUT_REPORT,
  MASAN_INVENTORY_REPORT,
  ORDERS,
  SITE_TRANSFER_REPORT,
  STOCK_SUMMARY_REPORT,
  STOCK_SUMMARY_BY_LOT_REPORT,
  WAREHOUSE_TRANSACTION,
  WAREHOUSE_TRANSACTION_REPORT,
  STOCK_COUNT_SESSION,
  PURCHASE_REPORT,
  MISA_REPORT,
  STOCK_TRANSACTION,
  CASHIER_PAYMENT_RECEIPT,
  END_OF_SHIFT_REPORT,
  CASH_BALANCE_REPORT,
  STOCK_SUMMARY_CONSIGNMENT_REPORT,
  WMS_CURRENT_STOCK_REPORT,
  PAYMENT_RECEIPT_REPORT,
  CONSIGNMENT_SALES_REPORT,
  DETAIL_IMPORT_SUPPLIER_REPORT,
  REVENUE_REPORT,
} = resourceConstants;
const {
  READ,
  READ_SOR,
  READ_SO,
  VIEW_SALES_REPORT,
  READ_REVENUE_REPORT,
  READ_OTHER_RECEIPT,
  READ_OTHER_ISSUE,
  CREATE,
  CREATE_REPORT,
  CREATE_REPORT_V2,
  CREATE_VNPOST,
  READ_PO,
  READ_POR,
  READ_DISCOUNT_APPROVED_REPORT,
  READ_REPORT_CASH_FUNDS,
  READ_SITE_TRANSFER,
  READ_CHART_BY_TIME,
  READ_CHART_BY_SITES,
} = actionConstants;
const { FLAG_KEYS } = flagConstants;
const {
  SELLER_PERMISSION_LIST,
  HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES,
} = commonConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const reportRoutes: IRoute[] = [
  // Level 1 menu
  {
    path: '/reports',
    name: t('Reports.Root'),
    icon: LineChartOutlined,
    children: [
      '/reports/business',
      '/reports/accounting',
      '/reports/warehouse',
      '/reports/logistics',
      '/reports/purchasing',
      '/reports/finance',
      ...biReportRoutes.map(biReportRoute => biReportRoute.path),
    ],
  },
  // Level 2 menus
  {
    path: '/reports/business',
    name: t('Reports.BusinessReports'),
    children: [
      '/reports/business/seller-sale-report',
      '/reports/business/graph-sale-report',
      '/reports/business/tabular-sale-report',
      '/reports/business/sellout-report',
      '/reports/business/sales-order-report',
      '/reports/business/revenue-report',
      '/reports/business/revenue-report-v2',
      '/reports/business/discount-approved-orders-report',
    ],
  },
  {
    path: '/reports/accounting',
    name: t('Reports.AccountingReports'),
    children: [
      '/reports/accounting/rec-report-by-order',
      '/reports/accounting/rec-report-by-object',
      '/reports/accounting/goods-transactions',
      '/reports/accounting/money-transactions',
      '/reports/accounting/out-vat-report',
      '/reports/accounting/in-vat-report',
      '/reports/accounting/branch-vat-report',
      '/reports/accounting/other-acc-reports',
      '/reports/accounting/debt-report',
      '/reports/accounting/bank-reconciliations',
      '/reports/accounting/stock-transfer-out',
      '/reports/accounting/sor-issued-invoice-report',
      '/reports/accounting/sor-not-issued-invoice-report',
      '/reports/accounting/so-issued-invoice-report',
      '/reports/accounting/so-not-issued-invoice-report',
      '/reports/accounting/purchase-receipt-report',
      '/reports/accounting/purchase-return-issue-report',
      '/reports/accounting/other-receipt-report',
      '/reports/accounting/other-issue-report',
      '/reports/accounting/end-of-shift-report',
      '/reports/accounting/cash-balance-report',
      '/reports/accounting/payment-receipt-report',
      '/reports/accounting/import-internal-site-transfer-report',
      '/reports/accounting/export-internal-site-transfer-report',
      '/reports/accounting/consignment-sales',
    ],
  },
  {
    path: '/reports/warehouse',
    name: t('Reports.WarehouseReports'),
    children: [
      getSrc(baseWmsUrl, REPORT_PATHS.StockImportReport),
      getSrc(baseWmsUrl, REPORT_PATHS.StockExportReport),
      getSrc(baseWmsUrl, REPORT_PATHS.StockMovementSummary),
      getSrc(baseWmsUrl, REPORT_PATHS.StockCard),
      '/reports/warehouse/inventory-report',
      '/reports/warehouse/site-transfer-report',
      '/reports/warehouse/stock-summary-report',
      '/reports/warehouse/stock-summary-with-cogs-report',
      '/reports/warehouse/stock-summary-by-lot-report',
      '/reports/warehouse/warehouse-transaction-report',
      '/reports/warehouse/warehouse-transaction-report-v2',
      '/reports/warehouse/warehouse-transaction-with-cogs-report',
      '/reports/warehouse/stock-count-session-report',
      '/reports/warehouse/stock-transaction-report',
      '/reports/warehouse/stock-summary-consignment-report',
      '/reports/warehouse/current-stock-report',
    ],
  },
  {
    path: '/reports/logistics',
    name: t('Reports.LogisticsReports'),
    children: [
      '/reports/logistics/shipping-report',
      getSrc(apiLogisticsUrl, REPORT_PATHS.ShippingExpenseReport),
    ],
  },
  {
    path: '/reports/purchasing',
    name: t('Reports.SupplyReport'),
    children: [
      '/reports/purchasing/purchase-report',
      '/reports/purchasing/import-report',
    ],
  },
  {
    path: '/reports/finance',
    name: t('Reports.PaymentReceipt'),
    children: ['/reports/finance/cash-funds'],
  },
  ...biReportRoutes,
  // Level 3 menus
  {
    exact: true,
    path: '/reports/business/seller-sale-report',
    name: t('Reports.SellerSaleReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.SellerSaleReport),
    permissions: [permission(SELLER_GATEWAY, SELLER_SALE_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/business/graph-sale-report',
    name: t('Reports.GraphSaleReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.GraphSaleReport),
    permissions: [permission(STAFF_BFF, SALE_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/business/tabular-sale-report',
    name: t('Reports.TabularSaleReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.TabularSaleReport),
    permissions: [permission(STAFF_BFF, SALE_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/business/sellout-report',
    name: t('Reports.SelloutReport'),
    component: withMasanOwner(IFrameContainer),
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.SelloutReport),
    permissions: [permission(SELLER_GATEWAY, MASAN_SELLOUT_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/business/sales-order-report',
    name: t('Reports.SalesOrderReportBySaleman'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, REPORT_PATHS.SalesOrderReportBySaleman),
    permissions: [permission(STAFF_BFF, ORDERS, VIEW_SALES_REPORT)],
  },
  {
    exact: true,
    path: '/reports/business/revenue-report',
    name: t('Reports.RevenueReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, REPORT_PATHS.RevenueReport),
    permissions: [permission(STAFF_BFF, ORDERS, READ_REVENUE_REPORT)],
  },
  {
    exact: true,
    path: '/reports/business/revenue-report-v2',
    name: t('Reports.RevenueReportV2'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFinanceUrl, FINANCE_WEB_APP_PATHS.RevenueReport),
    permissions: [
      permission(STAFF_BFF, REVENUE_REPORT, READ_CHART_BY_TIME),
      permission(STAFF_BFF, REVENUE_REPORT, READ_CHART_BY_SITES),
    ],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.REPORT.REPORT,
      userGuideCode: USER_GUIDE_CODE.REPORT.REVENUE_REPORT,
    },
    hideBreadcrumbMobileView: true,
  },
  {
    exact: true,
    path: '/reports/business/discount-approved-orders-report',
    name: t('Reports.DiscountApprovedOrdersReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, REPORT_PATHS.DiscountApprovedOrdersReport),
    permissions: [permission(STAFF_BFF, ORDERS, READ_DISCOUNT_APPROVED_REPORT)],
  },
  // ----
  {
    exact: true,
    path: '/reports/accounting/rec-report-by-order',
    name: t('Reports.ReceivablesReportByOrder'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.ReceivablesReportByOrder),
    permissions: [
      permission(FMS, REPORTS, READ),
      permission(FMS, PERIOD_REPORT, READ),
    ],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/rec-report-by-object',
    name: t('Reports.ReceivablesReportByObject'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.ReceivablesReportByObject),
    permissions: [
      permission(FMS, REPORTS, READ),
      permission(FMS, PERIOD_REPORT, READ),
    ],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/goods-transactions',
    name: t('Reports.GoodsTransactions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.GoodsTransactions),
    permissions: [
      permission(FMS, REPORTS, READ),
      permission(FMS, PERIOD_REPORT, READ),
    ],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/money-transactions',
    name: t('Reports.MoneyTransactions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.MoneyTransactions),
    permissions: [
      permission(FMS, REPORTS, READ),
      permission(FMS, PERIOD_REPORT, READ),
    ],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/out-vat-report',
    name: t('Reports.OutVatReport'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-report']
    ),
    iFrameSrc: getSrc(baseAsUrl, REPORT_PATHS.OutVatReport),
    permissions: [permission(AS, ACCOUNTANT)],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/in-vat-report',
    name: t('Reports.InVatReport'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-report']
    ),
    iFrameSrc: getSrc(baseAsUrl, REPORT_PATHS.InVatReport),
    permissions: [
      permission(AS, ACCOUNTANT),
      permission(AS, BRANCH_ACCOUNTANT),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/branch-vat-report',
    name: t('Reports.BranchVatReport'),
    component: withSellerPermission(
      IFrameContainer,
      SELLER_PERMISSION_LIST['as-report']
    ),
    iFrameSrc: getSrc(baseAsUrl, REPORT_PATHS.BranchVatReport),
    permissions: [permission(AS, ACCOUNTANT)],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/other-acc-reports',
    name: t('Reports.OtherAccReports'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.OtherAccReports),
    permissions: [permission(FMS, REPORTS, READ)],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/debt-report',
    name: t('Reports.DebtReport'),
    component: withMasanOwner(IFrameContainer),
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.DebtReport),
    permissions: [permission(SELLER_GATEWAY, MASAN_DEBT_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/accounting/bank-reconciliations',
    name: t('Reports.BankReconciliations'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFmsUrl, REPORT_PATHS.BankReconciliations),
    permissions: [permission(FMS, BANKING, READ)],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/reports/accounting/stock-transfer-out',
    name: t('Reports.StockTransferOut'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, REPORT_PATHS.StockTransferOut),
    permissions: [permission(STAFF_FE, STOCK_TRANSFER_OUT_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/accounting/sor-issued-invoice-report',
    name: t('Reports.SaleOrderReturnWithInvoiceReportMenu'),
    title: t('Reports.SaleOrderReturnWithInvoiceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.SaleOrderReturnWithInvoiceReport
    ),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SOR)],
    flagKey: FLAG_KEYS.MISA_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/sor-not-issued-invoice-report',
    name: t('Reports.SaleOrderReturnWithoutInvoiceReportMenu'),
    title: t('Reports.SaleOrderReturnWithoutInvoiceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.SaleOrderReturnWithoutInvoiceReport
    ),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SOR)],
    flagKey: FLAG_KEYS.MISA_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/so-issued-invoice-report',
    name: t('Reports.SaleOrderWithInvoiceReportMenu'),
    title: t('Reports.SaleOrderWithInvoiceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.SaleOrderWithInvoiceReport),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SO)],
    flagKey: FLAG_KEYS.MISA_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/so-not-issued-invoice-report',
    name: t('Reports.SaleOrderWithoutInvoiceReportMenu'),
    title: t('Reports.SaleOrderWithoutInvoiceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.SaleOrderWithoutInvoiceReport
    ),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SO)],
    flagKey: FLAG_KEYS.MISA_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/purchase-receipt-report',
    name: t('Reports.PurchaseReceiptReportMenu'),
    title: t('Reports.PurchaseReceiptReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.PurchaseReceiptReport),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_PO)],
    flagKey: FLAG_KEYS.MISA_PURCHASE_REPORTS,
  },
  {
    exact: true,
    path: '/reports/accounting/purchase-return-issue-report',
    name: t('Reports.PurchaseReturnIssueReportMenu'),
    title: t('Reports.PurchaseReturnIssueReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.PurchaseReturnIssueReport),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_POR)],
    flagKey: FLAG_KEYS.MISA_PURCHASE_REPORTS,
  },
  {
    exact: true,
    path: '/reports/accounting/other-receipt-report',
    name: t('Reports.OtherReceiptReportMenu'),
    title: t('Reports.OtherReceiptReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.OtherReceiptReport),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_OTHER_RECEIPT)],
    flagKey: FLAG_KEYS.MISA_OTHER_RECEIPT_OTHER_ISSUE_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/other-issue-report',
    name: t('Reports.OtherIssueReportMenu'),
    title: t('Reports.OtherIssueReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.OtherIssueReport),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_OTHER_ISSUE)],
    flagKey: FLAG_KEYS.MISA_OTHER_RECEIPT_OTHER_ISSUE_REPORT,
  },
  {
    exact: true,
    path: '/reports/accounting/end-of-shift-report',
    name: t('Reports.EndOfShiftReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.EndOfShiftReport),
    permissions: [permission(STAFF_BFF, END_OF_SHIFT_REPORT, READ)],
    flagKey: FLAG_KEYS.END_OF_SHIFT_REPORT,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ACCOUNTING.ACCOUNTING,
      userGuideCode: USER_GUIDE_CODE.ACCOUNTING.END_OF_SHIFT_REPORT,
    },
  },
  {
    exact: true,
    path: '/reports/accounting/cash-balance-report',
    name: t('Reports.CashBalanceReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.CashBalanceReport),
    permissions: [permission(STAFF_BFF, CASH_BALANCE_REPORT, READ)],
    flagKey: FLAG_KEYS.CASH_BALANCE_REPORT,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ACCOUNTING.ACCOUNTING,
      userGuideCode: USER_GUIDE_CODE.ACCOUNTING.CASH_BALANCE_REPORT,
    },
  },
  {
    exact: true,
    path: '/reports/accounting/payment-receipt-report',
    name: t('Reports.PaymentReceiptReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.PaymentReceiptReport),
    permissions: [permission(STAFF_BFF, PAYMENT_RECEIPT_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/accounting/import-internal-site-transfer-report',
    name: t('Reports.ImportInternalSiteTransferReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.ImportInternalSiteTransferReport
    ),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SITE_TRANSFER)],
  },
  {
    exact: true,
    path: '/reports/accounting/export-internal-site-transfer-report',
    name: t('Reports.ExportInternalSiteTransferReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.ExportInternalSiteTransferReport
    ),
    permissions: [permission(STAFF_BFF, MISA_REPORT, READ_SITE_TRANSFER)],
  },
  {
    exact: true,
    path: '/reports/accounting/consignment-sales',
    name: t('Reports.ConsignmentSalesReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.ConsignmentSalesReport),
    permissions: [
      permission(STAFF_BFF, CONSIGNMENT_SALES_REPORT, CREATE_REPORT),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  // ----
  {
    exact: true,
    path: getSrc(baseWmsUrl, REPORT_PATHS.StockImportReport),
    name: t('Reports.StockImportReport'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, REPORT_PATHS.StockExportReport),
    name: t('Reports.StockExportReport'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, REPORT_PATHS.StockMovementSummary),
    name: t('Reports.StockMovementSummary'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, REPORT_PATHS.StockCard),
    name: t('Reports.StockCard'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/inventory-report',
    name: t('Reports.InventoryReport'),
    component: withMasanOwner(IFrameContainer),
    iFrameSrc: getSrc(baseSellerCenterV2Url, REPORT_PATHS.InventoryReport),
    permissions: [permission(SELLER_GATEWAY, MASAN_INVENTORY_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/warehouse/site-transfer-report',
    name: t('Reports.SiteTransferReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.SiteTransferReport),
    permissions: [permission(STAFF_BFF, SITE_TRANSFER_REPORT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-summary-report',
    name: t('Reports.StockSummaryReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.StockSummaryReport),
    permissions: [permission(STAFF_BFF, STOCK_SUMMARY_REPORT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-summary-consignment-report',
    name: t('Reports.InventoryTransactionReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.StockSummaryConsignmentReport
    ),
    permissions: [
      permission(STAFF_BFF, STOCK_SUMMARY_CONSIGNMENT_REPORT, CREATE),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-summary-with-cogs-report',
    name: t('Reports.StockSummaryWithCogsReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.StockSummaryWithCogsReport),
    permissions: [permission(STAFF_BFF, STOCK_SUMMARY_REPORT, CREATE_VNPOST)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-summary-by-lot-report',
    name: t('Reports.StockSummaryByLotReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.StockSummaryByLotReport),
    permissions: [permission(STAFF_BFF, STOCK_SUMMARY_BY_LOT_REPORT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/warehouse-transaction-report',
    name: t('Reports.WarehouseTransactionReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.WarehouseTransactionReport),
    permissions: [permission(STAFF_BFF, WAREHOUSE_TRANSACTION, CREATE_REPORT)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/warehouse-transaction-report-v2',
    name: t('Reports.WarehouseTransactionReportV2'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.WarehouseTransactionReportV2),
    permissions: [
      permission(STAFF_BFF, WAREHOUSE_TRANSACTION, CREATE_REPORT_V2),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
    flagKey: FLAG_KEYS.WH_TRANSACTION_REPORT_V2,
  },
  {
    exact: true,
    path: '/reports/warehouse/warehouse-transaction-with-cogs-report',
    name: t('Reports.WarehouseTransactionWithCogsReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseNewWmsUrl,
      REPORT_PATHS.WarehouseTransactionWithCogsReport
    ),
    permissions: [permission(STAFF_BFF, WAREHOUSE_TRANSACTION_REPORT, CREATE)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-count-session-report',
    name: t('Reports.StockCountSessionReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.StockCountSessionReport),
    permissions: [permission(STAFF_BFF, STOCK_COUNT_SESSION, CREATE_REPORT)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/stock-transaction-report',
    name: t('Reports.StockTransactionReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.StockTransactionReport),
    permissions: [permission(STAFF_BFF, STOCK_TRANSACTION, CREATE_REPORT)],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  {
    exact: true,
    path: '/reports/warehouse/current-stock-report',
    name: t('Reports.CurrentStockReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseNewWmsUrl, REPORT_PATHS.CurrentStockReport),
    permissions: [
      permission(STAFF_BFF, WMS_CURRENT_STOCK_REPORT, CREATE_REPORT),
    ],
    hideSellerSwitch: true,
    hidePlatformSwitch: true,
  },
  // ----
  {
    exact: true,
    path: '/reports/logistics/shipping-report',
    name: t('Reports.ShippingReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLogisticsUrl, REPORT_PATHS.ShippingReport),
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, REPORT_PATHS.ShippingExpenseReport),
    name: t('Reports.ShippingExpenseReport'),
    redirectOutside: true,
    permissions: [permission(LO, LO_SUPER_ADMIN)],
    usePermissionPrefix: true,
  },
  // hardcode routes for hiding back button in supply chain repo
  ...HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES.map(exportType => ({
    exact: true,
    path: `/reports/export-history/${exportType}`,
    name: t('ExportHistory.Root'),
    component: IFrameContainer,
    hideTitle: true,
    hideBackButton: true,
    hideSellerSwitch: true,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      `${PURCHASING_PATHS.ExportHistoryStatic}/${exportType}`
    ),
  })),
  {
    exact: true,
    path: '/reports/export-history/:exportType',
    name: t('ExportHistory.Root'),
    component: IFrameContainer,
    hideTitle: true,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.ExportHistory),
  },
  {
    exact: true,
    path: '/reports/purchasing/purchase-report',
    name: t('Reports.PurchaseReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseReport),
    permissions: [permission(STAFF_BFF, PURCHASE_REPORT, READ)],
  },
  {
    exact: true,
    path: '/reports/finance/cash-funds',
    name: t('Reports.CashFunds'),
    component: IFrameContainer,
    flagKey: FLAG_KEYS.REFACTOR_FINANCE_POS,
    iFrameSrc: getSrc(baseFinanceUrl, FINANCE_WEB_APP_PATHS.CashFundsReport),
    permissions: [
      permission(STAFF_BFF, CASHIER_PAYMENT_RECEIPT, READ_REPORT_CASH_FUNDS),
    ],
  },
  {
    exact: true,
    path: '/reports/purchasing/import-report',
    name: t('Reports.ImportSupplierReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.ImportSupplierReport
    ),
    permissions: [permission(STAFF_BFF, DETAIL_IMPORT_SUPPLIER_REPORT, READ)],
  },
];

export default reportRoutes;
