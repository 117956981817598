import { lazy } from 'react';
import { ClusterOutlined } from '@ant-design/icons';
import {
  appConstants,
  resourceConstants,
  actionConstants,
  roleConstants,
  flagConstants,
} from 'constants/index';
import { SYSTEM_CATALOG_PATHS, PRODUCT_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { IRoute } from 'interfaces';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV2Url, baseWmsUrl } = getCommonConfig();

const { CATALOG, SELLER_GATEWAY, WMS, STAFF_BFF } = appConstants;
const {
  MASTER_CATEGORY,
  UNIT,
  CATEGORY,
  SHIPPING_TYPE,
  OLD_ATTRIBUTE_SET,
  OLD_ATTRIBUTE,
} = resourceConstants;
const { PHONGVU } = roleConstants;
const { READ, READ_MAPPING_MASTER_CAT } = actionConstants;
const { FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const catalogRoutes: IRoute[] = [
  // Parent menu
  {
    path: '/system-catalog',
    name: t('SystemCatalog.Root'),
    icon: ClusterOutlined,
    children: [
      '/system-catalog/master-categories',
      '/system-catalog/units',
      getSrc(baseWmsUrl, SYSTEM_CATALOG_PATHS.WarehouseLocations),
      '/system-catalog/category-mapping',
      '/system-catalog/attribute-sets',
      '/system-catalog/attributes',
      '/system-catalog/shipping-types',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/system-catalog/master-categories',
    name: t('SystemCatalog.MasterCategories'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SYSTEM_CATALOG_PATHS.MasterCategories
    ),
    permissions: [permission(CATALOG, MASTER_CATEGORY, READ)],
  },
  {
    exact: true,
    path: '/system-catalog/units',
    name: t('SystemCatalog.Units'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SYSTEM_CATALOG_PATHS.Units),
    permissions: [permission(CATALOG, UNIT, READ)],
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, SYSTEM_CATALOG_PATHS.WarehouseLocations),
    name: t('SystemCatalog.WarehouseLocations'),
    redirectOutside: true,
    needAppendSellerId: true,
    permissions: [permission(WMS, PHONGVU)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/system-catalog/category-mapping',
    name: t('SystemCatalog.CategoryMapping'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SYSTEM_CATALOG_PATHS.CategoryMapping
    ),
    permissions: [
      permission(SELLER_GATEWAY, CATEGORY, READ_MAPPING_MASTER_CAT),
    ],
  },
  {
    exact: true,
    path: '/system-catalog/attribute-sets',
    name: t('Products.AttributeSets'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.AttributeSets),
    permissions: [permission(CATALOG, OLD_ATTRIBUTE_SET, READ)],
    hideWhenEnabledFlagKey: FLAG_KEYS.ATTRIBUTE_SET_MANAGEMENT,
  },
  {
    exact: true,
    path: '/system-catalog/attributes',
    name: t('Products.Attributes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.Attributes),
    permissions: [permission(CATALOG, OLD_ATTRIBUTE, READ)],
    hideWhenEnabledFlagKey: FLAG_KEYS.ATTRIBUTE_SET_MANAGEMENT,
  },
  {
    exact: true,
    path: '/system-catalog/shipping-types',
    name: t('SystemCatalog.ShippingTypes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SYSTEM_CATALOG_PATHS.ShippingTypes
    ),
    permissions: [permission(STAFF_BFF, SHIPPING_TYPE, READ)],
  },
];

export default catalogRoutes;
