import { Modal } from 'antd';
import { commonConstants, serviceWorkerConstants } from 'constants/index';
import { t } from 'helpers/i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { notificationHelpers } from 'helpers';
import { MessagePayload } from 'firebase/messaging';
import { StoreContext } from 'contexts';

const { BROWSER_PERMISSIONS, IFRAME_CONTAINER_ID } = commonConstants;
const { COMMAND_NEW_NOTIFICATION } = serviceWorkerConstants;
const { installFirebaseAndUnsNotificationService } = notificationHelpers;

const BrowserPermissionModal: React.FC<{ browserPermissions: string[] }> = ({
  browserPermissions,
}) => {
  const [
    isModalBrowserPermissionsVisible,
    setIsModalBrowserPermissionsVisible,
  ] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<string>('');
  const { extraConfig } = useContext(StoreContext);

  useEffect(() => {
    if (!browserPermissions.length) {
      return;
    }

    browserPermissions.forEach(permission => {
      if (permission === BROWSER_PERMISSIONS.NOTIFICATIONS) {
        browserPermissionHandler[permission].askPermission();
      }
    });
  }, [browserPermissions]);

  const browserPermissionHandler = {
    [BROWSER_PERMISSIONS.NOTIFICATIONS]: {
      askPermission: async () => {
        if (Notification.permission === 'granted') {
          // Check whether notification permissions have already been granted
          // If it has, we can continue to the next step
          await installFirebaseAndUnsNotificationService(
            onReceiveNewNotification,
            extraConfig
          );
        } else if (Notification.permission !== 'denied') {
          // If the user has not denied the permission, we show modal to ask for permission
          setIsModalBrowserPermissionsVisible(true);
          setSelectedPermission(BROWSER_PERMISSIONS.NOTIFICATIONS);
        } else {
          /***
           * If the user has denied the permission, we can't do anything
           * We can only ask them to enable it manually
           */
        }
      },
      modalMessage: () => {
        return (
          <Trans i18nKey="BrowserPermissions.Description.Notification">
            Cần quyền truy cập&nbsp;
            <span className="text-primary text-medium">Thông báo</span>
            &nbsp;để user có thể nhận các thông tin mới nhất liên quan tới đơn
            hàng
          </Trans>
        );
      },
      onGrantPermission: () => {
        setIsModalBrowserPermissionsVisible(false);
        setSelectedPermission('');
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // If the user has granted the permission, we can continue to the next step
            return installFirebaseAndUnsNotificationService(
              onReceiveNewNotification,
              extraConfig
            );
          }
        });
      },
    },
  };

  const onReceiveNewNotification = useCallback(
    async (payload: MessagePayload) => {
      const iframe = document.getElementById(
        IFRAME_CONTAINER_ID
      ) as HTMLIFrameElement;

      iframe?.contentWindow?.postMessage(
        {
          type: COMMAND_NEW_NOTIFICATION,
          payload,
        },
        '*'
      );
    },
    []
  );

  useEffect(() => {
    // Add event listener to listen for new notification
    const handleEventFromServiceWorker = (event: MessageEvent) => {
      if (event.data.command === COMMAND_NEW_NOTIFICATION) {
        if (event.data.payload && event.data.payload.notification) {
          onReceiveNewNotification(event.data.payload);
        }
      }
    };

    navigator.serviceWorker.addEventListener(
      'message',
      handleEventFromServiceWorker
    );

    return () => {
      navigator.serviceWorker.removeEventListener(
        'message',
        handleEventFromServiceWorker
      );
    };
  }, [onReceiveNewNotification]);

  return (
    <Modal
      visible={isModalBrowserPermissionsVisible}
      title={t('BrowserPermissions.Title')}
      onOk={browserPermissionHandler[selectedPermission]?.onGrantPermission}
      onCancel={() => {
        setIsModalBrowserPermissionsVisible(false);
        setSelectedPermission('');
      }}
    >
      {browserPermissionHandler[selectedPermission]?.modalMessage()}
    </Modal>
  );
};

export default BrowserPermissionModal;
