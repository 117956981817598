const catalogResources = {
  PRODUCT: 'products',
  IMPORT: 'import',
  TERMINAL_PRODUCT: 'terminal_products',
  MASTER_CATEGORY: 'master_categories',
  ATTRIBUTE: 'attribute',
  ATTRIBUTE_SET: 'attribute_set',
  OLD_ATTRIBUTE: 'attributes',
  OLD_ATTRIBUTE_SET: 'attribute_sets',
  UNIT: 'units',
  SHIPPING_TYPE: 'shipping-types',
  BRAND: 'brand',
};

const ppmResources = {
  PRICE: 'price',
  PROMOTION: 'promotion',
  QR_PROMOTION: 'qr_promotion',
  COUPON: 'coupon',
  GIFT_CODE: 'gift_code',
  BUDGET: 'budget',
  BUCKET: 'bucket',
};

const sellerResources = {
  SELLER: 'seller',
  TERMINAL: 'terminal',
  TERMINAL_SPOS: 'terminal-spos',
  TERMINAL_GROUP: 'terminal-group',
  PROVIDER: 'provider',
  DELIVERY_PROVIDER: '3pl',
  PLATFORM: 'platform',
  DEALER: 'dealer',
};

const pageBuilderResources = {
  URL: 'urls',
  MENU: 'menus',
  BANNER: 'banners',
  BANNER_TYPE: 'banner_types',
  CAMPAIGN: 'campaigns',
  NEWS: 'news',
  LANDING_PAGE: 'landing_pages',
  LANDING_BLOCK: 'landing_blocks',
};

const policyResources = {
  SHIPPING_FEE: 'shipping-fee-rules',
  SYNONYM: 'synonym',
  POLICY: 'policy',
  DELIVERY_SERVICE: 'policy-v2-delivery-service',
  ADDITIONAL_SERVICE: 'policy-v2-additional-service',
  COD_SERVICE: 'policy-v2-cod-service',
  POLICY_V2: 'policy-v2-policy',
  APPROVAL_DEBT_CONFIG: 'approval_debt_config',
};

const fmsResources = {
  BANKING: 'banking',
  INVOICE: 'invoice',
  PERIOD_REPORT: 'period_report',
  REPORTS: 'reports',
  SYSTEM_ADMIN: 'system_admin',
  TOOLS_IMPORT: 'tools_import',
};

const staffFeResources = {
  PAYMENT_RECONCILE_REPORT: 'payment_reconcile_report',
  STOCK_TRANSFER_OUT_REPORT: 'stock_transfer_out_report',
};

const aplResources = {
  DISCOUNT_APPROVAL_CONFIGS: 'discount_approval_configs',
};

const staffBffResources = {
  CLEARANCE_REQUEST: 'clearance_request',
  INVENTORY: 'inventory',
  ORDERS: 'orders',
  ORDER_HEALTH: 'order_health',
  RETURN_REASONS: 'return_reasons',
  REFUND_REQUEST: 'refund_request',
  RETURN_REQUESTS: 'return_requests',
  SELLER_MANUAL_INVENTORY: 'seller_manual_inventory',
  SELLER_MANUAL_INVENTORY_READONLY: 'seller_manual_inventory_readonly',
  LOYALTY_COMPUTATION_CONFIGS: 'loyalty_computation_configs',
  STOCK_REQUEST: 'stock_request',
  SUPPLIER_QUOTATIONS: 'supplier_quotations',
  CLEARANCE_TYPE: 'clearance_type',
  SUPPLY_STOCK_PLAN: 'supply_stock_plan',
  PURCHASE_ORDER: 'purchase_order',
  PENDING_ORDER: 'pending_order',
  PURCHASE_INVOICES: 'purchase_invoices',
  MARKETPLACE_CONNECTOR: 'marketplace-connector',
  PENDING_SALES_ORDERS: 'pending_sales_orders',
  GOODS_COMING_TRACKING: 'gct',
  STOCK_TRANSFER: 'stock_transfer',
  PAYMENT_METHOD_CONFIG: 'payment-method-config',
  PICKING_LIST: 'picking_list',
  BINS: 'bins',
  IMPORT_REQUEST: 'import_request',
  QUALITY_CONTROL: 'quality-control',
  PACKING: 'packing',
  HANDOVER: 'handover',
  CREDIT_APPROVAL_CONFIGS: 'credit_approval_configs',
  RECEIVING_TICKET_SUPPLIER: 'receiving_ticket_supplier',
  SITE_LEAD_TIME: 'site_lead_time',
  SUPPLIER_MANAGEMENT: 'supplier_management',
  STOCK_ADJUSTMENT: 'stock_adjustment',
  WAREHOUSE_TRANSACTION: 'warehouse_transaction',
  PURCHASE_ORDER_RETURN: 'por',
  AUDIT_LOG: 'audit-log',
  SITE_TRANSFER_REPORT: 'site_transfer_report',
  STOCK_SUMMARY_REPORT: 'stock_summary_report',
  STOCK_SUMMARY_BY_LOT_REPORT: 'stock_summary_by_lot_report',
  WAREHOUSE_TRANSACTION_REPORT: 'warehouse_transaction_report',
  MISA_REPORT: 'misa_report',
  PURCHASE_STATUS: 'purchase_status',
  STOCK_COUNT_SESSION: 'stock_count_session',
  INVENTORY_TRACKING: 'inventory_tracking',
  PURCHASE_REPORT: 'purchase_report',
  STOCK_BALANCE: 'stock_balance',
  EXPORT_HISTORY: 'export_history',
  MANUFACTURE: 'manufacture',
  WAREHOUSE_EXPORT_REQUEST: 'warehouse_export_request',
  STOCK_PRODUCT_BY_BIN: 'stock_product_by_bin',
  STOCK_TRANSACTION: 'stock_transaction',
  IMPORT_HISTORY: 'import_history',
  CASHIER_PAYMENT_RECEIPT: 'cashier_payment_receipt',
  END_OF_SHIFT_REPORT: 'end_shift_report',
  CASH_BALANCE_REPORT: 'cash_balance_report',
  STOCK_SUMMARY_CONSIGNMENT_REPORT: 'stock_summary_consignment_report',
  WAREHOUSE_STOCK_COUNT_SESSION: 'wms_stock_count_session',
  WAREHOUSE_STOCK_COUNT_CYCLE: 'wms_stock_count_cycle',
  DEMAND_PLANNING: 'demand_planning',
  WEBSITE_EDITOR: 'website-editor',
  WMS_CURRENT_STOCK_REPORT: 'wms_current_stock_report',
  PAYMENT_RECEIPT_REPORT: 'payment_receipt_report',
  CONSIGNMENT_SALES_REPORT: 'consignment_sales_report',
  UPGRADING_PROCESS: 'upgrading_process',
  SUPPLY_SOURCE_WARNING: 'supply-source:warning-missing',
  LEAD_TIME_WARNING: 'lead-time:warning-missing',
  MOVEMENT: 'movement',
  ACCOUNTING: 'accounting',
  STOCK_REQUEST_DAILY: 'stock_request_daily',
  SERIAL_TRACKING: 'serial_tracking',
  MOVEMENT_ALL_PRODUCT: 'movement_all_product',
  DETAIL_IMPORT_SUPPLIER_REPORT: 'detail_import_supplier_report',
  REVENUE_REPORT: 'revenue_report',
  AUTO_GENERATED_SERIAL_LOT: 'auto_generated_serial_lot',
};

const sellerGatewayResources = {
  CATEGORY: 'category',
  SELLER_SALE_REPORT: 'seller-sale-report',
  SALE_REPORT: 'sale-report',
  MASAN_DEBT_REPORT: 'masan-debt-report',
  MASAN_SELLOUT_REPORT: 'masan-sellout-report',
  MASAN_INVENTORY_REPORT: 'masan-inventory-report',
};

const notificationResources = {
  MESSAGE: 'message',
  TEMPLATE: 'template',
  HISTORY: 'history',
  REPORT: 'report',
  PARAM: 'param',
  ZALO: 'zalo',
};

const loyaltyServiceResources = {
  TRANSACTION: 'transaction',
  TRANSACTION_POINT: 'transaction-point',
  TRANSACTION_SYSTEM: 'transaction-system',
  REPORT_BALANCE: 'report-balance',
  REPORT_TRANSACTION: 'report-transaction',
  MEMBER: 'member',
};

const rebateResources = {
  PROGRAM: 'program',
  RULE: 'rule',
};

const systemAdminResources = {
  SUPPORT_TOOL: 'support_tool',
  USER_SUPPORT_TOOL: 'user_support_tool',
  SQL_LOOKUP: 'sql_lookup',
  CATALOG_ADMIN: 'super_admin',
  USER_ADMIN_TOOL: 'admin-tool',
};

const ticketBffResources = {
  ISSUES: 'issues',
  ERP: 'erp',
};

const campaignManagementResources = {
  CAMPAIGN_MANAGEMENT: 'campaign',
  JOURNEY_BUILDER: 'journey',
};

const userSegmentResources = {
  GENERAL: 'general',
};

const smartBannerResources = {
  OPERATOR: 'operator',
};

const StaffManagementResources = {
  STAFF: 'staff',
  BIZ_GROUP: 'bizgroup',
};

const logisticsResources = {
  ROUTE_MANAGEMENT: 'route',
  LO_TRANSPORT: 'lo_transport',
  LO_DELIVERY_REQUEST: 'lo_delivery_request',
};

const biReportResources = {
  VIEW_REPORT_FOR_MARKETING_LEAD_SPS: 'view_report_for_marketing_lead_sps',
  VIEW_REPORT_FOR_OPERATION_SPS: 'view_report_for_operation_sps',
  VIEW_REPORT_FOR_MERCHANDISE_SPS: 'view_report_for_merchandise_sps',
  VIEW_REPORT_FOR_SALES_SPS: 'view_report_for_sales_sps',
  VIEW_REPORT_FOR_WAREHOUSE_MANAGEMENT_SPS:
    'view_report_for_warehouse_management_sps',
  VIEW_REPORT_FOR_SUPPLY_CHAIN_SPS: 'view_report_for_supply_chain_sps',
  VIEW_REPORT_FOR_BOD_SPS: 'view_report_for_bod_sps',
};

const supersetPublicResources = {
  VIEW_REPORT_SUPERSET_FOR_MARKETING: 'view_report_superset_for_marketing',
  VIEW_REPORT_SUPERSET_FOR_OPERATION: 'view_report_superset_for_operation',
  VIEW_REPORT_SUPERSET_FOR_MERCHANDISE: 'view_report_superset_for_merchandise',
  VIEW_REPORT_SUPERSET_FOR_SALES: 'view_report_superset_for_sales',
  VIEW_REPORT_SUPERSET_FOR_WAREHOUSE_MANAGEMENT:
    'view_report_superset_for_warehouse_management',
  VIEW_REPORT_SUPERSET_FOR_SUPPLY_CHAIN:
    'view_report_superset_for_supply_chain',
  VIEW_REPORT_SUPERSET_FOR_BOD: 'view_report_superset_for_bod',
  VIEW_REPORT_SUPERSET_FOR_MOIT: 'view_report_for_gov_moit_sps',
  VIEW_REPORT_SUPERSET_FOR_PO_PM: 'view_report_superset_for_po_pm',
  VIEW_REPORT_SUPERSET_FOR_LCM: 'view_report_for_lcm',
};

const onsBffResources = {
  ORDER_NOTIFICATIONS: 'order-notifications',
};

const loyaltyManagementBffResources = {
  LOY_CAMPAIGN: 'campaign',
};

export default {
  ...catalogResources,
  ...ppmResources,
  ...sellerResources,
  ...pageBuilderResources,
  ...policyResources,
  ...fmsResources,
  ...staffFeResources,
  ...aplResources,
  ...staffBffResources,
  ...sellerGatewayResources,
  ...notificationResources,
  ...rebateResources,
  ...systemAdminResources,
  ...loyaltyServiceResources,
  ...ticketBffResources,
  ...campaignManagementResources,
  ...StaffManagementResources,
  ...logisticsResources,
  ...userSegmentResources,
  ...biReportResources,
  ...supersetPublicResources,
  ...onsBffResources,
  ...loyaltyManagementBffResources,
  ...smartBannerResources,
};
