import { lazy } from 'react';
import { CarOutlined } from '@ant-design/icons';
import {
  appConstants,
  roleConstants,
  resourceConstants,
  actionConstants,
  flagConstants,
  guideConstants,
} from 'constants/index';
import { LOGISTIC_PATHS, FINANCE_WEB_APP_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { IRoute } from 'interfaces';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  baseLogisticsUrl,
  apiLogisticsUrl,
  baseRebateUrl,
  baseFinanceUrl,
} = getCommonConfig();

const { LO, STAFF_BFF } = appConstants;
const { LO_SUPER_ADMIN, LO_ADMIN, LO_MOD, LO_VIEW } = roleConstants;
const {
  ROUTE_MANAGEMENT,
  LO_TRANSPORT,
  AUDIT_LOG,
  LO_DELIVERY_REQUEST,
} = resourceConstants;
const {
  READ,
  UPDATE,
  CREATE,
  VIEW_AUDIT_LOG_LO,
  READ_DELIVERY_REPORT,
  READ_DASHBOARD,
} = actionConstants;
const { FLAG_KEYS } = flagConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;

const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));
const IFrameAuditLog = lazy(() => import('containers/shared/IFrameAuditLog'));

const logisticRoutes: IRoute[] = [
  // Parent menu
  {
    path: '/logistics',
    name: t('Logistics.Root'),
    icon: CarOutlined,
    children: [
      '/logistics/dashboard',
      LOGISTIC_PATHS.DashboardV2,
      LOGISTIC_PATHS.DeliveryRequests,
      '/logistics/bols',
      '/logistics/merge-bols',
      getSrc(apiLogisticsUrl, LOGISTIC_PATHS.PrintLabels),
      getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ErrorRequests),
      '/logistics/bus-lines',
      getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportDataForHandover),
      getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportCustomData),
      getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportDataForComparison),
      LOGISTIC_PATHS.RouteManagement,
      LOGISTIC_PATHS.RouteManagementConfiguration,
      LOGISTIC_PATHS.DeliveryReport,
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/logistics/dashboard',
    name: t('Logistics.Dashboard'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLogisticsUrl, LOGISTIC_PATHS.Dashboard),
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
      permission(LO, LO_VIEW),
    ],
    usePermissionPrefix: true,
  },
  /**
   * @deprecate by OMNI-663, OMNI-1147
   */
  {
    exact: true,
    path: '/logistics/bols',
    name: t('Logistics.BillsOfLading'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLogisticsUrl),
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
      permission(LO, LO_VIEW),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.DashboardV2,
    name: t('Logistics.LogisticsDashboard'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseFinanceUrl, FINANCE_WEB_APP_PATHS.LogisticsDashboard),
    permissions: [permission(STAFF_BFF, LO_DELIVERY_REQUEST, READ_DASHBOARD)],
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.DeliveryRequests,
    name: t('Logistics.DeliveryRequests'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, LOGISTIC_PATHS.DeliveryRequests),
    permissions: [
      permission(STAFF_BFF, LO_TRANSPORT, READ),
      permission(STAFF_BFF, LO_TRANSPORT, UPDATE),
    ],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.REQUEST_SHIPPING,
    },
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.DeliveryRequestDetail,
    name: t('Detail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, LOGISTIC_PATHS.DeliveryRequestDetail),
    permissions: [
      permission(STAFF_BFF, LO_TRANSPORT, READ),
      permission(STAFF_BFF, LO_TRANSPORT, UPDATE),
    ],
    hideTitle: true,
  },
  {
    exact: true,
    path: '/logistics/merge-bols',
    name: t('Logistics.MergeBoLs'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLogisticsUrl, LOGISTIC_PATHS.MergeBoLs),
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
      permission(LO, LO_VIEW),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, LOGISTIC_PATHS.PrintLabels),
    name: t('Logistics.PrintLabels'),
    redirectOutside: true,
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
      permission(LO, LO_VIEW),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, LOGISTIC_PATHS.PrintLabels),
    name: t('Logistics.ErrorRequests'),
    redirectOutside: true,
    permissions: [permission(LO, LO_SUPER_ADMIN)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/logistics/bus-lines',
    name: t('Logistics.BusLines'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseLogisticsUrl, LOGISTIC_PATHS.BusLines),
    permissions: [permission(LO, LO_SUPER_ADMIN), permission(LO, LO_ADMIN)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportDataForHandover),
    name: t('Logistics.ExportDataForHandover'),
    redirectOutside: true,
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportCustomData),
    name: t('Logistics.ExportCustomData'),
    redirectOutside: true,
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(apiLogisticsUrl, LOGISTIC_PATHS.ExportDataForComparison),
    name: t('Logistics.ExportDataForComparison'),
    redirectOutside: true,
    permissions: [
      permission(LO, LO_SUPER_ADMIN),
      permission(LO, LO_ADMIN),
      permission(LO, LO_MOD),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.RouteManagement,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, LOGISTIC_PATHS.RouteManagement),
    name: t('StaticRouteManagement.List'),
    permissions: [permission(STAFF_BFF, ROUTE_MANAGEMENT, READ)],
    flagKey: FLAG_KEYS.ROUTE_MANAGEMENT,
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.RouteManagementConfiguration,
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseRebateUrl,
      LOGISTIC_PATHS.RouteManagementConfiguration
    ),
    name: t('StaticRouteManagement.Configuration'),
    permissions: [permission(STAFF_BFF, ROUTE_MANAGEMENT, CREATE)],
    flagKey: FLAG_KEYS.ROUTE_MANAGEMENT,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.ROUTE_CONFIG,
    },
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.RouteManagementDetail,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, LOGISTIC_PATHS.RouteManagementDetail),
    name: t('StaticRouteManagement.Detail'),
    permissions: [permission(STAFF_BFF, ROUTE_MANAGEMENT, UPDATE)],
    flagKey: FLAG_KEYS.ROUTE_MANAGEMENT,
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.ActionHistory,
    name: t('Logistics.ActionHistory'),
    component: IFrameAuditLog,
    permissions: [permission(STAFF_BFF, AUDIT_LOG, VIEW_AUDIT_LOG_LO)],
  },
  {
    exact: true,
    path: LOGISTIC_PATHS.DeliveryReport,
    name: t('Logistics.DeliveryReport'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, LOGISTIC_PATHS.DeliveryReport),
    permissions: [
      permission(STAFF_BFF, LO_DELIVERY_REQUEST, READ_DELIVERY_REPORT),
    ],
    flagKey: FLAG_KEYS.DELIVERY_REPORT,
  },
];

export default logisticRoutes;
