import { lazy } from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import {
  appConstants,
  resourceConstants,
  actionConstants,
  flagConstants,
} from 'constants/index';
import { ENTITY_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { IRoute } from 'interfaces';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV2Url, baseSellerCenterTic41Url } = getCommonConfig();

const { SELLERS, STAFF_BFF } = appConstants;
const {
  PROVIDER,
  SELLER,
  TERMINAL_GROUP,
  TERMINAL,
  PLATFORM,
} = resourceConstants;
const { READ_UI, UPDATE } = actionConstants;
const { FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const entityRoutes: IRoute[] = [
  // Parent menu
  {
    path: '/entities',
    name: t('Entities.Root'),
    icon: IdcardOutlined,
    children: [
      '/entities/platforms',
      '/entities/platforms-tic41',
      '/entities/sellers',
      '/entities/terminal-groups',
      '/entities/terminals',
      '/entities/providers',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/entities/platforms',
    name: t('Entities.Platforms'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.Platforms),
    permissions: [permission(SELLERS, PLATFORM, READ_UI)],
  },
  {
    exact: true,
    path: '/entities/platforms-tic41',
    name: t('Entities.Platforms') + ' TIC-41',
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterTic41Url, ENTITY_PATHS.Platforms),
    permissions: [permission(STAFF_BFF, PLATFORM, UPDATE)],
    flagKey: FLAG_KEYS.SELF_SERVE_STAFF_WEBSITE,
  },
  {
    exact: true,
    path: '/entities/sellers',
    name: t('Entities.Sellers'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.Sellers),
    permissions: [permission(SELLERS, SELLER, READ_UI)],
  },
  {
    exact: true,
    path: '/entities/terminal-groups',
    name: t('Entities.TerminalGroups'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.TerminalGroups),
    permissions: [permission(SELLERS, TERMINAL_GROUP, READ_UI)],
  },
  {
    exact: true,
    path: '/entities/terminals',
    name: t('Entities.Terminals'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.Terminals),
    permissions: [permission(SELLERS, TERMINAL, READ_UI)],
  },
  {
    exact: true,
    path: '/entities/terminals/:id',
    name: t('Entities.TerminalDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.TerminalDetail),
    permissions: [permission(SELLERS, TERMINAL, READ_UI)],
  },
  {
    exact: true,
    path: '/entities/providers',
    name: t('Entities.Providers'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, ENTITY_PATHS.Providers),
    permissions: [permission(SELLERS, PROVIDER, READ_UI)],
  },
];

export default entityRoutes;
