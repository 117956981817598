import React from 'react';
import { Select } from 'antd';
import { ISeller } from 'interfaces';
import { t } from 'helpers/i18n';

interface SellerSelectProps {
  sellerSelectList: ISeller[];
  handleChangeSeller: Function;
}

const SellerSelect: React.FC<SellerSelectProps> = props => {
  const { sellerSelectList, handleChangeSeller } = props;

  const firstSeller = sellerSelectList[0];
  const options = sellerSelectList.map(({ id, displayName }) => {
    return { label: displayName, value: id.toString() };
  });

  const handleChange = (value: string) => {
    handleChangeSeller(Number(value));
  };

  return (
    <div className="mt-half">
      <Select
        style={{ width: '50%' }}
        showSearch
        placeholder={t('SearchSellerPlaceholder')}
        optionFilterProp="label"
        options={options}
        defaultValue={firstSeller.id.toString()}
        onChange={handleChange}
      />
    </div>
  );
};

export default SellerSelect;
