import commonConstants from 'constants/common';
import { IRoute } from 'interfaces';
import { getPermissionCode } from './user';

const { ALL } = commonConstants;

const checkPermission = (
  permissions: string[],
  app: string,
  resource: string,
  action?: string
) => {
  const perform = getPermissionCode(app, resource, action);
  const performAllAction = getPermissionCode(app, resource, ALL);
  const performAllResource = getPermissionCode(app, ALL, ALL);
  const allPerforms = [perform, performAllAction, performAllResource];

  return !!(permissions && permissions.find(p => allPerforms.includes(p)));
};

const isItemAccessible = (item: IRoute, currentPermissions: string[]) => {
  const { permissions, children } = item;
  if (!permissions) return true;
  const intersection = permissions.find(x => {
    const { app, resource, action } = x;
    if (children) {
      if (!currentPermissions) return false;
      return currentPermissions.some(p => {
        return (
          p.startsWith(getPermissionCode(app, resource, action)) ||
          p.startsWith(getPermissionCode(app, resource, ALL)) ||
          p.startsWith(getPermissionCode(app, ALL, ALL))
        );
      });
    }
    return checkPermission(currentPermissions, app, resource, action);
  });
  return !!intersection;
};

export default { isItemAccessible, checkPermission };
