import { useState, useEffect } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import lodashUniq from 'lodash/uniq';
import commonHelpers from 'helpers/common';
import routes, { routeMapping } from 'routes';
import { IRoute } from 'interfaces';
import { commonConstants } from 'constants/index';
import { userHelpers } from 'helpers';

const { TABLET_WIDTH } = commonConstants;
const { getWindowDimensions } = commonHelpers;
const { setCurrentSite } = userHelpers;

const useAppMenu = () => {
  const [selectedKey, setSelectedKey] = useState('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { matchedRoutes, currentRoute } = useCurrentRoute();

  useEffect(() => {
    // Mission: Find the correct menu item to highlight!
    // Step 1: find the nearest submenu
    const reversedMatchedRoutes = [...matchedRoutes].reverse(); // have to reverse because the closest match stays at the end of array
    const nearestSubmenu = reversedMatchedRoutes.find(r => !!r.children); // a submenu route is one with children
    if (!nearestSubmenu) {
      // current route has no child => highlight it!
      if (currentRoute?.path) setSelectedKey(currentRoute?.path);
    } else {
      // Step 2: see if the current route is among the submenu's children;
      // if not, the current route is nested (aka not visible in the menu tree) and
      // we need to find the closest visible ancestor
      const paths = reversedMatchedRoutes.map(r => r.path);
      const target = paths.find(p => nearestSubmenu.children?.includes(p));
      if (target) setSelectedKey(target);
    }
  }, [matchedRoutes, currentRoute]);

  useEffect(() => {
    setOpenKeys(openKeys =>
      // add the active menu and its ancestors to the set of expanded submenus,
      // while keeping the previously expanded ones expanded
      lodashUniq([...openKeys, ...matchedRoutes.map(r => r.path)])
    );
  }, [matchedRoutes]);

  return { selectedKey, openKeys, setOpenKeys };
};

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = () => setDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...dimensions, isTabletView: dimensions.width <= TABLET_WIDTH };
};

export const useCurrentRoute = () => {
  const [matchedRoutes, setMatchedRoutes] = useState<IRoute[]>([]);
  const location = usePathname();

  const updateCurrentRoute = () => {
    //Step 1: find all matched parent routes. E.g: /orders/marketplace/31OADF4 --> /, /orders, /orders/marketplace, /orders/marketplace/:id
    let matchedRoutes = routes.filter(route =>
      matchPath(window.location.pathname, { path: route.path })
    );
    // If the route is static, remove dynamic route
    if (matchedRoutes.some(route => route.path === window.location.pathname)) {
      matchedRoutes = matchedRoutes.filter(route => !route.path.includes(':'));
    }
    //Step 2: sort by path name
    const sorted = matchedRoutes?.sort((a, b) =>
      a.path < b.path ? -1 : a.path > b.path ? 1 : 0
    );
    setMatchedRoutes(sorted);
  };

  // update current route on each navigation
  useEffect(() => {
    updateCurrentRoute();
  }, [location]);

  return {
    matchedRoutes,
    currentRoute: matchedRoutes[matchedRoutes.length - 1] as IRoute | undefined,
  };
};

const useSyncIFramePathNameEffect = (filteredRoutes: IRoute[]) => {
  useEffect(() => {
    // common function normalize path name
    const commonNormalizePathName = (eventData: string) => {
      let normalizedPathname = '';
      if (eventData.startsWith('pathname')) {
        const receivedPathname = eventData.split('=')[1];
        /*
        Find route whose iFrameSrc matches with the received pathname then set the normalized pathname.
        The normalized pathname in this case retains the path of the parent app while appending the dynamic params from the child app
        */
        filteredRoutes.some(route => {
          if (route.iFrameSrc) {
            const iFrameURL = new URL(route.iFrameSrc);
            const matchedPath = matchPath(receivedPathname, {
              path: iFrameURL.pathname,
              exact: true,
            });
            if (matchedPath) {
              normalizedPathname = generatePath(route.path, matchedPath.params);
            }
            return Boolean(matchedPath); // break iteration if return true
          }
          return false;
        });
      }
      return normalizedPathname;
    };

    // If you want to sync path name for an iframe, please declare here
    const configs: {
      // Matcher to select the right handler
      matcher: RegExp;
      // Returns the parent pathName corresponding to the iframe's one.
      // Almost is ad-hoc implementation due to the route configs
      normalizePathName: (
        iframePathName: string,
        event: MessageEvent
      ) => string;
    }[] = [
      {
        matcher: /^(http|https):\/\/erp.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/sellercenterv(1|2).*/,
        normalizePathName: (pathName, event) => {
          let appPathName = pathName;

          const { host } = new URL(event.origin);

          const hitMapping = Object.entries(routeMapping[host]).find(
            ([path]) => {
              return matchPath(pathName, { path, exact: true });
            }
          );

          if (hitMapping) {
            const [path, parentPath] = hitMapping;

            const matchedResult = matchPath(pathName, {
              path,
              exact: true,
            });

            if (matchedResult) {
              const { params } = matchedResult;
              appPathName = generatePath(parentPath, params);
            }
          }

          return appPathName;
        },
      },
      {
        matcher: /^(http|https):\/\/ticket-management.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/rebate-campaign.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/wms-web.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/supplychain.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/finance.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/spos.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/loyalty-management.*/,
        normalizePathName: commonNormalizePathName,
      },
      {
        matcher: /^(http|https):\/\/ppm.*/,
        normalizePathName: (pathName, event) => {
          let appPathName = pathName;
          const { host } = new URL(event.origin);

          const hitMapping = Object.entries(routeMapping[host]).find(
            ([path]) => {
              return matchPath(pathName, { path, exact: true });
            }
          );
          if (hitMapping) {
            const [path, parentPath] = hitMapping;

            const matchedResult = matchPath(pathName, {
              path,
              exact: true,
            });

            if (matchedResult) {
              const { params } = matchedResult;
              appPathName = generatePath(parentPath, params);
            }
          }
          return appPathName;
        },
      },
      {
        matcher: /^(http|https):\/\/app.mkt.*/,
        normalizePathName: (pathName, event) => {
          let appPathName = pathName;
          const { host } = new URL(event.origin);

          const hitMapping = Object.entries(routeMapping[host]).find(
            ([path]) => {
              return matchPath(pathName, { path, exact: true });
            }
          );
          if (hitMapping) {
            const [path, parentPath] = hitMapping;

            const matchedResult = matchPath(pathName, {
              path,
              exact: true,
            });

            if (matchedResult) {
              const { params } = matchedResult;
              appPathName = generatePath(parentPath, params);
            }
          }
          return appPathName;
        },
      },
      {
        matcher: /^(http|https):\/\/smart-banner*/,
        normalizePathName: (pathName, event) => {
          let appPathName = pathName;
          const { host } = new URL(event.origin);

          const hitMapping = Object.entries(routeMapping[host]).find(
            ([path]) => {
              return matchPath(pathName, { path, exact: true });
            }
          );
          if (hitMapping) {
            const [path, parentPath] = hitMapping;

            const matchedResult = matchPath(pathName, {
              path,
              exact: true,
            });

            if (matchedResult) {
              const { params } = matchedResult;
              appPathName = generatePath(parentPath, params);
            }
          }
          return appPathName;
        },
      },
    ];

    const handleMessageFromIFrame = (event: MessageEvent) => {
      const config = configs.find(config => config.matcher.test(event.origin));

      if (!config || typeof event.data !== 'string') return;

      const subs = event.data
        .replace(event.origin, '')
        .split('?')
        .filter(Boolean);
      const searchStr = subs.length === 1 ? '' : `?${subs[1]}`;

      const syncPathName = (pathName: string) => {
        window.history.pushState({}, '', pathName);
        window.dispatchEvent(new Event('popstate'));
      };

      try {
        const pathName = config.normalizePathName(subs[0], event);
        const appUrl = window.location.pathname + window.location.search;
        const iframeUrl = pathName + searchStr;

        const shouldSyncPathName =
          pathName &&
          // Only sync when the current pathname is different from the message.
          appUrl !== iframeUrl &&
          // However, the iframe might post an unknown pathname so that leads to
          // the 404 page. A simple way to prevent this is re-verify it
          filteredRoutes.some(route =>
            matchPath(pathName, {
              path: route.path,
              exact: true,
            })
          );

        if (shouldSyncPathName) {
          syncPathName(pathName + searchStr);
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('message', handleMessageFromIFrame);

    return () => {
      window.removeEventListener('message', handleMessageFromIFrame);
    };
  }, [filteredRoutes]);
};

const usePathname = () => {
  const [location, setLocation] = useState<{
    pathname: string;
    hash: string;
  }>({ pathname: '', hash: '' });
  const currentLocation = useLocation();

  useEffect(() => {
    setLocation({
      pathname: currentLocation.pathname,
      hash: currentLocation.hash,
    });
  }, [currentLocation]);

  useEffect(() => {
    function onPopState() {
      setLocation({
        pathname: window.location.pathname,
        hash: window.location.hash,
      });
    }

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  return location;
};

const useHandleRouteMappingRequest = () => {
  const history = useHistory();

  useEffect(() => {
    const MESSAGE_TYPE = {
      REQUEST_ROUTE_MAPPING: 'request-route-mapping',
      RESPONSE_ROUTE_MAPPING: 'response-route-mapping',
      NAVIGATE_HISTORY: 'navigate-history',
      BACK_TO_HOME: 'back-to-home',
      SET_CURRENT_SITE: 'set_current_site',
    };

    const handleMessage = (event: MessageEvent) => {
      if (typeof event.data === 'object' && event.source !== window) {
        if (event.data.type === MESSAGE_TYPE.REQUEST_ROUTE_MAPPING)
          (event.source as Window)?.postMessage(
            {
              type: MESSAGE_TYPE.RESPONSE_ROUTE_MAPPING,
              payload: routeMapping,
            },
            '*'
          );

        /**
         * Problem: Micro-FrontEnds can't cross routing to others
         * Solution: Using pointer for go back and next by browser history.
         * TODO: support navigate exactly path
         */
        if (event.data.type === MESSAGE_TYPE.NAVIGATE_HISTORY) {
          const pointer = Number(event.data.pointer) || 0;
          history.go(pointer);
        }

        // Option allow to action go back home from child app
        if (event.data.type === MESSAGE_TYPE.BACK_TO_HOME) {
          history.push('/');
        }

        if (event.data.type === MESSAGE_TYPE.SET_CURRENT_SITE) {
          setCurrentSite(event.data.siteId);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [history]);
};

/**
 *
 * @returns {URLSearchParams}
 */
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default {
  useAppMenu,
  useWindowDimensions,
  useCurrentRoute,
  useSyncIFramePathNameEffect,
  useHandleRouteMappingRequest,
  usePathname,
  useQuery,
};
