import { FireOutlined } from '@ant-design/icons';
import { INTERNAL_PATH } from 'constants/paths';
import { t } from 'helpers/i18n';
import { lazy } from 'react';

const MenuList = lazy(() => import('containers/MenuList'));
const FlagsList = lazy(() => import('containers/FLagsList'));

const internalRoutes = [
  {
    name: t('Internal.Root'),
    path: INTERNAL_PATH.ROOT,
    icon: FireOutlined,
    children: [INTERNAL_PATH.MENU_LIST_PATH, INTERNAL_PATH.FLAGS_LIST_PATH],
  },
  {
    exact: true,
    path: INTERNAL_PATH.MENU_LIST_PATH,
    name: t('Router.Root'),
    component: MenuList,
  },
  {
    path: INTERNAL_PATH.FLAGS_LIST_PATH,
    name: t('Internal.FlagsList.Root'),
    hideTitle: true,
    component: FlagsList,
  },
];

export default internalRoutes;
