import { lazy } from 'react';
import { GiftOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import {
  USER_SEGMENT_PATHS,
  CAMPAIGN_MANAGEMENT_PATHS,
  SMART_BANNER_PATHS,
} from 'constants/paths';
import { commonHelpers } from 'helpers';
import {
  actionConstants,
  appConstants,
  flagConstants,
  resourceConstants,
} from 'constants/index';
import configHelpers from 'helpers/config';

const { FLAG_KEYS } = flagConstants;
const { getCommonConfig } = configHelpers;
const {
  baseUserSegmentUrl,
  baseCampaignManagementUrl,
  baseSmartBannerUrl,
} = getCommonConfig();

const {
  MARKETING_AUTOMATION,
  USER_SEGMENT_BFF,
  SMART_BANNER_BFF,
} = appConstants;
const {
  CAMPAIGN_MANAGEMENT,
  JOURNEY_BUILDER,
  GENERAL,
  OPERATOR,
} = resourceConstants;
const { READ } = actionConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const promotionRoutes = [
  // Parent menu
  {
    path: '/cdp-marketing',
    name: t('CampaignManagement.CDPMarketing'),
    icon: GiftOutlined,
    children: [
      '/cdp-marketing/campaigns',
      '/cdp-marketing/journey-builder',
      '/cdp-marketing/segmentation/list',
      '/cdp-marketing/transactions',
      '/cdp-marketing/notifications',
      '/cdp-marketing/smart-banner',
    ],
  },
  // Sub menu
  {
    exact: true,
    path: '/cdp-marketing/campaigns',
    name: t('CampaignManagement.Campaigns'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCampaignManagementUrl),
    permissions: [permission(MARKETING_AUTOMATION, CAMPAIGN_MANAGEMENT, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/campaigns/detail/:id',
    name: t('CampaignManagement.CampaignDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseCampaignManagementUrl,
      CAMPAIGN_MANAGEMENT_PATHS.PromotionDetail
    ),
    permissions: [permission(MARKETING_AUTOMATION, CAMPAIGN_MANAGEMENT, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/journey-builder',
    name: t('CampaignManagement.MultiStepCampaigns'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseCampaignManagementUrl,
      CAMPAIGN_MANAGEMENT_PATHS.JourneyBuilder
    ),
    permissions: [permission(MARKETING_AUTOMATION, JOURNEY_BUILDER, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/journey-builder/detail',
    name: t('CampaignManagement.MultiStepCampaignDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseCampaignManagementUrl,
      CAMPAIGN_MANAGEMENT_PATHS.JourneyBuilderDetail
    ),
    permissions: [permission(MARKETING_AUTOMATION, JOURNEY_BUILDER, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/transactions',
    name: t('CampaignManagement.ExploreTransactions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseCampaignManagementUrl,
      CAMPAIGN_MANAGEMENT_PATHS.Transactions
    ),
    permissions: [permission(MARKETING_AUTOMATION, CAMPAIGN_MANAGEMENT, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/segmentation/list',
    name: t('CampaignManagement.Segmentation.Root'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseUserSegmentUrl, USER_SEGMENT_PATHS.Segments),
    permissions: [permission(USER_SEGMENT_BFF, GENERAL, READ)],
  },
  {
    exact: true,
    path: '/cdp-marketing/smart-banner',
    name: t('CampaignManagement.SmartBanner.Root'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSmartBannerUrl, SMART_BANNER_PATHS.SmartBanners),
    permissions: [permission(SMART_BANNER_BFF, OPERATOR, READ)],
    flagKey: FLAG_KEYS.EPIC_MA_150,
  },
  {
    exact: true,
    path: '/cdp-marketing/smart-banner/detail/:id',
    name: t('CampaignManagement.SmartBanner.Root'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSmartBannerUrl, SMART_BANNER_PATHS.SmartBannerDetail),
    permissions: [permission(SMART_BANNER_BFF, OPERATOR, READ)],
    flagKey: FLAG_KEYS.EPIC_MA_150,
  },
];

export default promotionRoutes;
