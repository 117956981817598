import { TransactionOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  flagConstants,
  guideConstants,
  resourceConstants,
  roleConstants,
} from 'constants/index';
import {
  CASHIER_PATHS,
  DPOS_PATHS,
  FINANCE_WEB_APP_PATHS,
  PURCHASING_PATHS,
} from 'constants/paths';
import { commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { lazy } from 'react';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  basePosUrl,
  baseErpUrl,
  baseFinanceUrl,
  baseSupplyChainUrl,
  baseDposUrl,
  baseSposUrl,
} = getCommonConfig();
const { STAFF_BFF, STAFF_FE } = appConstants;
const { POS_PHONGVU_CASHIER, CASHIER, CASHIER_END_SHIFT } = roleConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { EXPORT_HISTORY, CASHIER_PAYMENT_RECEIPT } = resourceConstants;
const { READ, CREATE } = actionConstants;
const { FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const cashierRoutes = [
  // Parent menu
  {
    path: '/cashier',
    name: t('Cashier.Root'),
    icon: TransactionOutlined,
    children: [
      '/cashier/confirm-order',
      '/cashier/receipt',
      '/cashier/other-payment-in',
      '/cashier/payoo',
      '/cashier/refund',
      '/cashier/refund-requests',
      '/cashier/other-payment-out',
      '/cashier/endshift',
      '/cashier/finance',
    ],
  },
  // Sub menus (level 2 legacy menus)
  {
    exact: true,
    path: '/cashier/confirm-order',
    name: t('Cashier.ConfirmOrder'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.ConfirmOrder),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_CONFIRM_ORDER,
    },
  },
  {
    exact: true,
    path: '/cashier/receipt',
    name: t('Cashier.Receipt'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.Receipt),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_CREATE_RECEIPT,
    },
  },
  {
    exact: true,
    path: '/cashier/other-payment-in',
    name: t('Cashier.OtherPaymentIn'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.OtherPaymentIn),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_OTHER_PAYMENT_IN,
    },
  },
  {
    exact: true,
    path: '/cashier/payoo',
    name: t('Cashier.Payoo'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.Payoo),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_PAYOO_COLLECTION,
    },
  },
  {
    exact: true,
    path: '/cashier/refund',
    name: t('Cashier.Refund'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.Refund),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_CREATE_REFUND,
    },
  },
  {
    exact: true,
    path: '/cashier/refund-requests',
    name: t('Cashier.RefundRequests'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, CASHIER_PATHS.RefundRequests),
    permissions: [permission(STAFF_BFF, CASHIER)],
    usePermissionPrefix: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CASHIER.ACCOUNTING_CASHIER,
      userGuideCode: USER_GUIDE_CODE.CASHIER.REFUND_REQUESTS,
    },
  },
  {
    exact: true,
    path: '/cashier/other-payment-out',
    name: t('Cashier.OtherPaymentOut'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.OtherPaymentOut),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/cashier/endshift',
    name: t('Cashier.EndShift'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePosUrl, CASHIER_PATHS.EndShift),
    usePermissionPrefix: true,
    permissions: [permission(STAFF_FE, POS_PHONGVU_CASHIER)],
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.AS.AS,
      userGuideCode: USER_GUIDE_CODE.AS.CASHIER_END_SHIFT,
    },
  },

  // level 2 menu - refactor fms/accounting
  {
    path: '/cashier/finance',
    name: t('Cashier.PaymentManagement'),
    children: [
      '/cashier/finance/create-payment-receipt-in',
      '/cashier/finance/create-payment-receipt-out',
      '/cashier/finance/payment-receipt-list',
      '/cashier/finance/end-shift',
      '/cashier/finance/end-shift-history',
    ],
  },

  // level 3 menu
  {
    exact: true,
    path: '/cashier/finance/create-payment-receipt-in',
    name: t('Cashier.CreatePaymentReceiptIn'),
    component: IFrameContainer,
    flagKey: FLAG_KEYS.REFACTOR_FINANCE_POS,
    iFrameSrc: getSrc(
      baseFinanceUrl,
      FINANCE_WEB_APP_PATHS.CreatePaymentReceiptIn
    ),
    permissions: [permission(STAFF_BFF, CASHIER_PAYMENT_RECEIPT, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CASHIER.ACCOUNTING_CASHIER,
      userGuideCode: USER_GUIDE_CODE.CASHIER.CREATE_PAYMENT_RECEIPT_IN,
    },
  },
  {
    exact: true,
    path: '/cashier/finance/create-payment-receipt-out',
    name: t('Cashier.CreatePaymentReceiptOut'),
    component: IFrameContainer,
    flagKey: FLAG_KEYS.REFACTOR_FINANCE_POS,
    iFrameSrc: getSrc(
      baseFinanceUrl,
      FINANCE_WEB_APP_PATHS.CreatePaymentReceiptOut
    ),
    permissions: [permission(STAFF_BFF, CASHIER_PAYMENT_RECEIPT, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CASHIER.ACCOUNTING_CASHIER,
      userGuideCode: USER_GUIDE_CODE.CASHIER.CREATE_PAYMENT_RECEIPT_OUT,
    },
  },
  {
    exact: true,
    path: '/cashier/finance/payment-receipt-list',
    name: t('Cashier.PaymentReceiptList'),
    component: IFrameContainer,
    flagKey: FLAG_KEYS.REFACTOR_FINANCE_POS,
    iFrameSrc: getSrc(baseFinanceUrl, FINANCE_WEB_APP_PATHS.PaymentReceiptList),
    permissions: [permission(STAFF_BFF, CASHIER_PAYMENT_RECEIPT, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CASHIER.ACCOUNTING_CASHIER,
      userGuideCode: USER_GUIDE_CODE.CASHIER.LIST_PAYMENT_RECEIPT_OUT,
    },
  },
  {
    exact: true,
    path: '/cashier/export-history/:exportType',
    name: t('ExportHistory.Root'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, EXPORT_HISTORY, READ)],
    hideTitle: true,
    hideBackButton: true,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.ExportHistory),
  },
  {
    exact: true,
    path: '/cashier/finance/end-shift',
    name: t('DPOS.EndShift'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl, DPOS_PATHS.EndShift),
    permissions: [permission(STAFF_BFF, CASHIER_END_SHIFT)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
  },
  {
    exact: true,
    path: '/cashier/finance/end-shift-history',
    name: t('DPOS.EndShiftHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSposUrl, DPOS_PATHS.EndShiftHistory),
    permissions: [permission(STAFF_BFF, CASHIER_END_SHIFT)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
    isModalItem: true,
  },
];

export default cashierRoutes;
