import { lazy } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  guideConstants,
  resourceConstants,
  flagConstants,
} from 'constants/index';
import { SETTING_PATHS, FINANCE_WEB_APP_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseErpUrl, baseSellerCenterV2Url, baseFinanceUrl } = getCommonConfig();
const { APL, STAFF_BFF, SELLERS } = appConstants;
const {
  RETURN_REASONS,
  DISCOUNT_APPROVAL_CONFIGS,
  LOYALTY_COMPUTATION_CONFIGS,
  MARKETPLACE_CONNECTOR,
  TERMINAL_SPOS,
  PAYMENT_METHOD_CONFIG,
  DELIVERY_PROVIDER,
  SYNONYM,
  DELIVERY_SERVICE,
  ADDITIONAL_SERVICE,
  COD_SERVICE,
  POLICY_V2,
  APPROVAL_DEBT_CONFIG,
} = resourceConstants;
const { READ, UPDATE } = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const settingRoutes = [
  // Level 1 menu
  {
    path: '/settings',
    name: t('Settings.Root'),
    icon: SettingOutlined,
    children: [
      '/settings/monetary-approval',
      '/settings/return-reasons',
      '/settings/loyalty-program',
      '/settings/marketplace-connector',
      '/settings/delivery-providers',
      '/settings/shipping-services',
      '/settings/additional-services',
      '/settings/cod-services',
      '/settings/payment-methods',
      '/settings/terminals-sposes',
      '/settings/policies',
      '/settings/synonyms',
      '/settings/deb-approval-config',
    ],
  },
  // Level 2 menus
  {
    path: '/settings/monetary-approval',
    name: t('Settings.MonetaryApproval'),
    children: ['/settings/monetary-approval/discount-approval'],
  },
  {
    exact: true,
    path: '/settings/return-reasons',
    name: t('Settings.CancellationReasons'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, SETTING_PATHS.ReturnReasons),
    permissions: [permission(STAFF_BFF, RETURN_REASONS, UPDATE)],
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/settings/loyalty-program',
    name: t('Settings.LoyaltyProgram'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, SETTING_PATHS.LoyaltyProgram),
    permissions: [permission(STAFF_BFF, LOYALTY_COMPUTATION_CONFIGS, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SETTINGS,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.LOYALTY_PROGRAM,
    },
  },
  {
    exact: true,
    path: '/settings/marketplace-connector',
    name: t('Settings.MarketplaceConnector'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SETTING_PATHS.MarketplaceConnector
    ),
    permissions: [permission(STAFF_BFF, MARKETPLACE_CONNECTOR, READ)],
    hideWhenEnabledFlagKey: EXTENDED_FLAG_KEYS.MARKETPLACE_CONNECTOR_V2.key,
  },
  {
    exact: true,
    path: '/settings/marketplace-connector',
    name: t('Settings.MarketplaceConnector'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseFinanceUrl,
      FINANCE_WEB_APP_PATHS.MarketplaceConnector
    ),
    permissions: [permission(STAFF_BFF, MARKETPLACE_CONNECTOR, READ)],
    flagKey: EXTENDED_FLAG_KEYS.MARKETPLACE_CONNECTOR_V2.key,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SETTINGS,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.MARKETPLACE_CONNECTOR,
    },
  },
  {
    exact: true,
    path: '/settings/delivery-providers',
    name: t('Settings.DeliveryProviders'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.DeliveryProviders),
    permissions: [permission(SELLERS, DELIVERY_PROVIDER, UPDATE)],
  },
  {
    exact: true,
    path: '/settings/shipping-services',
    name: t('Settings.ShippingServices'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.ShippingServices),
    permissions: [permission(STAFF_BFF, DELIVERY_SERVICE, READ)],
    hideTitle: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SHIPPING_POLICY,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/shipping-services/policy/create',
    name: t('Settings.ShippingPolicy'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SETTING_PATHS.ShippingServicesCreate
    ),
    permissions: [permission(STAFF_BFF, DELIVERY_SERVICE, UPDATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SHIPPING_POLICY_v2,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/shipping-services/policy/:policyId',
    name: t('Settings.ShippingPolicy'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SETTING_PATHS.ShippingServicesDetail
    ),
    permissions: [permission(STAFF_BFF, DELIVERY_SERVICE, UPDATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SHIPPING_POLICY_v2,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/additional-services',
    name: t('Settings.AdditionalServices'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.AdditionalServices),
    permissions: [permission(STAFF_BFF, ADDITIONAL_SERVICE, READ)],
    hideTitle: true,
    flagKey: FLAG_KEYS.CONFIGS_ADDITIONAL_SERVICES,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.ADDITIONAL_POLICY_v2,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/additional-services/policy/create',
    name: t('Settings.AdditionalPolicy'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SETTING_PATHS.AdditionalServicesCreate
    ),
    permissions: [permission(STAFF_BFF, ADDITIONAL_SERVICE, UPDATE)],
    flagKey: FLAG_KEYS.CONFIGS_ADDITIONAL_SERVICES,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.ADDITIONAL_POLICY_v2,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/additional-services/policy/:policyId',
    name: t('Settings.AdditionalPolicy'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      SETTING_PATHS.AdditionalServicesDetail
    ),
    permissions: [permission(STAFF_BFF, ADDITIONAL_SERVICE, UPDATE)],
    flagKey: FLAG_KEYS.CONFIGS_ADDITIONAL_SERVICES,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.ADDITIONAL_POLICY_v2,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/cod-services',
    name: t('Settings.CODServices'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.CODServices),
    permissions: [permission(STAFF_BFF, COD_SERVICE, READ)],
    hideTitle: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.COD_SERVICE,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/policies',
    name: t('Settings.Policies'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.Policies),
    permissions: [permission(STAFF_BFF, POLICY_V2, READ)],
    hideTitle: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.HIGHLIGHT,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/terminals-sposes',
    name: t('Settings.SPOSDevice'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.SposDevices),
    permissions: [permission(SELLERS, TERMINAL_SPOS, READ)],
    hideTitle: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.TERMINAL_SPOS,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.CREATE,
    },
  },
  {
    exact: true,
    path: '/settings/payment-methods',
    name: t('Settings.PaymentMethods'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.PaymentMethods),
    permissions: [permission(STAFF_BFF, PAYMENT_METHOD_CONFIG, READ)],
  },
  // Level 3 menus
  {
    exact: true,
    path: '/settings/monetary-approval/discount-approval',
    name: t('Settings.DiscountApproval'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, SETTING_PATHS.DiscountApproval),
    permissions: [permission(APL, DISCOUNT_APPROVAL_CONFIGS, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SETTINGS.SETTINGS,
      userGuideCode: USER_GUIDE_CODE.SETTINGS.DISCOUNT_APPROVAL,
    },
  },
  {
    exact: true,
    path: '/settings/synonyms',
    name: t('Synonyms'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SETTING_PATHS.SynonymList),
    permissions: [permission(STAFF_BFF, SYNONYM, READ)],
  },
  {
    exact: true,
    path: '/settings/deb-approval-config',
    name: t('Settings.DebtApprovalConfig'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, SETTING_PATHS.DebtApprovalConfig),
    permissions: [permission(STAFF_BFF, APPROVAL_DEBT_CONFIG, READ)],
  },
];

export default settingRoutes;
