import configHelpers from 'helpers/config';

const { getClientConfig, getCommonConfig } = configHelpers;
const { baseWebsiteEditorUrl, baseNativeAppEditorUrl } = getCommonConfig();
const { iam } = getClientConfig();

const { clientId, oauthDomain } = iam;

const CUSTOM_WEBSITE_UI_PATH = `${baseWebsiteEditorUrl}/website/:websiteId?clientId=${clientId}&authDomain=${oauthDomain}`;

const CUSTOM_MOBILE_APP_UI_PATH = `${baseNativeAppEditorUrl}/:appId?clientId=${clientId}&authDomain=${oauthDomain}`;

const websiteEditorPathRegex = new RegExp(
  `${baseWebsiteEditorUrl}/website/([0-9]+)`
);
const appEditorPathRegex = new RegExp(`${baseNativeAppEditorUrl}/([0-9]+)`);

export default {
  CUSTOM_WEBSITE_UI_PATH,
  CUSTOM_MOBILE_APP_UI_PATH,
  websiteEditorPathRegex,
  appEditorPathRegex,
};
