import TekoID from 'teko-oauth2';
import { browserHistory } from 'helpers';
import { db } from 'db';

const isLoggedIn = () => {
  return TekoID.user?.isLoggedIn();
};

const login = () => {
  TekoID.user?.login();
};

const logout = () => {
  // Tracking - Reset userId when logged out
  track('resetUserId');
  TekoID.user.logout(window.location.origin);
};

const getAccessToken = () => {
  return TekoID.user?.getAccessToken();
};

const loadUser = () => {
  return TekoID.user?.loadUser();
};

const unloadUser = () => {
  return TekoID.user?.unloadUser();
};

const getUserInfo = () => {
  return TekoID.user?.getUserInfo();
};

const getFullUserInfo = async (isOnlineMode = true) => {
  if (!isOnlineMode) {
    const localUsers = await db.currentUser.toArray();
    return localUsers[0];
  }

  return await TekoID.user.getFullUserInfo();
};

const denyAccess = () => {
  browserHistory.push('/403');
};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
  loadUser,
  unloadUser,
};
