import { flagConstants } from 'constants/index';
import { useEffect, useState } from 'react';
import { IUserDetailsInfo } from 'teko-oauth2';
import FlagsupClient, { EvaluatedFlags } from 'flagsup-ts';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { apiFlagsupUrl } = getCommonConfig();

const useFeatureFlags = (currentUser?: IUserDetailsInfo) => {
  const [featureFlags, setFeatureFlags] = useState<EvaluatedFlags>({});
  const [isInitiating, setIsInitiating] = useState(true);

  const flagsupClient = new FlagsupClient({
    targetHostname: apiFlagsupUrl,
    defaultEntityId: currentUser?.sub,
    useCacheRefresh: false,
  });

  const getFlags = async () => {
    try {
      setIsInitiating(true);
      const flags = await flagsupClient.batchEvaluateFlags(
        {
          ...FLAG_KEYS,
          ...EXTENDED_FLAG_KEYS,
        },
        currentUser
      );
      setFeatureFlags(flags);
    } finally {
      setIsInitiating(false);
    }
  };

  useEffect(() => {
    if (currentUser?.sub) {
      getFlags();
    }
  }, [currentUser?.sub]);

  return { featureFlags, isInitiating, getFlags };
};

export default {
  useFeatureFlags,
};
