import { RiseOutlined } from '@ant-design/icons';
import { SEO_MANAGEMENT_PATHS } from 'constants/paths';
import { commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { IRoute } from 'interfaces';
import { lazy } from 'react';
import {
  actionConstants,
  appConstants,
  flagConstants,
  roleConstants,
  guideConstants,
} from '../../constants';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const { baseOscmUrl } = getCommonConfig();
const { permission } = commonHelpers;
const { STAFF_BFF } = appConstants;
const { REDIRECTION_MANAGEMENT } = roleConstants;
const { READ } = actionConstants;
const { FLAG_KEYS } = flagConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;

const seoManagementRoutes: IRoute[] = [
  {
    path: '/seo-management',
    name: t('SEOManagement.Root'),
    icon: RiseOutlined,
    children: ['/seo-management/redirection'],
  },
  {
    exact: true,
    path: '/seo-management/redirection',
    name: t('SEOManagement.Redirection'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseOscmUrl, SEO_MANAGEMENT_PATHS.Redirect),
    permissions: [permission(STAFF_BFF, REDIRECTION_MANAGEMENT, READ)],
    flagKey: FLAG_KEYS.MANAGE_REDIRECTION,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SEO.SEO,
      userGuideCode: USER_GUIDE_CODE.SEO.REDIRECT_MANAGEMENT,
    },
  },
];

export default seoManagementRoutes;
