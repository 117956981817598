import { Button, Modal } from 'antd';
import { NetworkContext } from 'contexts/network';
import { configHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import React, { useCallback, useContext, useEffect, useState } from 'react';

const { getClientConfig } = configHelpers;
const { swRegistered } = getClientConfig();
const LS_KEY_IS_ONLINE_MODE = 'isOnlineMode';

const NetworkChangeModal: React.FC = () => {
  const [showNetworkChangeModal, setShowNetworkChangeModal] = useState(false);
  const { isOnlineMode, setIsOnlineMode } = useContext(NetworkContext);
  const toggleNetwork = useCallback((networkCondition: boolean) => {
    localStorage.setItem(LS_KEY_IS_ONLINE_MODE, networkCondition.toString());
    setIsOnlineMode(networkCondition);
    setShowNetworkChangeModal(false);
  }, []);

  useEffect(() => {
    if (!swRegistered) return;
    localStorage.setItem(LS_KEY_IS_ONLINE_MODE, isOnlineMode.toString());

    const handleNetworkChange = () => {
      if (!swRegistered) return;
      const currentNetworkCondition =
        localStorage.getItem(LS_KEY_IS_ONLINE_MODE) === 'true';
      const isOnline = window.navigator.onLine;
      setShowNetworkChangeModal(currentNetworkCondition !== isOnline);
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []);

  return (
    <Modal
      visible={showNetworkChangeModal}
      title={
        window.navigator.onLine
          ? t('InternetAvailable')
          : t('InternetUnavailable')
      }
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={[
        <Button
          type="primary"
          key="submit"
          onClick={() => toggleNetwork(window.navigator.onLine)}
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      {window.navigator.onLine
        ? t('SwitchOnlineModeMsg')
        : t('SwitchOfflineModeMsg')}
    </Modal>
  );
};

export default NetworkChangeModal;
