import React, { useContext, useRef } from 'react';
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import { browserHistory, userHelpers, notificationHelpers } from 'helpers';
import SellerSelect from 'components/Seller/SellerSelect';

export const withSellerPermission = <P extends {}>(
  Component: React.FC<P>,
  sellerPermissionList: number[]
): React.FC<P> => {
  return props => {
    const { sellerInfo, authorizedSellers } = useContext(StoreContext);

    const sellerPermissionSet = new Set(sellerPermissionList);
    const isInCorrectSeller = sellerPermissionSet.has(sellerInfo.id);
    if (isInCorrectSeller) return <Component {...props} />;

    const sellerSelectList = authorizedSellers.filter(seller =>
      sellerPermissionSet.has(seller.id)
    );
    const sellerSelectRef = useRef({ value: sellerSelectList[0]?.id });
    const handleChangeSeller = (sellerId: number) => {
      sellerSelectRef.current.value = sellerId;
    };

    // If user has permission to manage this page
    if (!!sellerSelectList.length) {
      notificationHelpers.showConfirmModal({
        maskClosable: false,
        content: (
          <>
            {t('CurrentFeatureNotSupportedForSeller')}{' '}
            <b>{sellerInfo.displayName}</b> <br />
            {t('SwitchSellerToActivateThisFeature')}
            {':'}
            <SellerSelect
              sellerSelectList={sellerSelectList}
              handleChangeSeller={handleChangeSeller}
            />
          </>
        ),
        // If choose Confirm, switch to that seller
        onOk: () => userHelpers.changeSeller(sellerSelectRef.current.value),
        okText: t('Confirm'),

        // If choose Cancel, redirect user to home page
        onCancel: () => browserHistory.replace('/'),
        cancelText: t('Cancel'),
      });

      return null;
    }

    notificationHelpers.showConfirmModal({
      maskClosable: false,
      content: (
        <>
          {t('CurrentFeatureNotSupportedForSeller')}{' '}
          <b>{sellerInfo.displayName}</b>
        </>
      ),
      // If choose back, redirect user to the old page
      onOk: () => browserHistory.goBack(),
      okText: t('Back'),

      cancelButtonProps: {
        className: 'd-none',
      },
    });

    return null;
  };
};
