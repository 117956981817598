import { IClientConfig, ICommonConfig } from 'interfaces';

const getConfig = (configKey: 'commonConfig' | 'clientConfig') => {
  if (window[configKey]) {
    localStorage.setItem(configKey, JSON.stringify(window[configKey]));
    return window[configKey];
  } else {
    const config = localStorage.getItem(configKey);
    if (config) {
      return JSON.parse(config);
    }
  }
};

const getCommonConfig = () => getConfig('commonConfig') as ICommonConfig;
const getClientConfig = () => getConfig('clientConfig') as IClientConfig;

export default {
  getCommonConfig,
  getClientConfig,
};
