import { lazy } from 'react';
import { ScheduleOutlined, UserOutlined, OrderedListOutlined, DatabaseOutlined } from '@ant-design/icons';
import { getSrc } from 'helpers/url';
import { TICKET_PATHS } from 'constants/paths';
import { commonHelpers } from 'helpers';
import {
  actionConstants,
  appConstants,
  resourceConstants,
  guideConstants,
} from 'constants/index';
import { t } from 'helpers/i18n';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseCRMUrl, baseTicketManagementUrl } = getCommonConfig();

const { permission } = commonHelpers;
const { TICKET_BFF } = appConstants;
const { ISSUES, ERP } = resourceConstants;
const { READ } = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const ticketRoutes = [
  // Ticket
  {
    exact: true,
    path: '/tickets',
    name: t('Ticket.Root'),
    icon: ScheduleOutlined,
    children: ['/tickets/issueTypes', '/tickets/create', '/tickets/list']
  },
  {
    exact: true,
    path: '/tickets/list',
    name: t('Ticket.List'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseTicketManagementUrl, TICKET_PATHS.TicketList),
    permissions: [permission(TICKET_BFF, ISSUES, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ORDER_FULFILLMENT.ORDER,
      userGuideCode: USER_GUIDE_CODE.ORDER_FULFILLMENT.TICKET_LIST,
    },
  },
  {
    exact: true,
    path: '/tickets/create',
    name: t('Ticket.Create'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.TicketCreate),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
  {
    exact: true,
    path: '/tickets/:id/process',
    name: t('Ticket.Process'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseTicketManagementUrl, TICKET_PATHS.ProcessTicket),
    permissions: [permission(TICKET_BFF, ISSUES, READ)],
  },
  {
    exact: true,
    path: '/tickets/issueTypes',
    name: t('IssueTypes.List'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.TicketIssueType),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ],
  },

  // Lead
  {
    exact: true,
    path: '/lead',
    name: t('Lead.Root'),
    icon: UserOutlined,
    children: ['/file-import-lead', '/customers']
  },
  {
    exact: true,
    path: '/file-import-lead',
    name: t('Lead.Import'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ImportLead),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ],
  },
  {
    exact: true,
    path: '/customers',
    name: t('Customers'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.Customers),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ],
  },

  // Task Management
  {
    exact: true,
    path: '/task-management',
    name: t('SalePortal.ImplementManagement'),
    icon: OrderedListOutlined,
    children: ['/implement/list', '/implement/assign-task']
  },
  {
    exact: true,
    path: '/implement/list',
    name: t('SalePortal.ListImplement'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.Implement),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ],
  },

  // Report
  {
    exact: true,
    path: '/ticket-report',
    name: t('TicketReport.Root'),
    icon: ScheduleOutlined,
    children: [
      '/report/lead-approaching',
      '/report/status',
      '/report/time',
      '/report/channel',
      '/report/daily',
      '/report/summary'
    ]
  },
  {
    exact: true,
    path: '/report/lead-approaching',
    name: t('TicketReport.LeadApproaching'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportLeadApproaching),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ],
  },
  {
    exact: true,
    path: '/report/status',
    name: t('TicketReport.Status'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportStatus),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
  {
    exact: true,
    path: '/report/time',
    name: t('TicketReport.Time'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportTime),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
  {
    exact: true,
    path: '/report/channel',
    name: t('TicketReport.Channel'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportChannel),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
  {
    exact: true,
    path: '/report/daily',
    name: t('TicketReport.Daily'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportDaily),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
  {
    exact: true,
    path: '/report/summary',
    name: t('TicketReport.Summary'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.ReportSummary),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },

  // Timesheet
  {
    exact: true,
    path: '/timesheet/report',
    name: t('Timesheet.Root'),
    icon: DatabaseOutlined,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseCRMUrl, TICKET_PATHS.TimesheetReport),
    permissions: [
      permission(TICKET_BFF, ERP, READ)
    ]
  },
];

export default ticketRoutes;
