import { lazy } from 'react';
import { TagOutlined } from '@ant-design/icons';
import {
  appConstants,
  resourceConstants,
  actionConstants,
  guideConstants,
  flagConstants,
} from 'constants/index';
import { PROMOTION_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers, userHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  baseSellerCenterV1Url,
  baseSellerCenterV2Url,
  basePpmWebUrl,
  newComboMenuAllowSellers,
} = getCommonConfig();

const { PPM, STAFF_BFF } = appConstants;
const {
  PROMOTION,
  QR_PROMOTION,
  COUPON,
  BUDGET,
  GIFT_CODE,
  BUCKET,
  USER_SUPPORT_TOOL,
} = resourceConstants;
const {
  READ,
  READ_MULTI_SELLERS,
  CREATE_MULTI_SELLERS,
  TRACKING,
  CREATE,
} = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { permission } = commonHelpers;
const { FLAG_KEYS } = flagConstants;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const { getCurrentSeller } = userHelpers;
const currentSeller = getCurrentSeller();

const hasPermissionToShowCombo = newComboMenuAllowSellers?.includes(
  Number(currentSeller)
);
/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const promotionRoutes = [
  // Parent menu
  {
    path: '/promotions',
    name: t('Promotions.Root'),
    icon: TagOutlined,
    children: [
      '/promotions/campaigns',
      '/promotions/promotions',
      '/promotions/shipping-fee-promotions',
      '/promotions/combo-promotions',
      '/promotions/flash-sales',
      '/promotions/coupons',
      '/promotions/budgets',
      '/promotions/gift-codes',
      '/promotions/coupons/tracking',
      '/promotions/qr-promotions/create',
      '/promotions/buckets',
      '/promotions/check-coupon',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/promotions/campaigns',
    name: t('Promotions.Campaigns'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.Campaigns),
    permissions: [permission(PPM, PROMOTION, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.PROMOTION_CAMPAIGN,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/campaigns/create',
    name: t('Promotions.NewCampaign'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.NewCampaign),
    permissions: [permission(PPM, PROMOTION, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.PROMOTION_CAMPAIGN,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/campaigns/:id',
    name: t('Promotions.CampaignDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.CampaignDetail),
    permissions: [permission(PPM, PROMOTION, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.PROMOTION_CAMPAIGN,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/promotions',
    name: t('Promotions.Promotions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.Promotions),
    permissions: [permission(PPM, PROMOTION, READ)],
  },
  {
    exact: true,
    path: '/promotions/promotions/:id',
    name: t('Promotions.PromotionDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.PromotionDetail),
    permissions: [permission(PPM, PROMOTION, READ)],
  },
  {
    exact: true,
    path: '/promotions/shipping-fee-promotions',
    name: t('Promotions.ShippingFeePromotions'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.ShippingFeePromotions),
    permissions: [permission(PPM, PROMOTION, READ_MULTI_SELLERS)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.SHIPPING_FEE_PROMOTION,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/shipping-fee-promotions/create',
    name: t('Promotions.NewShippingFeePromotion'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      basePpmWebUrl,
      PROMOTION_PATHS.ShippingFeePromotionCreate
    ),
    permissions: [permission(PPM, PROMOTION, CREATE_MULTI_SELLERS)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.SHIPPING_FEE_PROMOTION,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/shipping-fee-promotions/:id',
    name: t('Promotions.ShippingFeePromotionDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      basePpmWebUrl,
      PROMOTION_PATHS.ShippingFeePromotionDetail
    ),
    permissions: [permission(PPM, PROMOTION, READ_MULTI_SELLERS)],
  },
  ...(hasPermissionToShowCombo
    ? [
        {
          exact: true,
          path: '/promotions/combo-promotions',
          name: t('Promotions.ComboPromotions'),
          component: IFrameContainer,
          iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.ComboPromotions),
          permissions: [permission(PPM, PROMOTION, READ)],
          guide: {
            serviceCode: SERVICE_CODE.OMNI,
            pageCode: PAGE_CODE.PPM.COMBO_PROMOTION,
            userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
          },
          flagKey: FLAG_KEYS.EPIC_OMNI_1286,
        },
      ]
    : []),
  {
    exact: true,
    path: '/promotions/qr-promotions/create',
    name: t('Promotions.NewQrPromotion'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PROMOTION_PATHS.QrPromotionCreate),
    permissions: [permission(STAFF_BFF, QR_PROMOTION, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.QR_PROMOTION,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/flash-sales',
    name: t('Promotions.FlashSales'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.FlashSales),
    permissions: [permission(PPM, PROMOTION, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.FLASHSALE,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/flash-sales/create',
    name: t('Promotions.NewFlashSales'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.NewFlashSales),
    permissions: [permission(PPM, PROMOTION, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.FLASHSALE,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/flash-sales/:id',
    name: t('Promotions.FlashSaleDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PROMOTION_PATHS.FlashSaleDetail),
    permissions: [permission(PPM, PROMOTION, READ)],
  },
  {
    exact: true,
    path: '/promotions/coupons',
    name: t('Promotions.Coupons'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.Coupons),
    permissions: [permission(PPM, COUPON, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.COUPON_GROUP,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/coupons/create',
    name: t('Promotions.NewCoupon'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.NewCoupons),
    permissions: [permission(PPM, COUPON, READ)],
  },
  {
    exact: true,
    path: '/promotions/coupons/:id',
    name: t('Promotions.CouponDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.CouponDetail),
    permissions: [permission(PPM, COUPON, READ)],
  },
  {
    exact: true,
    path: '/promotions/budgets',
    name: t('Promotions.Budgets'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.Budgets),
    permissions: [permission(PPM, BUDGET, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.PROMOTION_BUDGET,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/budgets/:id',
    name: t('Promotions.BudgetDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.BudgetDetail),
    permissions: [permission(PPM, BUDGET, READ)],
  },
  {
    exact: true,
    path: '/promotions/gift-codes',
    name: t('Promotions.GiftCodes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.GiftCodes),
    permissions: [permission(PPM, GIFT_CODE, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.VOUCHER,
      userGuideCode: USER_GUIDE_CODE.PPM.CREATE,
    },
  },
  {
    exact: true,
    path: '/promotions/gift-codes/:id',
    name: t('Promotions.GiftCodeDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.GiftCodeDetail),
    permissions: [permission(PPM, GIFT_CODE, READ)],
  },
  {
    exact: true,
    path: '/promotions/gift-codes/create',
    name: t('Promotions.NewGiftCode'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.NewGiftCode),
    permissions: [permission(PPM, GIFT_CODE, READ)],
  },
  {
    exact: true,
    path: '/promotions/coupons/tracking',
    name: t('Promotions.CouponTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.CouponTracking),
    permissions: [permission(PPM, COUPON, TRACKING)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PPM.COUPON,
      userGuideCode: USER_GUIDE_CODE.PPM.TRACKING,
    },
  },
  {
    exact: true,
    path: '/promotions/buckets',
    name: t('Promotions.Buckets'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.Buckets),
    permissions: [permission(STAFF_BFF, BUCKET, READ)],
  },
  {
    exact: true,
    path: '/promotions/check-coupon',
    name: t('Promotions.CheckCoupon'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PROMOTION_PATHS.CheckCoupon),
    permissions: [permission(PPM, USER_SUPPORT_TOOL, READ)],
  },
];

export default promotionRoutes;
