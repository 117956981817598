import React, { useEffect } from 'react';
import { Result, Statistic, Space } from 'antd';
import { t } from 'helpers/i18n';
import { BackToHomeButton, LogoutButton } from 'components/shared/Button';
import { useHistory, useLocation } from 'react-router-dom';

const { Countdown } = Statistic;

const PageUpgrading: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const upgradeStartTime = searchParams.get('upgradeStartTime');
  const upgradeEndTime = searchParams.get('upgradeEndTime');
  const serviceName = searchParams.get('serviceName');

  // Check if the current time is not in the upgrade time range, then navigate to the home page
  useEffect(() => {
    if (upgradeStartTime && upgradeEndTime) {
      const currentTime = new Date().getTime();
      if (
        currentTime < parseInt(upgradeStartTime) ||
        currentTime > parseInt(upgradeEndTime)
      ) {
        history.push('/');
      }
    }
  }, [upgradeStartTime, upgradeEndTime]);

  const onCountdownFinish = () => {
    history.push('/');
  };

  return (
    <Result
      style={{ marginTop: '10%' }}
      status="warning"
      title={t('Upgrading')}
      subTitle={t('UpgradingSubtitle', { service: t(serviceName || '') })}
      extra={
        <div className="d-flex flex-column">
          <div>
            <Countdown
              title={t('TimeRemainBeforeDone')}
              className="d-inline-block"
              value={Number(upgradeEndTime)}
              onFinish={onCountdownFinish}
            />
          </div>

          <Space className="d-flex justify-content-center mt-base">
            <BackToHomeButton />
            <LogoutButton />
          </Space>
        </div>
      }
    />
  );
};

export default PageUpgrading;
