import { Statistic, Typography, Tooltip, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { t } from 'helpers/i18n';
import { InfoCircleOutlined } from '@ant-design/icons';

import './UpgradeCountdown.scss';
import { useHistory } from 'react-router-dom';

const { Countdown } = Statistic;
const { Text } = Typography;

interface UpgradeCountdownProps {
  upgradeStartTime?: Date;
  upgradeEndTime?: Date;
}
const UpgradeCountdown: React.FC<UpgradeCountdownProps> = ({
  upgradeStartTime,
  upgradeEndTime,
}) => {
  const history = useHistory();
  // check if upgradeStartTime is in the future and no longer than 2 hours
  const [isDisplayUpgradeCountdown, setIsDisplayUpgradeCountdown] = useState(
    false
  );
  const [isUpgrading, setIsUpgrading] = useState(false);

  useEffect(() => {
    const now = new Date();
    // Check if upgradeStartTime is in the future and no longer than 2 hours
    if (
      upgradeStartTime &&
      upgradeStartTime > now &&
      upgradeStartTime.getTime() - now.getTime() < 2 * 60 * 60 * 1000
    ) {
      setIsDisplayUpgradeCountdown(true);
    }

    // Check if now is between upgradeStartTime and upgradeEndTime
    else if (
      upgradeStartTime &&
      upgradeEndTime &&
      upgradeStartTime < now &&
      upgradeEndTime > now
    ) {
      setIsUpgrading(true);
    }
  }, [upgradeStartTime, upgradeEndTime]);

  const onCountdownFinish = () => {
    setIsDisplayUpgradeCountdown(false);
    setIsUpgrading(true);

    Modal.confirm({
      content: t('UpgradingNotifyMsg'),
      okText: t('NoAndGoHomePage'),
      cancelText: t('Yes'),
      maskClosable: false,
      onOk: () => {
        history.push('/');
      },
    });
  };

  // Return null if upgradeStartTime or upgradeEndTime is not provided
  if (!upgradeStartTime || !upgradeEndTime) return null;

  return (
    <div className="app-upgrade-countdown d-flex align-items-center mr-base">
      {// Render countdown if upgradeStartTime is in the future and no longer than 2 hours
      isDisplayUpgradeCountdown && (
        <Countdown
          title={t('TimeRemainBeforeUpgrading')}
          className="d-inline-block"
          value={upgradeStartTime.getTime()}
          onFinish={onCountdownFinish}
        />
      )}

      {// Render upgrading if now is between upgradeStartTime and upgradeEndTime
      isUpgrading && (
        <>
          <Text className="text-medium text-warning mr-half">
            {t('Upgrading')}
          </Text>
          <Tooltip title={t('UpgradingTooltip')}>
            <InfoCircleOutlined className="text-link" />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default UpgradeCountdown;
