import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Menu, Dropdown, Input, Empty, Modal } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { trackingHelpers, userHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import 'components/Platform/PlatformGlobalSwitch/PlatformGlobalSwitch.scss';
import SiteItem from '../SiteItem/SiteItem';
import './SiteGlobalSwitch.scss';
import { trackingConstants } from 'constants/index';

const { getCurrentSite, getSearchUserSites } = userHelpers;
const { computeTrackingScreenName } = trackingHelpers;

const {
  TRACKING_CONTENT_REGION_NAME,
  TRACKING_CONTENT_NAME,
} = trackingConstants;

const SiteGlobalSwitch: React.FC<{ pathName: string }> = ({ pathName }) => {
  const { userSites, siteInfo } = useContext(StoreContext);
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);

  const results = useMemo(() => {
    return getSearchUserSites(userSites, keyword);
  }, [keyword, userSites]);

  useEffect(() => {
    if (!siteInfo) return;

    const validateCurrentSite = () => {
      if (
        document.visibilityState === 'visible' &&
        parseInt(getCurrentSite()) !== siteInfo.id
      ) {
        Modal.info({
          content: t('Warehousing.DetectInvalidSiteMsg'),
          onOk: () => window.location.reload(),
        });
      }
    };
    document.addEventListener('visibilitychange', validateCurrentSite);

    return () => {
      document.removeEventListener('visibilitychange', validateCurrentSite);
    };
  }, [siteInfo]);

  if (!siteInfo) return null;

  const computeTrackingPathName = computeTrackingScreenName(pathName);

  const siteMenu = (
    <Menu
      className="platform-menu"
      defaultSelectedKeys={[siteInfo.id.toString()]}
    >
      <Menu.Item className="platform-search-item">
        <Input
          data-testid="platform-search-input"
          autoFocus
          allowClear
          placeholder={t('Warehousing.SearchSitePlaceholder')}
          prefix={<SearchOutlined />}
          onChange={e => setKeyword(e.target.value.trim())}
        />
      </Menu.Item>
      {!!results.length ? (
        results.map(site => (
          <Menu.Item
            key={site.id}
            className="py-0"
            data-track-content
            data-content-region-name={
              TRACKING_CONTENT_REGION_NAME.SiteGlobalSwitch
            }
            data-content-name={TRACKING_CONTENT_NAME.SelectSite + site.id}
            data-content-payload={computeTrackingPathName}
          >
            <SiteItem site={site} />
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('NoResult')}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  const labelInfo = () => {
    return (
      <span
        className="app-user site-label"
        {...(userSites.length > 1 && {
          'data-track-content': true,
          'data-content-region-name':
            TRACKING_CONTENT_REGION_NAME.SiteGlobalSwitch,
          'data-content-name': TRACKING_CONTENT_NAME.OpenSiteSwitchDropdown,
          'data-content-payload':
            computeTrackingPathName + `_currentSite=${siteInfo.id}`,
        })}
      >
        <span className="label">
          {t('Warehousing.Site')}: {siteInfo.name}
        </span>
        {userSites.length !== 1 ? <DownOutlined /> : null}
      </span>
    );
  };

  return (
    <span id="site-global-switch">
      {userSites.length === 1 ? (
        labelInfo()
      ) : (
        <Dropdown
          overlay={siteMenu}
          trigger={['click']}
          onVisibleChange={flag => setVisible(flag)}
          visible={visible}
          getPopupContainer={() =>
            document.getElementById('site-global-switch') as HTMLElement
          }
        >
          {labelInfo()}
        </Dropdown>
      )}
    </span>
  );
};

export default SiteGlobalSwitch;
