import Dexie, { Table } from 'dexie';
import { IUserDetailsInfo } from 'teko-oauth2';

export class MySubClassedDexie extends Dexie {
  currentUser!: Table<IUserDetailsInfo & { accessToken: string }>;

  constructor() {
    super('ERPAdminLocalDB');
    this.version(1).stores({
      currentUser: 'sub', // Primary key and indexed props
    });
  }
}
export const db = new MySubClassedDexie();
