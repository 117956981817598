const isItemAccessible = (currentPermissions: string[], reportURL: string) => {
  const assetId = reportURL.split('/').pop();

  if (!currentPermissions || !assetId) return false;

  return currentPermissions.some((permission: string) => {
    const assetIdFromPermission = permission.split(':').pop();
    return (
      permission.startsWith('bi-report') && assetIdFromPermission === assetId
    );
  });
};

export default { isItemAccessible };
