import { AxiosRequestConfig } from 'axios';
import { getResult } from 'helpers/request';
import {
  AxiosRequestCustomConfig,
  IGetPlatformConfigParams,
  IGetUserSitesResponse,
  IPlatform,
  Website,
} from 'interfaces';
import { requestServices } from 'services';

const { staffClient } = requestServices;

const getWebsitesByPlatformId = (platformId: number): Promise<Website[]> => {
  return staffClient
    .get(`/api/v1/platforms/${platformId}/websites`)
    .then(getResult);
};

const getUserSites = (): Promise<IGetUserSitesResponse> => {
  return staffClient.get('/api/v1/sites/user').then(res => res?.data?.data);
};

const getPlatformConfig = (
  params: IGetPlatformConfigParams
): Promise<IPlatform> => {
  const requestConfig: AxiosRequestConfig & AxiosRequestCustomConfig = {
    params,
    isBypassNormalErrorRequests: true,
  };

  return staffClient
    .get('/api/v1/platforms/config', requestConfig)
    .then(res => res?.data?.data);
};

export default {
  getWebsitesByPlatformId,
  getUserSites,
  getPlatformConfig,
};
