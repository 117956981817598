import React from 'react';
import { Breadcrumb } from 'antd';
import { commonHooks } from 'hooks';
import { generatePath, Link, matchPath } from 'react-router-dom';
import { Guide } from 'teko-help-center';

const { useCurrentRoute, usePathname } = commonHooks;

const AppBreadcrumb: React.FC = () => {
  const pathname = usePathname().pathname;
  const { matchedRoutes, currentRoute } = useCurrentRoute();

  if (!currentRoute || currentRoute.hideBreadcrumb) return null;

  const match = matchPath(pathname, {
    path: currentRoute.path,
    exact: currentRoute.exact,
  });

  // linkableRoutes could be dynamic routes. Thus, matched params need to be mapped into them.
  const linkableRoutes = matchedRoutes
    .filter(route => !!route.component)
    .map(route => {
      if (match) {
        return { ...route, path: generatePath(route.path, match.params) };
      }
      return route;
    });

  return (
    <div className="d-flex align-items-center">
      <Breadcrumb className="app-breadcrumb">
        {linkableRoutes.map(item => (
          <Breadcrumb.Item key={item.path}>
            {currentRoute?.path !== item.path ? (
              <Link to={item.path}>{item.name}</Link>
            ) : (
              item.name
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {currentRoute?.guide && (
        <div className="app-guide">
          <Guide
            type="popup"
            popupProps={{ width: 1000 }}
            {...currentRoute.guide}
          />
        </div>
      )}
    </div>
  );
};

export default AppBreadcrumb;
