import { t } from '../helpers/i18n';

const IAM_SCOPES = [
  'openid',
  'profile',
  'read:permissions',
  // You can add more scopes (granted services) here
  'sellers',
  'om',
  'catalog',
  'ppm',
  'page_builder',
  'wms',
  'as',
  'fms',
  'seller-gateway',
  'ps-v2',
  'us',
  'tenant:management',
  'notification-management-apis',
  'apl',
  'staff-bff',
  'user-segment-bff',
  'rebate-staff-bff',
  'merchant-bff',
  'ons-bff',
  'uns-bff-api',
  'ticket-bff',
  'dca',
  'payment-staff-bff',
  'lo',
  'marketing-automation-bff-api',
  'rebate-admin',
  'shopping-cart:order',
  'shopping-cart:write',
  'teko:marketing-automation-bff-api',
  'terra-staff-bff',
  'terra-staff-bff:loyalty',
  'user-segment-v2',
  'loyalty-staff-bff',
  'loyalty-management-bff',
];

/**
 * Config the app's Ribbon here.
 * If does not matches any config, no display
 * */
const RIBBON_CONFIGS: Record<string, { name: string; className: string }> = {
  'TEST-1': {
    className: 'test-1',
    name: 'TEST-1',
  },
  DEV: {
    className: 'dev',
    name: 'DEV',
  },
  STAG: {
    className: 'stag',
    name: 'STAG',
  },
};

const DEFAULT_SIZE_CHANGER = {
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50', '100'],
  locale: {
    items_per_page: t('Rebate.RecordPerPage'),
  },
};

const DEFAULT_PAGINATION_P1 = {
  page: 1,
  size: 10,
};

const SELLER_IDS = {
  PV: 1,
  VNSHOP: 2,
  MASAN: 18,
  SMS: 143,
};

const SELLER_PERMISSION_LIST = {
  'stock-requests': [SELLER_IDS.PV, SELLER_IDS.VNSHOP, SELLER_IDS.SMS],
  'as-invoice': [SELLER_IDS.PV],
  'as-report': [SELLER_IDS.PV, SELLER_IDS.VNSHOP],
  'loyalty-config-pv': [SELLER_IDS.PV],
};

// This domain list will use the email "it-helpdesk@teko.vn" for permission granting support
const VNLIFE_DOMAINS = [
  // DEV
  'erp-pv.dev.teko.vn',
  'admin-umbala.dev.teko.vn',
  // STAG
  'erp-pv.stag.teko.vn',
  'vnshop-staff.stag.teko.vn',
  'karavan-staff.stag.teko.vn',
  'admin-umbala.stag.teko.vn',
  // PROD
  'erp.phongvu.vn',
  'sellercenter.vnshop.vn',
  'admin.karavan.vn',
  'seller.umbala.vn',
];

const DEFAULT_SUPPORT_EMAIL = 'support@teko.vn';
const VNLIFE_SUPPORT_EMAIL = 'it-helpdesk@teko.vn';

// This domain list will include non-admin and admin-only menus
const ADMIN_DOMAINS = [
  'erpadmin.dev.teko.vn',
  'erpadmin.stag.teko.vn',
  'erpadmin.teko.vn',
  'localhost',
];

const START_EDIT_MESSAGE = 'start edit';
const END_EDIT_MESSAGE = 'stop edit';

const LOGOUT_MESSAGE = 'logout';

const HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES = [
  'om_export_orders_by_platform',
  'om_export_orders_by_seller',
  'om_export_revenue_report',
];

const DEFAULT_NUMBER_DISPLAY_FORMAT = {
  thousandSeparator: '.',
  decimalSeparator: ',',
};

const API_RESPONSE_TIME_BUCKETS = [
  {
    value: 200,
    label: '0 - 200',
  },
  {
    value: 500,
    label: '200 - 500',
  },
  {
    value: 800,
    label: '500 - 800',
  },
  {
    value: 1000,
    label: '800 - 1000',
  },
  {
    value: 1500,
    label: '1000 - 1500',
  },
  {
    value: 2000,
    label: '1500 - 2000',
  },
  {
    value: 3000,
    label: '2000 - 3000',
  },
  {
    value: 4000,
    label: '3000 - 4000',
  },
  {
    value: 5000,
    label: '4000 - 5000',
  },
  {
    value: 8000,
    label: '5000 - 8000',
  },
  {
    value: 10000,
    label: '8000 - 10000',
  },
  {
    value: 11000,
    label: '> 10000',
  },
];

const BROWSER_PERMISSIONS = {
  NOTIFICATIONS: 'Notifications',
};

const DEFAULT_PAGINATION = {
  page: 1,
  pageSize: 100,
};

const URL_SEARCH_PARAMS_REGEX = /\?.*$/g;

export default {
  IAM_SCOPES,
  ALL: 'all',
  CODE_SUCCESS: 'SUCCESS',
  CODE_INVALID: 'INVALID',
  CODE_ERROR: 'ERROR',
  TABLET_WIDTH: 768,
  RIBBON_CONFIGS,
  DEBOUNCE_TIME: 500,
  DEFAULT_SIZE_CHANGER,
  DEFAULT_PAGINATION_P1,
  SELLER_PERMISSION_LIST,
  SELLER_IDS,
  ADMIN_DOMAINS,
  VNLIFE_DOMAINS,
  DEFAULT_SUPPORT_EMAIL,
  VNLIFE_SUPPORT_EMAIL,
  START_EDIT_MESSAGE,
  END_EDIT_MESSAGE,
  LOGOUT_MESSAGE,
  HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES,
  DEFAULT_NUMBER_DISPLAY_FORMAT,
  API_RESPONSE_TIME_BUCKETS,
  UNDEFINED_URL: 'undefined_url',
  BROWSER_PERMISSIONS,
  IFRAME_CONTAINER_ID: 'erp-admin-child-app',
  DEFAULT_PAGINATION,
  URL_SEARCH_PARAMS_REGEX,
};
