import { lazy } from 'react';
import { SolutionOutlined, TeamOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
  roleConstants,
  guideConstants,
} from 'constants/index';
import {
  CUSTOMER_MANAGEMENT_PATHS,
  STAFF_MANAGEMENT_PATHS,
} from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';

// ERP2020-562 new routes
import orderRoutes from './order';
import productRoutes from './product';
import promotionRoutes from './promotion';
import pageBuilderRoutes from './pageBuilder';
import purchasingRoutes from './purchasing';
import cashierRoutes from './cashier';
import invoiceRoutes from './invoice';
import accountingRoutes from './accounting';
import warehousingRoutes from './warehousing';
import logisticRoutes from './logistic';
import dposRoutes from './dpos';
import userNotificationRoutes from './userNotification';
import settingRoutes from './setting';
import reportRoutes from './report';
import entityRoutes from './entity';
import loyaltyServiceRoutes from './loyaltyService';
import ticketRoutes from './ticket';
import campaignManagement from './campaignManagement';
import staffManagementRoutes from './staffManagement';
import seoManagementRoutes from './seoManagement';
import reportV2Routes from './report-v2';
import PageUpgrading from 'containers/shared/PageUpgrading';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV2Url } = getCommonConfig();

const { SELLERS, STAFF_BFF } = appConstants;
const { LEGACY_STAFF_MANAGEMENT } = roleConstants;
const { DEALER } = resourceConstants;
const { READ_UI } = actionConstants;
const { permission } = commonHelpers;
const { SERVICE_CODE, USER_GUIDE_CODE } = guideConstants;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

export default [
  ...orderRoutes,
  ...ticketRoutes,
  ...productRoutes,
  ...promotionRoutes,
  ...campaignManagement,
  ...loyaltyServiceRoutes,
  ...pageBuilderRoutes,
  ...purchasingRoutes,
  ...cashierRoutes,
  ...invoiceRoutes,
  ...accountingRoutes,
  ...warehousingRoutes,
  ...logisticRoutes,
  {
    exact: true,
    path: '/customer-management',
    name: t('CustomerManagement'),
    icon: SolutionOutlined,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, CUSTOMER_MANAGEMENT_PATHS.Dealers),
    permissions: [permission(SELLERS, DEALER, READ_UI)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      userGuideCode: USER_GUIDE_CODE.CUSTOMER_MANAGEMENT,
    },
  },
  ...dposRoutes,
  ...userNotificationRoutes,
  {
    exact: true,
    path: '/staff-management',
    name: t('StaffManagement'),
    icon: TeamOutlined,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, STAFF_MANAGEMENT_PATHS.Users),
    permissions: [permission(STAFF_BFF, LEGACY_STAFF_MANAGEMENT)],
    usePermissionPrefix: true,
  },
  ...settingRoutes,
  ...reportRoutes,
  ...reportV2Routes,
  ...entityRoutes,
  ...staffManagementRoutes,
  ...seoManagementRoutes,
  {
    exact: true,
    path: '/upgrading',
    name: t('Upgrading'),
    component: PageUpgrading,
  },
];
