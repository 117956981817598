import { lazy } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { appConstants, roleConstants, commonConstants } from 'constants/index';
import { DPOS_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseDposUrl, baseSposUrl } = getCommonConfig();

const { STAFF_BFF } = appConstants;
const { SHOPPING_CART_STAFF, SHOPPING_CART_MARKETPLACE_STAFF } = roleConstants;
const { BROWSER_PERMISSIONS } = commonConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const dposRoutes = [
  // Parent menu
  {
    path: '/dpos',
    name: t('DPOS.Root'),
    icon: ShoppingCartOutlined,
    children: [
      '/dpos/create-order',
      '/dpos/create-marketplace-order',
      '/dpos/end-shift',
      '/dpos/end-shift-history',
      '/dpos/orders',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/dpos/create-order',
    name: t('DPOS.CreateOrder'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
    supportOfflineMode: true,
    browserPermissions: [BROWSER_PERMISSIONS.NOTIFICATIONS],
  },
  {
    exact: true,
    path: '/dpos/create-marketplace-order',
    name: t('DPOS.CreateMarketplaceOrder'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_MARKETPLACE_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
    browserPermissions: [BROWSER_PERMISSIONS.NOTIFICATIONS],
  },
  {
    exact: true,
    path: '/dpos/end-shift',
    name: t('DPOS.EndShift'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl, DPOS_PATHS.EndShift),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
  },
  {
    exact: true,
    path: '/dpos/end-shift-history',
    name: t('DPOS.EndShiftHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSposUrl, DPOS_PATHS.EndShiftHistory),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
    isModalItem: true,
  },
  {
    exact: true,
    path: '/dpos/orders',
    name: t('DPOS.Orders'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl, DPOS_PATHS.Orders),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
    browserPermissions: [BROWSER_PERMISSIONS.NOTIFICATIONS],
  },
  {
    exact: true,
    path: '/dpos/clone-order',
    name: t('DPOS.CloneOrder'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseDposUrl, DPOS_PATHS.CloneOrder),
    permissions: [permission(STAFF_BFF, SHOPPING_CART_STAFF)],
    usePermissionPrefix: true,
    hideBreadcrumb: true,
    hideTitle: true,
  },
];

export default dposRoutes;
