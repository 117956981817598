import { createContext } from 'react';

interface NetworkContextType {
  isOnlineMode: boolean;
  setIsOnlineMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NetworkContext = createContext<NetworkContextType>({
  isOnlineMode: true,
  setIsOnlineMode: () => {},
});
