import { AppstoreOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
  roleConstants,
  guideConstants,
  flagConstants,
} from 'constants/index';
import { PRODUCT_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { lazy } from 'react';
import { IRoute } from 'interfaces';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  baseSupplyChainUrl,
  baseErpUrl,
  baseSellerCenterV1Url,
  baseSellerCenterV2Url,
  baseWmsUrl,
  basePpmWebUrl,
} = getCommonConfig();

const { CATALOG, PPM, SELLER_GATEWAY, STAFF_BFF, WMS } = appConstants;
const {
  PRODUCT,
  IMPORT,
  PRICE,
  CATEGORY,
  TERMINAL_PRODUCT,
  PENDING_SALES_ORDERS,
  PURCHASE_STATUS,
  BRAND,
  ATTRIBUTE_SET,
  ATTRIBUTE,
  AUDIT_LOG,
} = resourceConstants;
const {
  TAKASHIMAYA,
  POSTMART_MANAGER,
  POSTMART_SALESMAN,
  SOIBIEN,
  SOIBIEN_STORE_STAFF,
  SOIBIEN_WAREHOUSE_STAFF,
  SELLER_MANUAL_INVENTORY,
  SELLER_MANUAL_INVENTORY_READONLY,
} = roleConstants;
const {
  READ,
  CREATE,
  CREATE_UPSERT,
  READ_SKU_REQUEST,
  READ_CLEARANCE_REQUEST,
} = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;

const { FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));
const IFrameAuditLog = lazy(() => import('containers/shared/IFrameAuditLog'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const productRoutes: IRoute[] = [
  // Parent menu
  {
    path: '/products',
    name: t('Products.Root'),
    icon: AppstoreOutlined,
    children: [
      '/products/products',
      '/products/create',
      '/products/product-requests',
      '/products/import',
      '/products/prices',
      '/products/inventory-tracking',
      '/products/seller-categories',
      '/products/brands',
      '/products/attribute-sets',
      '/products/attributes',
      getSrc(baseWmsUrl, PRODUCT_PATHS.ProductVariants),
      '/products/terminal-products',
      '/products/product-matching',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/products/products',
    name: t('Products.Products'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.Products),
    permissions: [permission(CATALOG, PRODUCT, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.PRODUCT_SEARCH,
    },
  },
  {
    exact: true,
    path: '/products/create',
    name: t('Products.NewProduct'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.NewProduct),
    permissions: [permission(CATALOG, PRODUCT, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.CREATE_PRODUCT,
    },
  },
  {
    exact: true,
    path: '/products/product-requests',
    name: t('Products.ProductRequests'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.ProductRequests),
    permissions: [
      permission(STAFF_BFF, PENDING_SALES_ORDERS, READ_SKU_REQUEST),
    ],
  },
  {
    exact: true,
    path: '/products/products/:id/price',
    name: t('Products.PriceAndTerminalInfo'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.ProductPriceDetail),
    permissions: [permission(STAFF_BFF, PRICE, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.NORMAL_PRICE,
    },
  },
  {
    exact: true,
    path: '/products/similar-product',
    name: t('Products.SimilarProducts'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.SimilarProducts),
    permissions: [permission(CATALOG, PRODUCT, CREATE)],
    hideTitle: true,
  },
  {
    exact: true,
    path: '/products/import',
    name: t('Products.ImportProducts'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.ImportProducts),
    permissions: [
      permission(CATALOG, IMPORT, CREATE),
      permission(STAFF_BFF, PRICE, CREATE_UPSERT),
    ],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.IMPORT_PRODUCT,
    },
  },
  {
    exact: true,
    path: '/products/prices',
    name: t('Products.Prices'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, PRODUCT_PATHS.Prices),
    permissions: [permission(PPM, PRICE, READ)],
  },
  {
    exact: true,
    path: '/products/prices/:sku',
    name: t('Products.PriceDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PRODUCT_PATHS.PriceDetail),
    permissions: [permission(PPM, PRICE, READ)],
  },
  {
    exact: true,
    path: '/products/inventory-tracking',
    name: t('Products.InventoryTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, PRODUCT_PATHS.InventoryTracking),
    permissions: [
      permission(STAFF_BFF, SELLER_MANUAL_INVENTORY),
      permission(STAFF_BFF, SELLER_MANUAL_INVENTORY_READONLY),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/products/inventory-tracking/upload-history',
    name: t('Products.UploadHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, PRODUCT_PATHS.InventoryTrackingUploadHistory),
    permissions: [permission(STAFF_BFF, SELLER_MANUAL_INVENTORY)],
  },
  {
    exact: true,
    path: '/products/seller-categories',
    name: t('Products.SellerCategories'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.SellerCategories),
    permissions: [permission(SELLER_GATEWAY, CATEGORY, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.MANAGE_CATEGORY,
    },
  },
  {
    exact: true,
    path: '/products/brands',
    name: t('Products.Brands'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.Brands),
    permissions: [permission(STAFF_BFF, BRAND, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.BRAND,
      userGuideCode: USER_GUIDE_CODE.CATALOG.CREATE,
    },
  },
  {
    exact: true,
    path: '/products/attribute-sets',
    name: t('Products.AttributeSets'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.AttributeSets),
    permissions: [permission(STAFF_BFF, ATTRIBUTE_SET, READ)],
    flagKey: FLAG_KEYS.ATTRIBUTE_SET_MANAGEMENT,
  },
  {
    exact: true,
    path: '/products/attributes',
    name: t('Products.Attributes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.Attributes),
    permissions: [permission(STAFF_BFF, ATTRIBUTE, READ)],
    flagKey: FLAG_KEYS.ATTRIBUTE_SET_MANAGEMENT,
  },
  {
    exact: true,
    path: getSrc(baseWmsUrl, PRODUCT_PATHS.ProductVariants),
    name: t('Products.ProductVariants'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, TAKASHIMAYA),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_STORE_STAFF),
      permission(WMS, SOIBIEN_WAREHOUSE_STAFF),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/products/terminal-products',
    name: t('Products.TerminalProducts'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.TerminalProducts),
    permissions: [permission(CATALOG, TERMINAL_PRODUCT, READ)],
  },
  {
    exact: true,
    path: '/products/products/:id/purchase-status',
    name: t('Products.PurchaseStatus'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PRODUCT_PATHS.PurchaseStatus),
    permissions: [permission(STAFF_BFF, PURCHASE_STATUS, READ)],
  },
  {
    exact: true,
    path: '/products/products/:id',
    name: t('Products.ProductDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PRODUCT_PATHS.ProductDetail),
    permissions: [permission(CATALOG, PRODUCT, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.CATALOG.PRODUCT,
      userGuideCode: USER_GUIDE_CODE.CATALOG.VIEW_EDIT_PRODUCT,
    },
  },
  {
    exact: true,
    path: '/products/action-history/:serviceCode/:actionObject/:actionOn',
    name: t('ActionHistory'),
    component: IFrameAuditLog,
    permissions: [permission(STAFF_BFF, AUDIT_LOG, READ_CLEARANCE_REQUEST)],
  },
];

export default productRoutes;
