import { t } from './i18n';

/**
 * Returns true if the input string is a valid URL
 * @param str String
 * @returns Boolean
 */
export const isValidUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 * Concat domain and path, return empty string if both domain and path are empty
 * @param domain String
 * @param path String
 * @returns String
 */
export const getSrc = (domain?: string, path?: string) => {
  return [domain, path].filter(Boolean).join('');
};

const getExportTitleFromURL = () => {
  const currentUrl = window.location.href;
  const exportType = currentUrl.split('export-history/')[1];
  const exportTitle =
    exportType &&
    t(`ExportHistory.${exportType}`) !== `ExportHistory.${exportType}`
      ? t(`ExportHistory.${exportType}`)
      : undefined;
  return exportTitle;
};

export default {
  isValidUrl,
  getSrc,
  getExportTitleFromURL,
};
