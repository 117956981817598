import { lazy } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { IRoute } from 'interfaces';
import adminRoutes from './admin';
import nonAdminRoutes from './nonAdmin';
import { commonConstants } from 'constants/index';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseErpUrl } = getCommonConfig();
const { ADMIN_DOMAINS } = commonConstants;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const currentDomainIsAdmin = ADMIN_DOMAINS.includes(window.location.hostname);
/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const routes: IRoute[] = [
  {
    exact: true,
    path: '/',
    name: t('Home'),
    title: t('TodoList'),
    icon: HomeOutlined,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl),
    supportOfflineMode: true,
  },
  // Include NonAdmin routes and Admin routes if current base URL is Admin URL
  ...(currentDomainIsAdmin
    ? [...nonAdminRoutes, ...adminRoutes]
    : nonAdminRoutes),
];

const routeMapping = (() => {
  const mapping: {
    [host: string]: {
      [iFramePathname: string]: string;
    };
  } = {};

  routes.forEach(({ path, iFrameSrc }) => {
    if (!iFrameSrc) return;
    try {
      // See more about URL: https://javascript.info/url
      const { host, pathname } = new URL(iFrameSrc, window.origin);
      mapping[host] = mapping[host] || {};
      mapping[host][pathname] = path;
    } catch (error) {
      // Just ignore invalid URL
      console.error(`Invalid route: ${iFrameSrc}`);
    }
  });

  return mapping;
})();

export { routeMapping };
export default routes;
