import { lazy } from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  guideConstants,
  resourceConstants,
} from 'constants/index';
import { STAFF_MANAGEMENT_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV2Url } = getCommonConfig();
const { STAFF_BFF } = appConstants;
const { BIZ_GROUP, STAFF } = resourceConstants;
const { READ, CREATE } = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const staffManagementRoutes = [
  // Level 1 menu
  {
    path: '/staff-list',
    name: t('UserManagement.Root'),
    icon: UserOutlined,
    children: ['/staff-list/business-groups', '/staff-list/staffs'],
  },
  // Level 2 menus
  {
    exact: true,
    path: '/staff-list/business-groups',
    name: t('UserManagement.BusinessGroup'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      STAFF_MANAGEMENT_PATHS.BusinessGroups
    ),
    permissions: [permission(STAFF_BFF, BIZ_GROUP, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.BIZ_GROUP.BIZ_GROUP,
      userGuideCode: USER_GUIDE_CODE.BIZ_GROUP.CREATE,
    },
  },
  {
    exact: true,
    path: '/staff-list/business-groups/create',
    name: t('UserManagement.BusinessGroupCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      STAFF_MANAGEMENT_PATHS.NewBusinessGroup
    ),
    permissions: [permission(STAFF_BFF, BIZ_GROUP, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.BIZ_GROUP.BIZ_GROUP,
      userGuideCode: USER_GUIDE_CODE.BIZ_GROUP.CREATE,
    },
  },
  {
    exact: true,
    path: '/staff-list/business-groups/:id',
    name: t('UserManagement.BusinessGroupDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      STAFF_MANAGEMENT_PATHS.DetailBusinessGroup
    ),
    permissions: [permission(STAFF_BFF, BIZ_GROUP, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.BIZ_GROUP.BIZ_GROUP,
      userGuideCode: USER_GUIDE_CODE.BIZ_GROUP.CREATE,
    },
  },
  {
    exact: true,
    path: '/staff-list/staffs',
    name: t('UserManagement.StaffList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, STAFF_MANAGEMENT_PATHS.StaffList),
    permissions: [permission(STAFF_BFF, STAFF, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.STAFF_MANAGEMENT.STAFF,
      userGuideCode: USER_GUIDE_CODE.STAFF_MANAGEMENT.CREATE,
    },
  },
  {
    exact: true,
    path: '/staff-list/staffs/create',
    name: t('UserManagement.StaffCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, STAFF_MANAGEMENT_PATHS.NewStaff),
    permissions: [permission(STAFF_BFF, STAFF, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.STAFF_MANAGEMENT.STAFF,
      userGuideCode: USER_GUIDE_CODE.STAFF_MANAGEMENT.CREATE,
    },
  },
  {
    exact: true,
    path: '/staff-list/staffs/:id',
    name: t('UserManagement.StaffDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV2Url,
      STAFF_MANAGEMENT_PATHS.DetailStaff
    ),
    permissions: [permission(STAFF_BFF, STAFF, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.STAFF_MANAGEMENT.STAFF,
      userGuideCode: USER_GUIDE_CODE.STAFF_MANAGEMENT.CREATE,
    },
  },
];

export default staffManagementRoutes;
