export default {
  TODO: 'TODO', // use to set permissions as TODO (hide menu which hadn't been defined permissions yet)
  IAM_ADMIN: 'iam_admin',
  CATALOG: 'catalog',
  PPM: 'ppm',
  SELLERS: 'sellers',
  PAGE_BUILDER: 'page_builder',
  POLICY: 'policy',
  FMS: 'fms',
  AS: 'as',
  STAFF_BFF: 'staff-bff',
  STAFF_FE: 'staff_fe',
  APL: 'apl',
  SELLER_GATEWAY: 'seller-gateway',
  LO: 'lo',
  SUPPLY: 'supply',
  NOTIFICATION: 'notification-management-apis',
  LOYALTY: 'loyalty-bff-api',
  WMS: 'wms',
  TICKET_BFF: 'ticket-bff',
  SALE_PORTAL_BFF: 'teko-sale-portal-bff',
  REBATE_STAFF_BFF: 'rebate-staff-bff',
  MARKETING_AUTOMATION: 'teko:marketing-automation-bff-api',
  USER_SEGMENT_BFF: 'user-segment-bff',
  SMART_BANNER_BFF: 'smart-banner-bff-api',
  STAFF_MANAGEMENT: 'staff-management',
  POLICY_v2: 'policy-v2',
  BI_REPORT: 'bi-report',
  ONS_BFF: 'ons-bff',
  SUPERSET_PUBLIC: 'superset-public',
  UNS_BFF: 'uns-bff-api',
  LOYALTY_MANAGEMENT_BFF: 'loyalty-management-bff',
  USER_SERVICE: 'us',
  LEAD_BFF: 'teko-lm-bff-v2',
};
