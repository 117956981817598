import React from 'react';
import classNames from 'classnames';
import { userHelpers } from 'helpers';
import { IPlatform } from 'interfaces';
import './PlatformItem.scss';

const { getCurrentPlatform, changePlatform } = userHelpers;

export interface PlatformItemProps {
  /**
   * Seller data use to display
   */
  platform: IPlatform;
  /**
   * Control the size of this item
   */
  miniSize?: boolean;
}

const PlatformItem: React.FC<PlatformItemProps> = props => {
  const { platform, miniSize } = props;

  const currentPlatform = getCurrentPlatform();

  return (
    <div
      data-testid={`${platform.id}PlatformItem`}
      className={classNames({
        'platform-item': true,
        'mini-size': miniSize,
        'cursor-pointer': true,
      })}
      onClick={() =>
        parseInt(currentPlatform) !== platform.id
          ? changePlatform(platform.id)
          : {}
      }
    >
      {platform.name}
    </div>
  );
};

export default PlatformItem;
