import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18n';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { localizationConstants } from 'constants/index';
import { localizationHelpers } from 'helpers';
import App from './App';
import { registerSW } from 'virtual:pwa-register';
import configHelpers from 'helpers/config';

const { getClientConfig } = configHelpers;
const clientConfig = getClientConfig();

if ('serviceWorker' in navigator && !!clientConfig.swRegistered) {
  const onOk = registerSW();
  onOk(true);
}

if ('serviceWorker' in navigator && !clientConfig.swRegistered) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationHelpers;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ConfigProvider locale={REGIONS[getCurrentLanguage()].antdLocale}>
      <App />
    </ConfigProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
