import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Prompt,
  useParams,
  useRouteMatch,
  generatePath,
} from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'helpers/i18n';
import { isValidUrl } from 'helpers/url';
import { localizationHelpers, userHelpers, urlHelpers } from 'helpers';
import { userServices } from 'services';
import { commonHooks, iframeHooks } from 'hooks';
import { StoreContext } from 'contexts';
import { IRoute } from 'interfaces';
import AppContainer from 'containers/AppLayout/AppContainer';
import { commonConstants, flagConstants, unsConstants } from 'constants/index';
import './IFrameContainer.scss';
import configHelpers from 'helpers/config';
import { NetworkContext } from 'contexts/network';

const { getClientConfig } = configHelpers;
const { iam, trackerAppId } = getClientConfig();
const { clientId, oauthDomain } = iam;

const { getCurrentLanguage } = localizationHelpers;
const { getCurrentSeller, setCurrentSeller } = userHelpers;
const { getExportTitleFromURL } = urlHelpers;
const {
  useCurrentRoute,
  useSyncIFramePathNameEffect,
  useHandleRouteMappingRequest,
} = commonHooks;
const { useIframeDataTransfer } = iframeHooks;
const {
  ADMIN_DOMAINS,
  START_EDIT_MESSAGE,
  END_EDIT_MESSAGE,
  LOGOUT_MESSAGE,
  DEFAULT_NUMBER_DISPLAY_FORMAT,
  IFRAME_CONTAINER_ID,
} = commonConstants;
const { FLAG_KEYS } = flagConstants;
const { FCM_TOKEN, UNS_APP_ID } = unsConstants;

export interface IFrameContainerProps {
  iFrameSrc: string;
  filteredRoutes: IRoute[];
  customBackIcon?: string;
  hideBackButton?: boolean;
  isUseMobileView?: boolean;
  hideBreadcrumbMobileView?: boolean;
  isNotUseMobileViewWhenEnabledFlagKey?: string;
  serviceIdUNS?: number[];
}

const IFrameContainer: React.FC<IFrameContainerProps> = props => {
  const {
    iFrameSrc,
    filteredRoutes,
    customBackIcon,
    hideBackButton,
    isUseMobileView,
    hideBreadcrumbMobileView,
    isNotUseMobileViewWhenEnabledFlagKey,
    serviceIdUNS,
  } = props;
  useIframeDataTransfer();
  const { currentRoute } = useCurrentRoute();
  const { params: matchedParams } = useRouteMatch();
  const { sellerId } = useParams<{ sellerId: string }>();
  const {
    authorizedSellers,
    platformInfo,
    platformConfigBySeller,
    siteInfo,
    featureFlagsData,
  } = useContext(StoreContext);
  const { isOnlineMode } = useContext(NetworkContext);

  const getIsUseMobileView = () => {
    // Prioritize isNotUseMobileViewWhenEnabledFlagKey when it has value, if the params contain a valid string, if the flag is enabled we won't use mobile view but if the flag is disabled we will use mobile view
    if (!!isNotUseMobileViewWhenEnabledFlagKey) {
      const isNotUseMobileViewWhenFlagEnabled =
        featureFlagsData[isNotUseMobileViewWhenEnabledFlagKey]?.enabled;
      return !isNotUseMobileViewWhenFlagEnabled;
    }

    // Next we will check for the isUseMobileView param, if it exists we will use mobile view
    if (isUseMobileView) {
      return true;
    }

    // If none of the above conditions are met, we will use the default value (not use mobile view)
    return false;
  };

  const exportTitle = getExportTitleFromURL();

  const getCurrentSellerFromURL = () => {
    if (sellerId) {
      if (authorizedSellers.find(seller => seller.id === parseInt(sellerId))) {
        setCurrentSeller(parseInt(sellerId));
        return sellerId;
      }
    }
    return getCurrentSeller();
  };

  const getNumberDisplayFormat = () => {
    if (!featureFlagsData[FLAG_KEYS.EPIC_ECOM_489]?.enabled) {
      return DEFAULT_NUMBER_DISPLAY_FORMAT;
    }
    if (currentRoute?.isUsePlatformConfigBySeller) {
      return platformConfigBySeller?.numberDisplayFormat;
    } else {
      return platformInfo?.id
        ? platformInfo.numberDisplayFormat?.thousandSeparator
          ? platformInfo.numberDisplayFormat
          : DEFAULT_NUMBER_DISPLAY_FORMAT
        : platformConfigBySeller?.numberDisplayFormat;
    }
  };

  /**
   * Create this object to pass values from container to iframe (if needed)
   * In the iframe you can call: JSON.parse(window.name) to get its values
   * Eg: pass the localization to sync the language between container and iframe
   */
  const iframeNameObj = {
    localization: getCurrentLanguage(),
    currentPlatform: platformInfo?.id,
    numberDisplayFormat: getNumberDisplayFormat(),
    currentSeller: getCurrentSellerFromURL(),
    parentOrigin: window.location.origin,
    clientId,
    oauthDomain,
    isAdminDomain: ADMIN_DOMAINS.includes(window.location.hostname),
    customBackIcon,
    hideBackButton,
    isUseMobileView: getIsUseMobileView(),
    trackingAppId: trackerAppId,
    currentSite: siteInfo?.id,
    title: exportTitle,
    isOnlineMode,
    serviceIds: serviceIdUNS,
    fcmToken: localStorage.getItem(FCM_TOKEN),
    unsAppId: localStorage.getItem(UNS_APP_ID),
  };

  const [editing, setEditing] = useState(false);

  useSyncIFramePathNameEffect(filteredRoutes);
  useHandleRouteMappingRequest();
  const src = useMemo(() => {
    if (isValidUrl(iFrameSrc)) {
      try {
        // Sync matched params from url to iframe
        const { origin, pathname, search } = new URL(iFrameSrc);
        const syncedPathName = generatePath(pathname, matchedParams);
        return origin + syncedPathName + search + window.location.search;
      } catch {}
    }
    return iFrameSrc;
  }, [iFrameSrc, matchedParams]);

  useEffect(() => {
    const readDataFromIFrame = (event: MessageEvent) => {
      switch (event.data) {
        case START_EDIT_MESSAGE:
          setEditing(true);
          break;
        case END_EDIT_MESSAGE:
          setEditing(false);
          break;
        case LOGOUT_MESSAGE:
          userServices.logout();
          break;
      }
    };
    window.addEventListener('message', readDataFromIFrame);
    return () => {
      window.removeEventListener('message', readDataFromIFrame);
    };
  }, []);

  useEffect(() => {
    const confirmExitWhileEditing = (event: BeforeUnloadEvent) => {
      if (editing === true) {
        // Displays the prompt, as stated by the specs
        event.preventDefault();
        // The following two lines provide legacy support for displaying the prompt
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', confirmExitWhileEditing);
    return (): void => {
      window.removeEventListener('beforeunload', confirmExitWhileEditing);
    };
  }, [editing]);

  return (
    <AppContainer
      className={classNames({
        'iframe-container': getIsUseMobileView(),
        'hide-breadcrumb-title': hideBreadcrumbMobileView,
      })}
      title={
        currentRoute?.hideTitle
          ? null
          : currentRoute?.title || currentRoute?.name
      }
      guide={currentRoute?.guide}
      noPaddingBody
    >
      <Prompt when={editing} message={t('EditingStatus.ExitWarning')} />
      <iframe
        id={IFRAME_CONTAINER_ID}
        className={classNames({
          'iframe-mobile-view': getIsUseMobileView(),
        })}
        name={JSON.stringify(iframeNameObj)}
        src={src}
        title={currentRoute?.name}
        style={{ width: '100%', height: '100%', border: 'none' }}
        // Add permission to allow iframe to write to clipboard
        // Follow this: https://stackoverflow.com/questions/61401384/can-text-within-an-iframe-be-copied-to-clipboard
        allow="clipboard-write"
      />
    </AppContainer>
  );
};

export default IFrameContainer;
