import { lazy } from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import {
  appConstants,
  resourceConstants,
  actionConstants,
  guideConstants,
  outsidePathConstants,
  flagConstants,
} from 'constants/index';
import { PAGE_BUILDER_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { IRoute } from 'interfaces';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV1Url, baseOscmUrl } = getCommonConfig();

const { PAGE_BUILDER, STAFF_BFF } = appConstants;
const {
  URL,
  MENU,
  BANNER,
  BANNER_TYPE,
  CAMPAIGN,
  NEWS,
  LANDING_PAGE,
  LANDING_BLOCK,
  WEBSITE_EDITOR,
} = resourceConstants;
const { READ, CREATE } = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const {
  CUSTOM_WEBSITE_UI_PATH,
  CUSTOM_MOBILE_APP_UI_PATH,
} = outsidePathConstants;

const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const pageBuilderRoutes: IRoute[] = [
  // Parent menu
  {
    path: '/page-builder',
    name: t('PageBuilder.Root'),
    icon: LayoutOutlined,
    children: [
      '/page-builder/website-editors',
      CUSTOM_WEBSITE_UI_PATH,
      CUSTOM_MOBILE_APP_UI_PATH,
      '/page-builder/banner-types',
      '/page-builder/urls',
      '/page-builder/marketing-campaigns',
      '/page-builder/menus',
      '/page-builder/banners',
      '/page-builder/landing-pages',
      '/page-builder/news',
      '/page-builder/landing-blocks',
      '/page-builder/smart-banners',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/page-builder/website-editors',
    name: t('PageBuilder.WebsiteEditors'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseOscmUrl, PAGE_BUILDER_PATHS.WebsiteEditors),
    flagKey: flagConstants.FLAG_KEYS.EPIC_ECOM_480,
    permissions: [permission(STAFF_BFF, WEBSITE_EDITOR, READ)],
  },
  {
    exact: true,
    path: CUSTOM_WEBSITE_UI_PATH,
    name: t('PageBuilder.CustomizeWebsiteInterface'),
    redirectOutside: true,
    flagKey: flagConstants.FLAG_KEYS.INTEGRATE_TEMPI_WEBSITE_BUILDER,
    isCustomWebsiteItem: true,
    permissions: [permission(PAGE_BUILDER, 'admin')],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: CUSTOM_MOBILE_APP_UI_PATH,
    name: t('PageBuilder.CustomizeMobileAppInterface'),
    redirectOutside: true,
    flagKey: flagConstants.FLAG_KEYS.INTEGRATE_TEMPI_WEBSITE_BUILDER,
    isCustomWebsiteItem: true,
    permissions: [permission(PAGE_BUILDER, 'admin')],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/page-builder/banner-types',
    name: t('PageBuilder.BannerTypes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.BannerTypes),
    permissions: [permission(PAGE_BUILDER, BANNER_TYPE, READ)],
  },
  {
    exact: true,
    path: '/page-builder/banner-types/create',
    name: t('PageBuilder.BannerTypeCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.BannerTypeCreate
    ),
    permissions: [permission(PAGE_BUILDER, BANNER_TYPE, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_BANNER_TYPE,
    },
  },
  {
    exact: true,
    path: '/page-builder/banner-types/:id',
    name: t('PageBuilder.BannerTypeDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.BannerTypeDetail
    ),
    permissions: [permission(PAGE_BUILDER, BANNER_TYPE, READ)],
  },
  {
    exact: true,
    path: '/page-builder/banner-types/:id/banners',
    name: t('PageBuilder.BannerTypeBannersDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.BannerTypeBannersDetail
    ),
    permissions: [permission(PAGE_BUILDER, BANNER_TYPE, READ)],
  },
  {
    exact: true,
    path: '/page-builder/urls',
    name: t('PageBuilder.URLs'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.URLs),
    permissions: [permission(PAGE_BUILDER, URL, READ)],
  },
  {
    exact: true,
    path: '/page-builder/urls/create',
    name: t('PageBuilder.UrlCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.UrlCreate),
    permissions: [permission(PAGE_BUILDER, URL, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_URL,
    },
  },
  {
    exact: true,
    path: '/page-builder/urls/:id',
    name: t('PageBuilder.UrlDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.UrlDetail),
    permissions: [permission(PAGE_BUILDER, URL, READ)],
  },
  {
    exact: true,
    path: '/page-builder/marketing-campaigns',
    name: t('PageBuilder.MarketingCampaigns'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.MarketingCampaigns
    ),
    permissions: [permission(PAGE_BUILDER, CAMPAIGN, READ)],
  },
  {
    exact: true,
    path: '/page-builder/marketing-campaigns/create',
    name: t('PageBuilder.MarketingCampaignCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.MarketingCampaignCreate
    ),
    permissions: [permission(PAGE_BUILDER, CAMPAIGN, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_MARKETING_CAMPAIGN,
    },
  },
  {
    exact: true,
    path: '/page-builder/marketing-campaigns/:id',
    name: t('PageBuilder.MarketingCampaignDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.MarketingCampaignDetail
    ),
    permissions: [permission(PAGE_BUILDER, CAMPAIGN, READ)],
  },
  {
    exact: true,
    path: '/page-builder/menus',
    name: t('PageBuilder.Menus'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.Menus),
    permissions: [permission(PAGE_BUILDER, MENU, READ)],
  },
  {
    exact: true,
    path: '/page-builder/menus/create',
    name: t('PageBuilder.MenuCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.MenuCreate),
    permissions: [permission(PAGE_BUILDER, MENU, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_MENU,
    },
  },
  {
    exact: true,
    path: '/page-builder/menus/:id',
    name: t('PageBuilder.MenuDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.MenuDetail),
    permissions: [permission(PAGE_BUILDER, MENU, READ)],
  },
  {
    exact: true,
    path: '/page-builder/banners',
    name: t('PageBuilder.Banners'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.Banners),
    permissions: [permission(PAGE_BUILDER, BANNER, READ)],
  },
  {
    exact: true,
    path: '/page-builder/banners/create',
    name: t('PageBuilder.BannerCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.BannerCreate),
    permissions: [permission(PAGE_BUILDER, BANNER, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_BANNER,
    },
  },
  {
    exact: true,
    path: '/page-builder/banners/:id',
    name: t('PageBuilder.BannerDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.BannerDetail),
    permissions: [permission(PAGE_BUILDER, BANNER, READ)],
  },
  {
    exact: true,
    path: '/page-builder/landing-pages',
    name: t('PageBuilder.LandingPages'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.LandingPages),
    permissions: [permission(PAGE_BUILDER, LANDING_PAGE, READ)],
  },
  {
    exact: true,
    path: '/page-builder/landing-pages/create',
    name: t('PageBuilder.LandingPageCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.LandingPageCreate
    ),
    permissions: [permission(PAGE_BUILDER, LANDING_PAGE, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_PAGE,
    },
  },
  {
    exact: true,
    path: '/page-builder/landing-pages/:id',
    name: t('PageBuilder.LandingPageDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.LandingPageDetail
    ),
    permissions: [permission(PAGE_BUILDER, LANDING_PAGE, READ)],
  },
  {
    exact: true,
    path: '/page-builder/news',
    name: t('PageBuilder.News'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.News),
    permissions: [permission(PAGE_BUILDER, NEWS, READ)],
  },
  {
    exact: true,
    path: '/page-builder/news/create',
    name: t('PageBuilder.NewsCreate'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.NewsCreate),
    permissions: [permission(PAGE_BUILDER, NEWS, CREATE)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_NEWS,
    },
  },
  {
    exact: true,
    path: '/page-builder/news/:id',
    name: t('PageBuilder.NewsDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.NewsDetail),
    permissions: [permission(PAGE_BUILDER, NEWS, READ)],
  },
  {
    exact: true,
    path: '/page-builder/landing-blocks',
    name: t('PageBuilder.LandingBlocks'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV1Url, PAGE_BUILDER_PATHS.LandingBlocks),
    permissions: [permission(PAGE_BUILDER, LANDING_BLOCK, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.PAGE_BUILDER.PB,
      userGuideCode: USER_GUIDE_CODE.PAGE_BUILDER.CREATE_BLOCK,
    },
  },
  {
    exact: true,
    path: '/page-builder/landing-blocks/:id',
    name: t('PageBuilder.LandingBlockDetail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSellerCenterV1Url,
      PAGE_BUILDER_PATHS.LandingBlockDetail
    ),
    permissions: [permission(PAGE_BUILDER, LANDING_BLOCK, READ)],
  },
];

export default pageBuilderRoutes;
