import { IPermission, IRoute } from 'interfaces';
import { getPermissionCode } from './user';

/**
 * Checks if a permission exists in the current user's permission list
 * @param permissionToCheck
 * @param currentPermissions
 */
const checkPermissionExists = (
  permissionToCheck: IPermission,
  currentPermissions: string[]
): boolean => {
  const { app, resource } = permissionToCheck;
  const pCode = getPermissionCode(app, resource);
  return !!(currentPermissions && currentPermissions.find(p => p === pCode));
};

/**
 * Given user's permission list, determines if a leaf route is accessible.
 * By definition, a leaf route has no child routes.
 * @param route the leaf route
 * @param currentPermissions the current user's permission list
 */
const isLeafRouteVisible = (
  route: IRoute,
  currentPermissions: string[]
): boolean => {
  const { permissions } = route;
  if (!permissions) return true;
  const intersection = permissions.find(x =>
    checkPermissionExists(x, currentPermissions)
  );
  return !!intersection;
};

/**
 * Given user's permission list, checks if a given item is accessible
 * @param items all the items
 * @param item the one item to check
 * @param currentPermissions the current user's permission list
 */
const isItemAccessible = (
  item: IRoute,
  currentPermissions: string[]
): boolean => {
  return isLeafRouteVisible(item, currentPermissions);
};

export default { isItemAccessible };
