import React from 'react';
import classNames from 'classnames';
import { userHelpers } from 'helpers';
import { ISite } from 'interfaces';
import 'components/Platform/PlatformItem/PlatformItem.scss';

const { getCurrentSite, changeSite } = userHelpers;

export interface SiteItemProps {
  /**
   * Site data use to display
   */
  site: ISite;
  /**
   * Control the size of this item
   */
  miniSize?: boolean;
}

const SiteItem: React.FC<SiteItemProps> = props => {
  const { site, miniSize } = props;

  const currentSite = getCurrentSite();

  return (
    <div
      data-testid={`${site.id}SiteItem`}
      className={classNames({
        'site-item': true,
        'platform-item': true,
        'mini-size': miniSize,
        'cursor-pointer': true,
      })}
      onClick={() =>
        parseInt(currentSite) !== site.id ? changeSite(site.id) : {}
      }
    >
      {site.name}
    </div>
  );
};

export default SiteItem;
