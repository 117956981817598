import { lazy } from 'react';
import { BellOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
  flagConstants,
} from 'constants/index';
import {
  ORDER_NOTIFICATION_PATH,
  USER_NOTIFICATION_PATHS,
} from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { NOTIFICATION, ONS_BFF, UNS_BFF } = appConstants;
const { READ, UPDATE } = actionConstants;
const {
  MESSAGE,
  TEMPLATE,
  HISTORY,
  PARAM,
  REPORT,
  ORDER_NOTIFICATIONS,
  ZALO,
} = resourceConstants;
const { permission } = commonHelpers;
const { FLAG_KEYS } = flagConstants;

const { baseNotificationUrl, baseUnsUrl, baseErpUrl } = getCommonConfig();

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));
const IFrameUns = lazy(() => import('containers/shared/IFrameUns'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const userNotificationRoutes = [
  // Parent menu
  {
    // Using wildcard to match all user notification and order notifications routes
    path: '/*-notifications',
    name: t('UserNotifications.Root'),
    icon: BellOutlined,
    children: [
      '/user-notifications/list',
      '/order-notifications',
      '/user-notifications/history',
      '/user-notifications/template',
      '/user-notifications/params',
      '/user-notifications/report',
      '/user-notifications/zalo',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/user-notifications/list',
    name: t('UserNotifications.NotificationCampaignList'),
    component: IFrameUns,
    iFrameSrc: getSrc(
      baseNotificationUrl,
      USER_NOTIFICATION_PATHS.NotificationList
    ),
    permissions: [permission(NOTIFICATION, MESSAGE, READ)],
  },
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATH.List,
    name: t('UserNotifications.OrderNotification.Root'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_NOTIFICATION_PATH.List),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, READ)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATH.Create,
    name: t('UserNotifications.OrderNotification.Create'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_NOTIFICATION_PATH.Create),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, UPDATE)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATH.Update,
    name: t('UserNotifications.OrderNotification.Update'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_NOTIFICATION_PATH.Update),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, READ)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: ORDER_NOTIFICATION_PATH.Clone,
    name: t('UserNotifications.OrderNotification.Create'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_NOTIFICATION_PATH.Clone),
    permissions: [permission(ONS_BFF, ORDER_NOTIFICATIONS, UPDATE)],
    flagKey: FLAG_KEYS.ALLOW_SELLER_SETUP_ORDER_NOTIFICATION,
  },
  {
    exact: true,
    path: '/user-notifications/history',
    name: t('UserNotifications.NotificationHistory'),
    component: IFrameUns,
    iFrameSrc: getSrc(baseUnsUrl, USER_NOTIFICATION_PATHS.NotificationHistory),
    permissions: [permission(UNS_BFF, HISTORY, READ)],
  },
  {
    exact: true,
    path: '/user-notifications/template',
    name: t('UserNotifications.TemplateList'),
    component: IFrameUns,
    iFrameSrc: getSrc(baseUnsUrl, USER_NOTIFICATION_PATHS.TemplateList),
    permissions: [permission(UNS_BFF, TEMPLATE, READ)],
  },
  {
    exact: true,
    path: '/user-notifications/params',
    name: t('UserNotifications.ParamList'),
    component: IFrameUns,
    iFrameSrc: getSrc(baseUnsUrl, USER_NOTIFICATION_PATHS.ParamList),
    permissions: [permission(UNS_BFF, PARAM, READ)],
  },
  {
    exact: true,
    path: '/user-notifications/report',
    name: t('UserNotifications.Report'),
    component: IFrameUns,
    iFrameSrc: getSrc(baseUnsUrl, USER_NOTIFICATION_PATHS.ReportList),
    permissions: [permission(UNS_BFF, REPORT, READ)],
  },
  {
    exact: true,
    path: '/user-notifications/zalo',
    name: t('UserNotifications.ZaloIntegrate'),
    component: IFrameUns,
    iFrameSrc: getSrc(baseUnsUrl, USER_NOTIFICATION_PATHS.ZaloIntegrate),
    permissions: [permission(UNS_BFF, ZALO, UPDATE)],
  },
];

export default userNotificationRoutes;
