import { LineChartOutlined } from '@ant-design/icons';
import { BI_REPORT_V2_PATHS, BI_REPORT_V2_PATHS_LIVE } from 'constants/paths';
import { commonHelpers } from 'helpers';
import { getSrc } from 'helpers/url';
import { IRoute } from 'interfaces';
import { lazy } from 'react';
import { t } from 'helpers/i18n';
import { appConstants, resourceConstants } from 'constants/index';
import configHelpers from 'helpers/config';

const { getClientConfig, getCommonConfig } = configHelpers;

const { baseSupersetUrl, env } = getCommonConfig();
const {
  iam: { clientId, oauthDomain },
} = getClientConfig();

const { permission } = commonHelpers;
const { BI_REPORT, SUPERSET_PUBLIC } = appConstants;
const {
  VIEW_REPORT_FOR_MARKETING_LEAD_SPS,
  VIEW_REPORT_FOR_OPERATION_SPS,
  VIEW_REPORT_FOR_MERCHANDISE_SPS,
  VIEW_REPORT_FOR_SALES_SPS,
  VIEW_REPORT_FOR_WAREHOUSE_MANAGEMENT_SPS,
  VIEW_REPORT_FOR_SUPPLY_CHAIN_SPS,
  VIEW_REPORT_FOR_BOD_SPS,
  VIEW_REPORT_SUPERSET_FOR_MARKETING,
  VIEW_REPORT_SUPERSET_FOR_OPERATION,
  VIEW_REPORT_SUPERSET_FOR_MERCHANDISE,
  VIEW_REPORT_SUPERSET_FOR_SALES,
  VIEW_REPORT_SUPERSET_FOR_WAREHOUSE_MANAGEMENT,
  VIEW_REPORT_SUPERSET_FOR_SUPPLY_CHAIN,
  VIEW_REPORT_SUPERSET_FOR_BOD,
  VIEW_REPORT_SUPERSET_FOR_MOIT,
  VIEW_REPORT_SUPERSET_FOR_PO_PM,
} = resourceConstants;

const paths = env === 'PROD' ? BI_REPORT_V2_PATHS_LIVE : BI_REPORT_V2_PATHS;
const BI_REPORT_V2_DEFAULT_SEARCH = new URLSearchParams({
  standalone: '2',
  clientId,
  oauthDomain,
}).toString();

// Page
const BIReportV2 = lazy(() => import('containers/Report/BIReportV2'));

const reportV2Routes: IRoute[] = [
  // Level 1 menu
  {
    path: '/reports-v2',
    name: `${t('Reports.Root')} (beta)`,
    icon: LineChartOutlined,
    children: [
      '/reports-v2/report-for-marketing-lead',
      '/reports-v2/report-for-operation',
      '/reports-v2/report-for-merchandise',
      '/reports-v2/report-for-sales',
      '/reports-v2/report-for-warehouse-management',
      '/reports-v2/report-for-supply-chain',
      '/reports-v2/report-for-bod',
      '/reports-v2/report-for-moit',
      '/reports-v2/report-for-po-pm',
    ],
  },

  // Level 2 menu
  {
    exact: true,
    path: '/reports-v2/report-for-marketing-lead',
    name: t('Reports.MarketingReport'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.MarketingLead}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_MARKETING)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_MARKETING_LEAD_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-operation',
    name: t('Reports.OperationDashboard'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.Operation}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_OPERATION)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_OPERATION_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-merchandise',
    name: t('Reports.MerchandiseReport'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.Merchandise}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_MERCHANDISE)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_MERCHANDISE_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-sales',
    name: t('Reports.SalesDashboard'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.Sales}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_SALES)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_SALES_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-warehouse-management',
    name: t('Reports.WarehouseReports'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.WarehouseManagement}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [
            permission(
              SUPERSET_PUBLIC,
              VIEW_REPORT_SUPERSET_FOR_WAREHOUSE_MANAGEMENT
            ),
          ]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_WAREHOUSE_MANAGEMENT_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-supply-chain',
    name: t('Reports.SupplyChainDashboard'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.SupplyChain}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_SUPPLY_CHAIN)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_SUPPLY_CHAIN_SPS)],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/reports-v2/report-for-bod',
    name: t('Reports.DashboardForBOD'),
    component: BIReportV2,
    iFrameSrc: getSrc(
      baseSupersetUrl,
      `${paths.BOD}?${BI_REPORT_V2_DEFAULT_SEARCH}`
    ),
    permissions:
      env === 'PROD'
        ? [permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_BOD)]
        : [permission(BI_REPORT, VIEW_REPORT_FOR_BOD_SPS)],
    usePermissionPrefix: true,
  },
  ...(env === 'PROD'
    ? [
        {
          exact: true,
          path: '/reports-v2/report-for-moit',
          name: t('Reports.EcommerceReport'),
          component: BIReportV2,
          iFrameSrc: getSrc(
            baseSupersetUrl,
            `${BI_REPORT_V2_PATHS_LIVE.MOIT}?${BI_REPORT_V2_DEFAULT_SEARCH}`
          ),
          permissions: [
            permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_MOIT),
          ],
          usePermissionPrefix: true,
        },
        {
          exact: true,
          path: '/reports-v2/report-for-po-pm',
          name: t('Reports.POPMDashboard'),
          component: BIReportV2,
          iFrameSrc: getSrc(
            baseSupersetUrl,
            `${BI_REPORT_V2_PATHS_LIVE.POPM}?${BI_REPORT_V2_DEFAULT_SEARCH}`
          ),
          permissions: [
            permission(SUPERSET_PUBLIC, VIEW_REPORT_SUPERSET_FOR_PO_PM),
          ],
          usePermissionPrefix: true,
        },
      ]
    : []),
];

export default reportV2Routes;
