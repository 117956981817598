import { t } from 'helpers/i18n';
import { BIReport, BIReportType } from 'interfaces';

const BI_REPORTS: BIReport[] = [
  {
    key: BIReportType.ecommerce,
    name: t('Reports.EcommerceReport'),
    assetIdEn: '5d794969-2fcc-4fa0-a526-5dc5cbadcde8',
    assetIdVi: '5d794969-2fcc-4fa0-a526-5dc5cbadcde8',
  },
  {
    key: BIReportType.marketing,
    name: t('Reports.MarketingReport'),
    assetIdVi: '43e4e10f-cd34-44b1-96d5-579a61c143ba',
    assetIdEn: 'a0bfe49f-7c20-4b00-a60b-94f0b421ba15',
  },
  {
    key: BIReportType.operation,
    name: t('Reports.OperationDashboard'),
    assetIdEn: 'f6a70e3e-cb91-4ab9-b686-f0ac484ce7d3',
    assetIdVi: '334e96e5-2259-4971-8678-66446b26e0bb',
  },
  {
    key: BIReportType.merchandise,
    name: t('Reports.MerchandiseReport'),
    assetIdEn: '3fb7c2de-29bc-4cfa-a659-451d6a196018',
    assetIdVi: 'c658da1c-0862-46ea-bc86-83510750e560',
  },
  {
    key: BIReportType.sales,
    name: t('Reports.SalesDashboard'),
    assetIdEn: '7cea98fe-ed44-4aa4-8719-7b117beeb17b',
    assetIdVi: '74a1c47e-c978-440c-b2c6-5943eaae42aa',
  },
  {
    key: BIReportType.warehouse,
    name: t('Reports.WarehouseDashboard'),
    assetIdEn: 'b7eea215-d786-438d-b4f7-ba80da5bac57',
    assetIdVi: 'c6ce88a4-31ea-4cae-8e21-7abf4b708d2d',
  },
  {
    key: BIReportType.supplyChain,
    name: t('Reports.SupplyChainDashboard'),
    assetIdEn: '52d93307-b25b-4dab-9aef-59cb63d994d0',
    assetIdVi: '4fc60208-6deb-4c16-a41b-2c6be4cd3126',
  },
  {
    key: BIReportType.boardOfDirector,
    name: t('Reports.DashboardForBOD'),
    assetIdEn: '3f4b9ac9-6d08-4939-bb50-c19163c06a24',
    assetIdVi: '748f0200-0e70-422d-9fd0-cff42f9a58e1',
  },
  {
    key: BIReportType.productPerformance,
    name: t('Reports.ProductPerformanceDashboard'),
    assetIdEn: '800b1578-46af-4c62-98ea-02e3e6b27ea3',
    assetIdVi: '800b1578-46af-4c62-98ea-02e3e6b27ea3',
  },
];

export default {
  BI_REPORTS,
};
