import { useContext, useEffect } from 'react';
import { userServices } from 'services';
import TekoID from 'teko-oauth2';
import { trackingConstants } from 'constants/index';
import { NetworkContext } from 'contexts/network';
import configHelpers from 'helpers/config';

const { getCommonConfig, getClientConfig } = configHelpers;

const { CUSTOM_EVENT, ACTIONS } = trackingConstants;
const WILDCARD_ORIGIN = '*';

// supported message types between parent app and child app are defined here: https://git.teko.vn/staffinterface/staff-admin-id-sdk#message-type
const messageTypes = {
  TekoSDKReadyToInit: 'TekoSDKReadyToInit',
  TekoSDKInit: 'TekoSDKInit',
  TekoSDKLogin: 'TekoSDKLogin',
  TekoSDKLogout: 'TekoSDKLogout',
  TekoSDKGetFullUserInfo: 'TekoSDKGetFullUserInfo',
  TekoSDKUnloadUser: 'TekoSDKUnloadUser',
};

const useIframeDataTransfer = () => {
  const { isOnlineMode } = useContext(NetworkContext);
  useEffect(() => {
    const handleUserLoaded = async () => {
      /**
       * broadcast the init payload for backward compatibility with child apps using staff-admin-id-sdk before 1.0.5.
       * !! TODO: remove this line after all child apps have upgraded: OMPRODUCT-1446, UX4SELLER-1699
       */
      await sendToChildApp(messageTypes.TekoSDKInit, WILDCARD_ORIGIN);

      await sendToChildApp(messageTypes.TekoSDKReadyToInit, WILDCARD_ORIGIN);
    };

    const handleMessagesFromChildApp = async (event: MessageEvent) => {
      if (event.data && event.data.type) {
        const isMessageTypeValid = !!messageTypes[
          event.data.type as keyof typeof messageTypes
        ];

        if (!isMessageTypeValid) {
          return;
        }

        // check origin to prevent messages from unallowed origins
        const isMessageOriginValid = event.origin.endsWith('.teko.vn');
        if (!isMessageOriginValid) {
          if (getCommonConfig().env === 'DEV') {
            alert(
              `Origin of ${event.origin} is not allowed to communicate with parent app through postMessage! Please check the origin of the child app or contact the app's owner for more details.`
            );
          }
          track(CUSTOM_EVENT, ACTIONS.MESSAGE_FROM_CHILD_APP, {
            property: 'error',
            label: `Origin of ${event.origin} is not allowed`,
          });
          return;
        }

        await sendToChildApp(
          event.data.type,
          event.origin,
          event.data.requestId
        );
      }
    };

    window.addEventListener('message', handleMessagesFromChildApp);
    TekoID.user?.events.addUserLoaded(handleUserLoaded);

    return () => {
      window.removeEventListener('message', handleMessagesFromChildApp);
      TekoID.user?.events.removeUserLoaded(handleUserLoaded);
    };
  }, []);

  const createInitPayload = () => {
    return {
      isLoggedIn: userServices.isLoggedIn(),
      accessToken: userServices.getAccessToken(),
      userInfo: userServices.getUserInfo(),
      user: userServices.loadUser(),
    };
  };

  const createGetFullUserInfoPayload = async () => {
    if (getClientConfig().swRegistered) {
      return await userServices.getFullUserInfo(isOnlineMode);
    }
    return await userServices.getFullUserInfo();
  };

  const sendToChildApp = async (
    type: string,
    targetOrigin: string,
    requestId?: string
  ) => {
    const iframeNodes = Array.from(document.querySelectorAll('iframe'));

    if (iframeNodes.length === 0) {
      return;
    }

    const postMessageToIframes = (message: Record<string, any>) => {
      iframeNodes.forEach(iframe => {
        iframe.contentWindow?.postMessage(message, targetOrigin);
      });
    };

    if (
      targetOrigin === WILDCARD_ORIGIN &&
      type === messageTypes.TekoSDKReadyToInit
    ) {
      postMessageToIframes({ type });
      return;
    }

    switch (type) {
      case messageTypes.TekoSDKReadyToInit:
        postMessageToIframes({ type });
        break;
      case messageTypes.TekoSDKInit:
        const initPayload = createInitPayload();
        postMessageToIframes({ type, payload: initPayload });
        break;
      case messageTypes.TekoSDKGetFullUserInfo:
        const fullUserInfoPayload = await createGetFullUserInfoPayload();
        postMessageToIframes({ type, payload: fullUserInfoPayload, requestId });
        break;
      case messageTypes.TekoSDKLogin:
        userServices.login();
        break;
      case messageTypes.TekoSDKLogout:
        userServices.logout();
        break;
      case messageTypes.TekoSDKUnloadUser:
        userServices.unloadUser();
        break;
      default:
        break;
    }
  };
};

export default {
  useIframeDataTransfer,
};
