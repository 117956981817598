import { lazy } from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
} from 'constants/index';
import { SYSTEM_ADMIN_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSellerCenterV2Url, basePpmWebUrl } = getCommonConfig();

const {
  PPM,
  CATALOG,
  STAFF_MANAGEMENT,
  POLICY_v2,
  USER_SERVICE,
} = appConstants;
const {
  SUPPORT_TOOL,
  SQL_LOOKUP,
  CATALOG_ADMIN,
  USER_ADMIN_TOOL,
} = resourceConstants;
const { READ, UPDATE } = actionConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const entityRoutes = [
  // Parent menu
  {
    path: '/system-admin',
    name: t('SystemAdmin.Root'),
    icon: IdcardOutlined,
    children: [
      '/system-admin/support-tool',
      '/system-admin/sql-lookup',
      '/system-admin/catalog-admin',
      '/system-admin/staff-admin',
      '/system-admin/policy-admin',
      '/system-admin/user-admin',
      '/system-admin/user-support-tool',
    ],
  },
  // Sub menus
  {
    exact: true,
    path: '/system-admin/support-tool',
    name: t('SystemAdmin.SupportTool'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, SYSTEM_ADMIN_PATHS.SupportTool),
    permissions: [permission(PPM, SUPPORT_TOOL, READ)],
  },
  {
    exact: true,
    path: '/system-admin/sql-lookup',
    name: t('SystemAdmin.SQLLookup'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, SYSTEM_ADMIN_PATHS.SQLLookup),
    permissions: [permission(PPM, SQL_LOOKUP, READ)],
  },
  {
    exact: true,
    path: '/system-admin/catalog-admin',
    name: t('SystemAdmin.CatalogAdmin'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SYSTEM_ADMIN_PATHS.CatalogAdmin),
    permissions: [permission(CATALOG, CATALOG_ADMIN, UPDATE)],
  },
  {
    exact: true,
    path: '/system-admin/staff-admin',
    name: t('SystemAdmin.StaffAdmin'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, SYSTEM_ADMIN_PATHS.StaffAdmin),
    permissions: [permission(STAFF_MANAGEMENT, SUPPORT_TOOL, UPDATE)],
  },
  {
    exact: true,
    path: '/system-admin/policy-admin',
    name: t('SystemAdmin.PolicyAdmin'),
    component: IFrameContainer,
    iFrameSrc: getSrc(basePpmWebUrl, SYSTEM_ADMIN_PATHS.PolicyAdmin),
    permissions: [permission(POLICY_v2, SUPPORT_TOOL, UPDATE)],
  },
  {
    exact: true,
    path: '/system-admin/user-admin',
    name: t('SystemAdmin.UserAdmin'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, SYSTEM_ADMIN_PATHS.UserAdmin),
    permissions: [permission(USER_SERVICE, USER_ADMIN_TOOL, UPDATE)],
  },
];

export default entityRoutes;
