import React, { useEffect, Suspense } from 'react';
import { Modal } from 'antd';
import { IRoute } from 'interfaces';
import './ModalComponent.scss';

interface IModalComponentProps {
  component: IRoute['component'];
  iFrameSrc: IRoute['iFrameSrc'];
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  isSuspense?: boolean; // render component inside <Suspense /> or not
}

const ModalComponent: React.FC<IModalComponentProps> = props => {
  const {
    component: Component,
    iFrameSrc,
    showModal,
    setShowModal,
    isSuspense = false,
  } = props;

  useEffect(() => {
    // Listen event close-modal from children app
    const handleMessageFromChildrenApp = (event: MessageEvent) => {
      if (event.data === 'close-modal') {
        setShowModal(false);
      }
    };
    window.addEventListener('message', handleMessageFromChildrenApp);

    return () => {
      window.removeEventListener('message', handleMessageFromChildrenApp);
    };
    // eslint-disable-next-line
  }, []);

  if (!Component) return null;

  return (
    <Modal
      className="modal-component"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      closable={false}
      keyboard={false}
      centered
      width={450}
      destroyOnClose
      maskClosable={false}
    >
      {isSuspense ? (
        <Suspense fallback={null}>
          <Component
            iFrameSrc={iFrameSrc}
            filteredRoutes={[]}
            customBackIcon="close"
          />
        </Suspense>
      ) : (
        <Component
          iFrameSrc={iFrameSrc}
          filteredRoutes={[]}
          customBackIcon="close"
        />
      )}
    </Modal>
  );
};

export default ModalComponent;
