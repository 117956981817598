import React, { useContext } from 'react';
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import commonConstants from 'constants/common';
import EmptyBlock from 'components/shared/EmptyBlock';
import ContactAdminMessage from 'components/shared/ContactAdminMessage';
import SellerItem from 'components/Seller/SellerItem';
import { browserHistory, userHelpers, notificationHelpers } from 'helpers';

export const withMasanOwner = <P extends {}>(
  Component: React.FC<P>
): React.FC<P> => {
  return props => {
    const { sellerInfo, authorizedSellers } = useContext(StoreContext);
    const { SELLER_IDS } = commonConstants;

    const isMasanOwner = authorizedSellers.some(
      seller => seller.id === SELLER_IDS.MASAN
    );

    // If user has permission to manage Masan
    if (isMasanOwner) {
      // If user is currently managing different seller than Masan
      if (sellerInfo.id !== SELLER_IDS.MASAN) {
        const nextSeller = authorizedSellers.find(
          seller => seller.id === SELLER_IDS.MASAN
        );
        notificationHelpers.showConfirmModal({
          maskClosable: false,
          content: (
            <span>
              {t('CurrentPageIsBelongToSeller')}
              <SellerItem seller={nextSeller!} miniSize preventOnClick />
              {t('CurrentManageSeller')} <b>{sellerInfo.displayName}</b>,{' '}
              {t('WantToSwitchToSeller')} <b>{nextSeller?.displayName}</b>?
            </span>
          ),
          okText: t('Yes'),
          cancelText: t('No'),
          // If choose Yes, switch to that seller
          onOk: () => userHelpers.changeSeller(SELLER_IDS.MASAN),
          // If choose No, redirect user to home page
          onCancel: () => browserHistory.replace('/'),
        });

        return null;
      }

      return <Component {...props} />;
    }

    // Show invalid block if user has no permission to manage Masan
    return (
      <EmptyBlock
        data-testid="invalid-seller-block"
        description={
          <>
            {t('FieldIsInvalid', { field: t('SellerInformation') })}
            <br />
            <ContactAdminMessage />
          </>
        }
      />
    );
  };
};
