import React from 'react';
import { Avatar } from 'antd';
import classNames from 'classnames';
import { userHelpers } from 'helpers';
import { ISeller } from 'interfaces';
import defaultSellerLogo from 'assets/images/defaultSellerLogo.png';
import './SellerItem.scss';

const { getCurrentSeller, changeSeller } = userHelpers;

export interface SellerItemProps {
  /**
   * Seller data use to display
   */
  seller: ISeller;
  /**
   * Control the size of this item
   */
  miniSize?: boolean;
  /**
   * Prevent clicking on the item or not
   */
  preventOnClick?: boolean;
}

const SellerItem: React.FC<SellerItemProps> = props => {
  const { seller, miniSize, preventOnClick = false } = props;
  const logoUrl = seller.logoUrl || defaultSellerLogo;

  const currentSeller = getCurrentSeller();

  return (
    <div
      data-testid={`${seller.id}SellerItem`}
      className={classNames({
        'seller-item': true,
        'mini-size': miniSize,
        'cursor-pointer': !preventOnClick,
      })}
      onClick={() =>
        !preventOnClick && parseInt(currentSeller) !== seller.id
          ? changeSeller(seller.id)
          : {}
      }
    >
      <Avatar shape="square" src={logoUrl} />
      <span
        className={classNames({
          'ml-base': true,
          'text-linethrough': !seller.isActive,
        })}
      >
        {seller.name}
      </span>
    </div>
  );
};

export default SellerItem;
