import { IRegisterTokenParams } from 'interfaces/uns';
import { requestServices } from 'services';

const { unsClient } = requestServices;

const registerToken = async (params: IRegisterTokenParams): Promise<void> => {
  return unsClient.post('/notification/register-token', params);
};

export default {
  registerToken,
};
