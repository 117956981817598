import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';
import { IRoute } from 'interfaces';
import { commonHooks } from 'hooks';
import { commonHelpers } from 'helpers';
import { StoreContext } from 'contexts';
import MenuItemContent from './MenuItemContent';
import { outsidePathConstants } from 'constants/index';

const { Sider, Footer } = Layout;
const { SubMenu } = Menu;
const { useAppMenu } = commonHooks;

const { getBrandConfig } = commonHelpers;
const { logo } = getBrandConfig();

interface AppSiderProps {
  filteredNavigation: IRoute[];
  collapsed: boolean;
}

const AppSider: React.FC<AppSiderProps> = props => {
  const { filteredNavigation, collapsed } = props;
  const { appName } = useContext(StoreContext);

  const [isHovered, setIsHovered] = useState(false);

  const { selectedKey, openKeys, setOpenKeys } = useAppMenu();

  /**
   * return true if route.path in children
   *
   * return true if children array contains relative path for case isCustomWebsiteItem
   * Ex: children = ['https://base-domain/:websiteId'], route.path = 'https://base-domain/123' => return true
   */
  const checkShowMenuItem = (children: string[], route: IRoute) => {
    const {
      websiteEditorPathRegex,
      CUSTOM_WEBSITE_UI_PATH,
      CUSTOM_MOBILE_APP_UI_PATH,
      appEditorPathRegex,
    } = outsidePathConstants;

    return (
      children.includes(route.path) ||
      (children.includes(CUSTOM_WEBSITE_UI_PATH) &&
        route.isCustomWebsiteItem &&
        websiteEditorPathRegex.test(route.path)) ||
      (children.includes(CUSTOM_MOBILE_APP_UI_PATH) &&
        route.isCustomWebsiteItem &&
        appEditorPathRegex.test(route.path))
    );
  };

  return (
    <Sider
      className={classNames({
        'app-sider': true,
        collapsed,
      })}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={270}
    >
      <div className="app-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <Menu
        className="app-menu"
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={[selectedKey]}
        onOpenChange={keys => setOpenKeys(keys as string[])}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {filteredNavigation.map(levelOneItem => {
          if (!levelOneItem.icon) return null;
          if (!levelOneItem.children) {
            return (
              <Menu.Item key={levelOneItem.path}>
                <MenuItemContent item={levelOneItem} />
              </Menu.Item>
            );
          } else {
            const { children } = levelOneItem;
            const levelTwoItems = filteredNavigation.filter(child =>
              checkShowMenuItem(children, child)
            );
            return (
              <SubMenu
                popupClassName={classNames({
                  'd-none': collapsed && !isHovered,
                })}
                key={levelOneItem.path}
                title={
                  <span>
                    <levelOneItem.icon className="app-icon" />
                    <span title={levelOneItem.name}>{levelOneItem.name}</span>
                  </span>
                }
              >
                {levelTwoItems.map(levelTwoItem => {
                  if (!levelTwoItem.children) {
                    return (
                      <Menu.Item key={levelTwoItem.path}>
                        <MenuItemContent item={levelTwoItem} />
                      </Menu.Item>
                    );
                  } else {
                    const { children } = levelTwoItem;
                    const levelThreeItems = filteredNavigation.filter(child =>
                      checkShowMenuItem(children, child)
                    );
                    return (
                      <SubMenu
                        key={levelTwoItem.path}
                        title={levelTwoItem.name}
                      >
                        {levelThreeItems.map(levelThreeItem => (
                          <Menu.Item key={levelThreeItem.path}>
                            <MenuItemContent item={levelThreeItem} />
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                })}
              </SubMenu>
            );
          }
        })}
      </Menu>

      {!collapsed && (
        <Footer className="app-footer">
          {appName} © {import.meta.env.VITE_APP_VERSION}
        </Footer>
      )}
    </Sider>
  );
};

export default AppSider;
