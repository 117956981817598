import React from 'react';
import { Empty } from 'antd';
import { t } from 'helpers/i18n';
import { EmptyProps } from 'antd/es/empty';

const EmptyBlock: React.FC<EmptyProps> = props => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t('NoResult')}
      {...props}
    />
  );
};

export default EmptyBlock;
