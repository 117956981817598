import { lazy } from 'react';
import { biReportConstants, localizationConstants } from 'constants/index';
import { BIReport, IRoute } from 'interfaces';
import { getSrc } from 'helpers/url';
import { localizationHelpers } from 'helpers';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseDataStudioUrl } = getCommonConfig();

const { BI_REPORTS } = biReportConstants;
const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationHelpers;

// Pages
const BIReports = lazy(() => import('containers/Report/BIReports'));

const biReportRoutes = BI_REPORTS.map((report: BIReport) => {
  const assetId =
    getCurrentLanguage() === REGIONS.vi.key
      ? report.assetIdVi
      : report.assetIdEn;

  if (!assetId) return undefined;

  const path = `/${assetId}`;
  return {
    path: `/reports/${report.key}`,
    name: report.name,
    component: BIReports,
    iFrameSrc: getSrc(baseDataStudioUrl, path),
  };
}).filter(Boolean) as IRoute[];

export default biReportRoutes;
