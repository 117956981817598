export enum BIReportType {
  boardOfDirector = 'board-of-director',
  ecommerce = 'e-commerce',
  marketing = 'marketing',
  merchandise = 'merchandise',
  operation = 'operation',
  productPerformance = 'product-performance',
  sales = 'sales',
  supplyChain = 'supply-chain',
  warehouse = 'warehouse',
}

export interface BIReport {
  key: BIReportType;
  name: string;
  assetIdEn: string;
  assetIdVi: string;
}
