import React, { useState } from 'react';
import { ToolOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ModalComponent from 'components/ModalComponent';
import IFrameContainer from 'containers/shared/IFrameContainer';
import { HELPDESK_TICKET_PATH } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseSposUrl } = getCommonConfig();

const TICKET_URL = getSrc(baseSposUrl, HELPDESK_TICKET_PATH);

const Ticket = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button icon={<ToolOutlined />} onClick={() => setVisible(true)}>
        {t('ContactSupport')}
      </Button>
      <ModalComponent
        component={IFrameContainer}
        iFrameSrc={TICKET_URL}
        showModal={visible}
        setShowModal={setVisible}
      />
    </>
  );
};

export default Ticket;
