// LocalStorage key saves firebase cloud message token when the app registers to receive notifications
const FCM_TOKEN = 'fcmToken';

// LocalStorage key saves userId to check the case when the user is logged in, registers the token
// and the case does not register fcmToken when it is not needed
const FCM_USER_ID = 'fcmUserId';

const FCM_TRANSPORT = 'fcm';

// LocalStorage key saves fcm token registration status
const FCM_REGISTER_TOKEN_SUCCESS = 'fcmRegisterTokenSuccess';

// LocalStorage key saves uns app id
const UNS_APP_ID = 'fcmUNSAppId';

export default {
  FCM_TOKEN,
  FCM_USER_ID,
  FCM_TRANSPORT,
  FCM_REGISTER_TOKEN_SUCCESS,
  UNS_APP_ID,
};
