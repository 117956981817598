import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { t } from 'helpers/i18n';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import { serviceWorkerConstants, unsConstants } from 'constants/index';
import { unsServices, userServices } from 'services';

const { COMMAND_INIT_FIREBASE } = serviceWorkerConstants;
const {
  FCM_TOKEN,
  UNS_APP_ID,
  FCM_USER_ID,
  FCM_REGISTER_TOKEN_SUCCESS,
  FCM_TRANSPORT,
} = unsConstants;

const showConfirmModal = (props: ModalFuncProps) => {
  Modal.confirm({
    title: t('RequestConfirmation'),
    maskClosable: true,
    content: t('AreYouSureToContinue'),
    okText: t('Confirm'),
    cancelText: t('Cancel'),
    ...props,
  });
};

const installFirebaseAndUnsNotificationService = async (
  onReceiveNewNotification: (payload: MessagePayload) => void,
  extraConfig?: Record<string, unknown>
) => {
  const serviceWorkerRegistration = await navigator.serviceWorker.ready;
  if (!serviceWorkerRegistration) {
    console.warn('Service worker is not ready');
    return;
  }

  if (!('Notification' in window)) {
    console.warn('This browser does not support desktop notification');
    return;
  }

  if (!extraConfig) {
    console.warn('Extra config is not provided');
    return;
  }

  try {
    const {
      firebaseNotificationApiKey,
      firebaseNotificationAuthDomain,
      firebaseNotificationDatabaseUrl,
      firebaseNotificationProjectId,
      firebaseNotificationStorageBucket,
      firebaseNotificationMessagingSenderId,
      firebaseNotificationAppId,
      firebaseNotificationMeasurementId,
      firebaseNotificationVapidKey,
      stnAppId,
    } = extraConfig;

    const firebaseConfigs: FirebaseOptions = {
      apiKey: firebaseNotificationApiKey?.toString(),
      authDomain: firebaseNotificationAuthDomain?.toString(),
      databaseURL: firebaseNotificationDatabaseUrl?.toString(),
      projectId: firebaseNotificationProjectId?.toString(),
      storageBucket: firebaseNotificationStorageBucket?.toString(),
      messagingSenderId: firebaseNotificationMessagingSenderId?.toString(),
      appId: firebaseNotificationAppId?.toString(),
      measurementId: firebaseNotificationMeasurementId?.toString(),
    };

    // Step 1: Config firebase in service worker
    await registerFirebaseInServiceWorker(firebaseConfigs);

    // Step 2: Init firebase app
    const app = initializeApp(firebaseConfigs);
    const messaging = getMessaging(app);
    const firebaseToken = await getToken(messaging, {
      serviceWorkerRegistration,
      vapidKey: firebaseNotificationVapidKey?.toString(),
    });

    // Step 3: Register FCM token to UNS
    await registerUNS(firebaseToken, stnAppId?.toString());

    // Step 4: Listen to new notification in foreground
    onMessage(messaging, payload => {
      onReceiveNewNotification(payload);
    });
  } catch (error) {
    console.error('Error while installing notification', error);
    return;
  }
};

const registerFirebaseInServiceWorker = async (
  firebaseConfigs: FirebaseOptions
) => {
  const serviceWorkerRegistration = await navigator.serviceWorker.ready;
  if (!serviceWorkerRegistration) {
    return;
  }

  serviceWorkerRegistration.active?.postMessage({
    command: COMMAND_INIT_FIREBASE,
    data: { firebaseConfigs },
  });
};

const registerUNS = async (
  fcmToken: string,
  unsAppId?: string
): Promise<void> => {
  const lsFCMToken = localStorage.getItem(FCM_TOKEN);
  const lsUNSAppId = localStorage.getItem(UNS_APP_ID);
  const lsUserId = localStorage.getItem(FCM_USER_ID);
  const lsRegisterTokenSuccess = localStorage.getItem(
    FCM_REGISTER_TOKEN_SUCCESS
  );
  const userInfo = userServices.getUserInfo();
  if (!fcmToken || !unsAppId) return;
  const shouldRegisterTokenUNS =
    // firebase updates token OR first time users using the app on browser
    fcmToken !== lsFCMToken ||
    // notificationAppId config updates
    unsAppId !== lsUNSAppId ||
    // user logs in
    userInfo.sub !== lsUserId ||
    // registration failed last time
    lsRegisterTokenSuccess !== 'true';

  if (shouldRegisterTokenUNS) {
    try {
      await unsServices.registerToken({
        app_id: unsAppId,
        user_id: userInfo.sub,
        transport: FCM_TRANSPORT,
        token: fcmToken,
        email: userInfo.email,
        phone: userInfo.phone_number,
      });
      localStorage.setItem(FCM_REGISTER_TOKEN_SUCCESS, 'true');
      localStorage.setItem(FCM_USER_ID, userInfo.sub);
    } catch (e) {
      localStorage.setItem(FCM_REGISTER_TOKEN_SUCCESS, 'false');
    }
  }
  localStorage.setItem(FCM_TOKEN, fcmToken);
  localStorage.setItem(UNS_APP_ID, unsAppId);
};

export default {
  showConfirmModal,
  installFirebaseAndUnsNotificationService,
};
