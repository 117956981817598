const asRoles = {
  ACCOUNTANT_MANAGER: 'accountant_manager',
  ACCOUNTANT: 'accountant',
  BRANCH_ACCOUNTANT: 'branch_accountant',
  CASHIER: 'cashier',
  MANAGER: 'manager',
};

const opRoles = {
  EXECUTIVE: 'executive',
};

const staffBffRoles = {
  ACCOUNTANT: 'accountant',
  ACCOUNTANT_READONLY: 'accountant_readonly',
  CASHIER: 'cashier',
  MARKETPLACE_ORDER_READONLY: 'marketplace_order_readonly',
  SELLER_MANUAL_INVENTORY: 'seller_manual_inventory',
  SELLER_MANUAL_INVENTORY_READONLY: 'seller_manual_inventory_readonly',
  SELLER_ORDER_READONLY: 'seller_order_readonly',
  SHOPPING_CART_STAFF: 'shopping_cart_staff',
  SHOPPING_CART_MARKETPLACE_STAFF: 'shopping_cart_marketplace_staff',
  SYNC_UP_DATA: 'sync_up_data',
  WAREHOUSE_TRANSACTION_MANAGEMENT_READ: 'wms_transaction_management_read',
  WAREHOUSE_TRANSACTION_MANAGEMENT_UPDATE: 'wms_transaction_management_update',
  LEGACY_STAFF_MANAGEMENT: 'legacy-staff-management',
  STAFF_MANAGEMENT: 'staff-management',
  REDIRECTION_MANAGEMENT: 'redirection',
  CASHIER_END_SHIFT: 'cashier_end_shift',
  OM_BATCH_UPDATE: 'om_batch_update',
};

const loRoles = {
  LO_SUPER_ADMIN: 'all',
  LO_ADMIN: 'admin',
  LO_MOD: 'mod',
  LO_VIEW: 'view',
  LO_SHIPPER: 'shipper',
  LO_DISABLED: 'disabled',
};

const supplyRoles = {
  WRITER: 'writer',
  READER: 'reader',
};

const wmsRoles = {
  PHONGVU: 'phongvu',
  POSTMART_MANAGER: 'postmart_manager',
  POSTMART_SALESMAN: 'postmart_salesman',
  TAKASHIMAYA: 'takashimaya',
  SOIBIEN: 'soibien',
  SOIBIEN_STORE_STAFF: 'soibien_store_staff',
  SOIBIEN_WAREHOUSE_STAFF: 'soibien_warehouse_staff',
  SOIBIEN_SUPPLYCHAIN: 'soibien_supplychain',
  WMS_UNBUILD_ORDERS: 'wms_unbuild_orders',
};

const staffFeRoles = {
  POS_PHONGVU_CASHIER: 'pos_phongvu_cashier',
};

const loyaltyRoles = {
  QR_GAME_VIEWER: 'qr-game-viewer',
  QR_GAME_ADMIN: 'qr-game-admin',
};

export default {
  ...asRoles,
  ...opRoles,
  ...staffBffRoles,
  ...loRoles,
  ...supplyRoles,
  ...wmsRoles,
  ...staffFeRoles,
  ...loyaltyRoles,
};
