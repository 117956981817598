import { lazy } from 'react';
import { MedicineBoxOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  resourceConstants,
} from 'constants/index';
import { ORDER_HEALTH_PATH } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
// ERP2020-562 new routes
import systemCatalogRoutes from './systemCatalog';
import systemAdminRoutes from './systemAdmin';
import internalRoutes from './internal';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseErpUrl } = getCommonConfig();

const { STAFF_BFF } = appConstants;
const { ORDER_HEALTH } = resourceConstants;
const { READ } = actionConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 */
const adminRoutes = [
  ...systemCatalogRoutes,
  {
    exact: true,
    path: '/order-health',
    name: t('OrderHealth'),
    icon: MedicineBoxOutlined,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_HEALTH_PATH),
    permissions: [permission(STAFF_BFF, ORDER_HEALTH, READ)],
    hideSellerSwitch: true,
  },
  ...internalRoutes,
  ...systemAdminRoutes,
];

export default adminRoutes;
