import React, { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { IRoute } from 'interfaces';
import AppBreadcrumb from 'containers/AppLayout/AppBreadcrumb';
import BrowserPermissionModal from 'components/shared/BrowserPermissionModal';
import { NetworkContext } from 'contexts/network';
import { StoreContext } from 'contexts';

const { Content } = Layout;

interface AppContentProps {
  filteredRoutes: IRoute[];
}

const AppContent: React.FC<AppContentProps> = props => {
  const { filteredRoutes } = props;
  const { isOnlineMode } = useContext(NetworkContext);
  const { extraConfig } = useContext(StoreContext);
  const canShowBrowserPermissionModal = !!extraConfig?.enableEpicOmni1318;

  return (
    <Content className="app-content">
      <AppBreadcrumb />
      <Suspense fallback={null}>
        <Switch>
          {filteredRoutes.map(
            ({
              component: Component,
              iFrameSrc,
              name,
              hideBackButton,
              isUseMobileView,
              isNotUseMobileViewWhenEnabledFlagKey,
              browserPermissions,
              hideBreadcrumbMobileView,
              ...rest
            }) => {
              if (!Component) return null;
              return (
                <Route
                  {...rest}
                  key={uuidv4()}
                  render={routeProps => (
                    <>
                      <Component
                        {...routeProps}
                        iFrameSrc={iFrameSrc}
                        filteredRoutes={filteredRoutes}
                        hideBackButton={hideBackButton}
                        isUseMobileView={isUseMobileView}
                        hideBreadcrumbMobileView={hideBreadcrumbMobileView}
                        isNotUseMobileViewWhenEnabledFlagKey={
                          isNotUseMobileViewWhenEnabledFlagKey
                        }
                      />
                      {isOnlineMode &&
                        Array.isArray(browserPermissions) &&
                        canShowBrowserPermissionModal && (
                          <BrowserPermissionModal
                            browserPermissions={browserPermissions}
                          />
                        )}
                    </>
                  )}
                />
              );
            }
          )}
          {/* TODO: remove this menu-tracking redirect after 6 months (end of 2023) */}
          <Redirect from="/menu-tracking" to="/menu-directory" />
          <Redirect from="/" to="/404" />
        </Switch>
      </Suspense>
    </Content>
  );
};

export default AppContent;
