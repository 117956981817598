import React, { useContext, useMemo, useState } from 'react';
import { Menu, Dropdown, Avatar, Input, Empty } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { sellerHelpers, userHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { StoreContext } from 'contexts';
import SellerItem from 'components/Seller/SellerItem';
import defaultSellerLogo from 'assets/images/defaultSellerLogo.png';
import './SellerGlobalSwitch.scss';

const { getSearchSellers } = sellerHelpers;
const { getCurrentSeller } = userHelpers;

const SellerGlobalSwitch: React.FC = () => {
  const { authorizedSellers, sellerInfo } = useContext(StoreContext);
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);

  const logoUrl = sellerInfo.logoUrl || defaultSellerLogo;
  const currentSeller = getCurrentSeller();

  const results = useMemo(() => {
    return getSearchSellers(authorizedSellers, keyword);
  }, [keyword, authorizedSellers]);

  const sellerMenu = (
    <Menu className="seller-menu" defaultSelectedKeys={[currentSeller]}>
      <Menu.Item className="seller-search-item">
        <Input
          data-testid="seller-search-input"
          autoFocus
          allowClear
          placeholder={t('SearchSellerPlaceholder')}
          prefix={<SearchOutlined />}
          onChange={e => setKeyword(e.target.value.trim())}
        />
      </Menu.Item>
      {!!results.length ? (
        results.map(seller => (
          <Menu.Item key={seller.id} className="py-0">
            <SellerItem seller={seller} miniSize />
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('NoResult')}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  const labelInfo = () => {
    return (
      <span className="app-user">
        <Avatar className="seller-avatar" shape="square" src={logoUrl} />
        <span className="label">{sellerInfo.name}</span>
        {authorizedSellers.length !== 1 ? <DownOutlined /> : null}
      </span>
    );
  };

  return (
    <span id="seller-global-switch">
      {authorizedSellers.length === 1 ? (
        labelInfo()
      ) : (
        <Dropdown
          overlay={sellerMenu}
          trigger={['click']}
          onVisibleChange={flag => setVisible(flag)}
          visible={visible}
          getPopupContainer={() =>
            document.getElementById('seller-global-switch') as HTMLElement
          }
        >
          {labelInfo()}
        </Dropdown>
      )}
    </span>
  );
};

export default SellerGlobalSwitch;
