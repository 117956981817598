import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import { GuideProps } from 'interfaces';

const { Title } = Typography;

interface AppContainerProps {
  title: React.ReactNode;
  head?: React.ReactNode;
  className?: string;
  noPaddingBody?: boolean;
  guide?: GuideProps;
}

const AppContainer: React.FC<AppContainerProps> = props => {
  const { title, head, className, noPaddingBody, children } = props;

  return (
    <div className="app-container">
      {title && (
        <div className="app-container-head">
          <Title className="app-title" level={4}>
            {title}
          </Title>
          {head}
        </div>
      )}

      {children && (
        <div
          className={classNames({
            'app-container-body': true,
            'p-0': noPaddingBody,
            ...(className && { [className]: true }),
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default AppContainer;
